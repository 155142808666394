import React from 'react';
import PropTypes from 'prop-types';
// import { InputNumber } from '@aldridge/aldg-data-components';
import { InputAutocomplete, InputNumber } from '@aldridge/aldg-data-components';
import { Grid, Paper, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faCheck
} from '@fortawesome/pro-regular-svg-icons';
// import InputAutocomplete from '../../_GlobalComponents/Test/Components/InputAutocomplete';

const TaskPlannedHoursEmployees = (props) => {
  const {
    record,
    onChange,
    customClasses,
    classes,
    setOpen,
    piccs,
    timeOfDay,
    checkLockout,
    CurrentJobNumber,
    user
  } = props;

  const suggestedHours = (() => {
    const hrs = piccs.filter((picc) => {
      if (
        typeof record.data.Level1 !== 'undefined' &&
        record.data.Level1 !== ''
      ) {
        if (
          typeof record.data.Level2 !== 'undefined' &&
          record.data.Level2 !== ''
        ) {
          if (
            typeof record.data.Level3 !== 'undefined' &&
            record.data.Level3 !== ''
          ) {
            if (
              typeof record.data.Level4 !== 'undefined' &&
              record.data.Level4 !== ''
            ) {
              return (
                record.data.Level1 === picc.data.Level1 &&
                record.data.Level2 === picc.data.Level2 &&
                record.data.Level3 === picc.data.Level3 &&
                record.data.Level4 === picc.data.Level4
              );
            }
            return (
              record.data.Level1 === picc.data.Level1 &&
              record.data.Level2 === picc.data.Level2 &&
              record.data.Level3 === picc.data.Level3 &&
              picc.data.Level4 === ''
            );
          }
          return (
            record.data.Level1 === picc.data.Level1 &&
            record.data.Level2 === picc.data.Level2 &&
            picc.data.Level3 === '' &&
            picc.data.Level4 === ''
          );
        }
        return (
          record.data.Level1 === picc.data.Level1 &&
          picc.data.Level2 === '' &&
          picc.data.Level3 === '' &&
          picc.data.Level4 === ''
        );
      }
      return undefined;
    });
    return hrs;
  })();

  const getEmps = () => {
    if (typeof suggestedHours !== 'undefined') {
      if (
        parseFloat(suggestedHours[0]?.data.EstimatedQuantity) >
        parseFloat(record.data.EmployeesWorkingOnTask.length)
      )
        return true;
    }
    return false;
  };

  const getHrs = () => {
    if (typeof suggestedHours !== 'undefined') {
      const display =
        suggestedHours[0]?.data.EstimatedHours ||
        suggestedHours[0]?.data.TotalHours;
      if (
        (record.data.StartDay.PlannedShiftHours === '' &&
          suggestedHours.length > 0) ||
        parseFloat(display) > parseFloat(record.data.StartDay.PlannedShiftHours)
      )
        return true;
    }
    return false;
  };

  const calculateTotalManHours = () => {
    const hours = parseFloat(record.data.StartDay.PlannedShiftHours);
    const people = record.data.EmployeesWorkingOnTask.length;
    if (Number.isNaN(hours)) return 'N/A';
    return hours * people;
  };

  const CrewWhereClause = () => {
    if (!user.admin && user.prefab && record.data.Foreman !== '') {
      return [['data.Foreman', '==', record.data.Foreman]];
    }
    if (CurrentJobNumber.value.length === 0 && record.data.Foreman !== '') {
      return [
        ['data.Foreman', '==', record.data.Foreman],
        ['data.JobNumber', '==', '']
      ];
    }

    if (CurrentJobNumber.value.length !== 0 && record.data.Foreman === '') {
      return [
        ['data.Foreman', '==', ''],
        ['data.JobNumber', '==', record.data.JobNumber]
      ];
    }
    if (CurrentJobNumber.value.length !== 0 && record.data.Foreman !== '') {
      return [
        ['data.Foreman', '==', record.data.Foreman],
        ['data.JobNumber', '==', record.data.JobNumber]
      ];
    }
    return undefined;
  };

  return (
    <Grid container style={{ margin: 0 }}>
      <Grid item xs={12} className={customClasses.header}>
        <h2>Planned Hours and Employees</h2>
      </Grid>
      <Paper
        variant='outlined'
        style={{
          width: '100%',
          padding: '8px',
          margin: '0px 8px 4px 8px'
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6} className={customClasses.suggested}>
            <FontAwesomeIcon
              style={{ fontSize: '20px' }}
              color={getHrs() ? 'goldenrod' : '#00ca00'}
              icon={getHrs() ? faExclamationTriangle : faCheck}
            />{' '}
            Suggested Planned Hours Based on WBS Task:{' '}
            <b>
              {typeof suggestedHours !== 'undefined'
                ? suggestedHours[0]?.data.EstimatedHours ||
                suggestedHours[0]?.data.TotalHours ||
                'N/A'
                : 'N/A'}
            </b>
          </Grid>
          <Grid item xs={12} md={6} className={customClasses.suggested}>
            <FontAwesomeIcon
              style={{ fontSize: '20px' }}
              color={getEmps() ? 'goldenrod' : '#00ca00'}
              icon={getEmps() ? faExclamationTriangle : faCheck}
            />{' '}
            Suggested # of People Based on WBS Task:{' '}
            <b>
              {typeof suggestedHours !== 'undefined'
                ? suggestedHours[0]?.data.EstimatedQuantity || 'N/A'
                : 'N/A'}
            </b>
          </Grid>
        </Grid>
      </Paper>
      <Grid item md={5} xs={12} className={classes.field}>
        <InputNumber
          label={(record.data.TaskType === 'PreFab') ? 'Total Hours for Task' : 'Task Hours (Not Total Hours)'}
          value={record.data.StartDay.PlannedShiftHours || ''}
          name='StartDay.PlannedShiftHours'
          collection='Tasks'
          onChange={onChange}
          disabled={timeOfDay === 'EndDay' || checkLockout}
        />
      </Grid>
      <Grid item md={5} xs={9} className={classes.field}>
        <InputAutocomplete
          label='Employees Working on Task'
          value={record.data.EmployeesWorkingOnTask || []}
          onChange={onChange}
          name='EmployeesWorkingOnTask'
          firestoreOptions={{
            method: 'onSnapshot',
            collection: 'Crew',
            valueKey: 'id',
            where: CrewWhereClause(),
            orderBy: 'data.EmployeeDisplay'
          }}
          enterprise={false}
          optionDisplay={(opts) =>
            `${opts.data.EmployeeDisplay} ${opts.data.UnionDisplay}`
          }
          multiple
          disableCloseOnSelect
          emptyPlaceholder='There are no crew members.'
          disabled={checkLockout}
        />
      </Grid>
      <Grid
        item
        md={2}
        xs={3}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingRight: '8px'
        }}
      >
        <Button variant='contained' onClick={() => setOpen(true)}>
          Missing Crew Members?
        </Button>
      </Grid>
      {record.data.TaskType === 'PreFab' ? null : <>
        <Grid item xs={12} className={classes.field} >
          Total Man Hours: {calculateTotalManHours()}
        </Grid>
      </>
      }
    </Grid>
  );
};

TaskPlannedHoursEmployees.propTypes = {
  CurrentJobNumber: PropTypes.objectOf(PropTypes.any).isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  customClasses: PropTypes.objectOf(PropTypes.any).isRequired,
  piccs: PropTypes.arrayOf(PropTypes.any).isRequired,
  setOpen: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  timeOfDay: PropTypes.string.isRequired,
  checkLockout: PropTypes.bool,
  user: PropTypes.objectOf(PropTypes.any).isRequired
};
TaskPlannedHoursEmployees.defaultProps = {
  checkLockout: false
};

export default TaskPlannedHoursEmployees;
