/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
// import { ActionIcon, Grid, Container, Table, Text, Title, Autocomplete, TextInput, Button } from '@mantine/core';
import { Button, Grid, Table, Typography, Autocomplete, TextField, TableHead, TableRow, TableBody, TableCell, useTheme, useMediaQuery, Accordion } from '@mui/material';

import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { isMobile, isTablet } from 'react-device-detect';
import { LoadingButton } from '@mui/lab';
import { UserContext } from '../../providers/UserProvider';

export default function AddRecordTable({ form, Equipment, checkLockout, EquipmentLoading }) {
  // const record = form.getValues();
  const Headers = ['Equipment', 'Hours'];

  const { user } = useContext(UserContext);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const showLabels = useMemo(() => isMobile || isTablet || matches, [isMobile, isTablet, matches]);

  const equipmentTransactions = {
    Equipment: [],
    Hours: ''
  };

  const [recordEquipRows, setRecordEquip] = useState(form.values.data.Equipments);

  form.watch('data.Equipments', ({ value }) => {
    setRecordEquip(value);
  });

  const addRow = (type, transaction) => {
    const record = form.getValues();
    const r = record.data[type];
    const upd = [...r, { id: (r.length || 0) + 1, ...transaction }];
    const rec = record?.data;
    rec[type] = upd;
    setRecordEquip(upd);
    form.setFieldValue('data', rec);
  };


  const onEquipmentChange = (value, id) => {
    const record = form.getValues();
    const equipRows = record.data.Equipments || [];
    const upd = equipRows.find(row => row.id === id);
    upd.Equipment = [value.id];
    upd.EquipmentDisplay = value.value;
    const rec = record.data;
    rec.Equipments = equipRows;
    form.setFieldValue('data', rec);
  };

  const updateEquipmentRow = (value, field, id) => {
    const record = form.getValues();
    const equipRows = record.data.Equipments || [];
    const upd = equipRows.map(row => row.id === id ? { ...row, [field]: value } : row);
    const rec = record.data;
    rec.Equipments = upd;
    setRecordEquip(upd);
    form.setFieldValue('data', rec);
  };

  const deleteRow = (id) => {
    const record = form.getValues();
    const equipRows = record.data.Equipments;
    const upd = equipRows.filter(row => row.id !== id);
    const rec = record.data;
    rec.Equipments = upd;
    setRecordEquip(upd);
    form.setFieldValue('data', rec);
  };

  const AddAllEquipment = () => {
    const record = form.getValues();
    const allEquipmentOnJob = [];
    const r = {
      CreatedBy: '',
      CreatedDate: '',
      ModifiedBy: '',
      ModifiedDate: '',
      Equipment: {},
      Hours: '',
      EquipmentDisplay: ''
    };

    for (let i = 0; i < Equipment.length; i++) {
      const uR = JSON.parse(JSON.stringify(r));

      uR.id = i + 1;
      uR.Equipment = [Equipment[i].id];
      uR.EquipmentField = Equipment[i].data;
      uR.Hours = '';
      uR.EquipmentDisplay = `${Equipment[i].data.EquipmentDisplay || 'No AE Number'
        } - ${Equipment[i].data.Description}`;

      uR.CreatedBy = user.email;
      uR.CreatedDate = new Date().toJSON();
      uR.ModifiedBy = user.email;
      uR.ModifiedDate = new Date().toJSON();
      allEquipmentOnJob.push(uR);
    }

    const rec = record.data;
    rec.Equipments = allEquipmentOnJob;
    setRecordEquip(allEquipmentOnJob);
    form.setFieldValue('data', rec);
  };

  return (
    <Grid container
      sx={{
        border: "1px solid lightgray",
        padding: "1rem",
        borderRadius: "5px",
        backgroundColor: "white",
        margin: "1rem 0",
      }}
    >
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <LoadingButton variant='contained' color='primary' onClick={AddAllEquipment} loading={EquipmentLoading} disabled={Equipment.length === 0}>
          Load All Equipment Assigned To This Job
        </LoadingButton>
        {Equipment.length === 0 && <Typography variant='h6' sx={{ textAlign: "center" }}>
          There is No Equipment Available...
        </Typography>}
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Typography variant='h6' sx={{ textAlign: "center" }}>
          Equipment
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ display: "flex", justifyContent: "space-between" }}>
          <b>Add Equipment</b>
          <ToolTipIconButton icon={faPlus} shape='circle' onClick={() => addRow('Equipments', equipmentTransactions)} />
        </Typography>

        <Grid container item xs={12} sx={{ fontWeight: 'bold', borderBottom: '1px solid rgba(0,0,0,0.12)', display: showLabels ? 'none' : 'flex' }}>
          {Headers.map((header, idx) => {
            const widths = [8, 3, 1];
            return (
              <Grid item key={header} xs={12} md={widths[idx]}>
                {header}
              </Grid>
            );
          })}
        </Grid>

        {recordEquipRows.map((row, idx) => (
          <Grid container item xs={12} key={row.id} sx={{
            borderBottom: "1px solid lightgray",
            backgroundColor: idx % 2 === 0 ? 'white' : '#f5f5f5',
            alignItems: 'center',
            '& .MuiGrid-item': {
              padding: 0.5
            }
          }}>
            <Grid item xs={12} md={8}>
              <Autocomplete
                options={Equipment}
                disabled={checkLockout}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    const eq = Equipment.find(equip => equip.id === option);
                    return eq.data.EquipmentDisplay;
                  }
                  return option.data.EquipmentDisplay;
                }}
                getOptionKey={(option) => option.id}
                isOptionEqualToValue={(option, value) => option.id === value}
                renderInput={(params) => <TextField {...params} label={showLabels ? 'Equipment' : ''} />}
                onChange={(event, value) => onEquipmentChange(value, row.id)}
                placeholder={Equipment.length > 0 ? "Select Equipment..." : "There are No Equipment Available..."}
                value={row.Equipment?.[0] || null}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label={showLabels ? 'Hours' : ''}
                value={row.Hours || ''}
                disabled={checkLockout}
                onChange={(event) => updateEquipmentRow(event.target.value, 'Hours', row.id)}
              />
            </Grid>
            <Grid item xs={12} md={1} sx={{ display: 'flex', justifyContent: 'center' }}>
              <ToolTipIconButton icon={faTrash} color='error' shape='circle' onClick={() => deleteRow(row.id)} />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid >
  );
};