/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import { Modal, useMediaQuery, useTheme, Backdrop, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useRecoilState } from 'recoil';
import { orderBy, query, collection, getDocs } from 'firebase/firestore';
import dayjs from 'dayjs';
import { firestore } from './firebase';
import { _ShowChangelogModal } from './_Recoil/atoms';
import { basicStyles } from './theme/index';
import { UserContext } from './providers/UserProvider';
import ErrorBoundary from './utils/ErrorBoundary';

const ChangelogModal = () => {
  const [logs, setLogs] = useState([]);
  const { user } = useContext(UserContext);
  const [ShowChangelogModal, setShowChangelogModal] =
    useRecoilState(_ShowChangelogModal);
  useEffect(() => {
    let mounted = true;
    setShowChangelogModal(
      localStorage.getItem('ChangelogShown') === null &&
      typeof user !== 'undefined' &&
      user !== null
    );
    return () => (mounted = false);
  }, [user]);

  useEffect(() => {
    let mounted = true;
    if (ShowChangelogModal) {
      const q = query(
        collection(firestore, 'Changelog'),
        orderBy('DeployDate', 'desc')
      );
      getDocs(q).then((docs) => {
        const allLogs = [];
        docs.forEach((d) => allLogs.push(d.data()));
        if (mounted) setLogs(allLogs);
      });
    }
    return () => (mounted = false);
  }, [ShowChangelogModal]);

  const close = () => {
    localStorage.setItem('ChangelogShown', 'true');
    setShowChangelogModal(false);
  };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const customClasses = makeStyles(
    {
      version: {
        fontWeight: 'bold',
        width: '100%',
        fontSize: '110%',
        textAlign: 'left'
      },
      deployDate: {
        fontWeight: 'bold',
        width: '100%',
        fontSize: '110%',
        textAlign: 'right'
      }
    },
    { index: 1 }
  )();
  const classes = basicStyles();

  return (
    <ErrorBoundary componentName='Changelog Modal' user={user}>
      <Grid container>
        <Modal
          // open
          open={ShowChangelogModal}
          // open={false}
          onClose={close}
          BackdropComponent={Backdrop}
          disablePortal
          className={classes.modal}
        >
          <div className={classes.modalPaper} style={{ paddingTop: '0px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: matches ? '6px 0px 5px 4px' : '12px 0px 10px 10px',
                position: 'sticky',
                top: '-1px',
                left: 0,
                right: 0,
                backgroundColor: 'white',
                zIndex: '2100'
              }}
            >
              <h1>What&apos;s New!</h1>
              <button
                style={{
                  minWidth: '50px',
                  backgroundColor: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '20px',
                  textAlign: 'right',
                  paddingRight: matches ? '6px' : '12px',
                  color: '#f50057'
                }}
                type='button'
                onClick={close}
              >
                <b>X</b>
              </button>
            </div>

            <div
              style={{
                position: 'relative',
                flex: '1 1 auto',
                padding: matches ? '6px' : '12px',
                paddingTop: '0px'
              }}
            >
              {logs
                .filter((c) => (c.Deployed || 'Yes') === 'Yes')
                .map((log) => (
                  <div key={log.id}>
                    <div
                      style={{ border: '1px ridge gray', marginTop: '6px' }}
                    />
                    <div
                      key={log.id}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '10px 0px'
                      }}
                    >
                      <div className={customClasses.version}>{log.Version}</div>
                      <i className={customClasses.deployDate}>
                        {dayjs(log.DeployDate).format('YYYY-MM-DD')}
                      </i>
                    </div>
                    <ul>
                      {log.Changes.map((change, idx) => (
                        <li
                          style={{
                            textAlign: 'left',
                            marginLeft: '28px',
                            padding: '4px 0px'
                          }}
                          key={change.CreatedDate}
                        >
                          {change.Change}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
            </div>
          </div>
        </Modal>
      </Grid>
    </ErrorBoundary>
  );
};

export default ChangelogModal;
