/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  Modal,
  FormControlLabel,
  Switch,
  Box
} from '@mui/material';
import { InputCheckbox } from '@aldridge/aldg-data-components';
import Loader from 'react-loader-spinner';

import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { useRecoilValue, useRecoilState } from 'recoil';
import dayjs from 'dayjs';
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  doc,
  where,
  orderBy,
  getDoc
} from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DailyToolbar from '../dailyUsage/DailyToolbar';
import { firestore } from '../../firebase';
import {
  _CurrentDate,
  _CurrentForeman,
  _CurrentJobNumber,
  _Division,
  _Job,
  _MultiJobsSelected,
  _OnlyThisJob,
  _AllDivTime,
  _AllSubJobTime
} from '../../_Recoil/atoms';
import { basicStyles, icons, TimeSummaryStyles } from '../../theme';
import TimeEntryRecord from './TimeEntryRecord';
import { weekEndDate } from '../../utils/dateFunctions';
import TimeSummaryTimeTypesFilters from './TimeSummaryTimeTypesFilters';
import TimeSummaryFilters from './TimeSummaryFilters';
import TimeSummaryLegend from './TimeSummaryLegend';
import { UserContext } from '../../providers/UserProvider';

const TimeSummaryList = (props) => {
  const { history, checkLockout } = props;
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const CurrentDate = useRecoilValue(_CurrentDate);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const CurrentDivision = useRecoilValue(_Division);
  const CurrentJob = useRecoilValue(_Job);
  const [NewTimeEntries, setNewTimeEntries] = useState([]);
  const [jobsSelected, setJobsSelected] = useRecoilState(_MultiJobsSelected);
  const [OnlyThisJob, setOnlyThisJob] = useRecoilState(_OnlyThisJob);
  const [employeeIds, setEmployeeIds] = useState([]);
  const [invalidPiccs, setInvalidPiccs] = useState([]);
  const [employeeRecords, setEmployeeRecords] = useState([]);
  const [filterSelected, setFilterSelected] = useState(0);
  const [aggTimeDisplay, setAggTimeDisplay] = useState([]);
  const [allForDiv, setAllForDiv] = useRecoilState(_AllDivTime);
  const [AllSubJobTime, setAllSubJobTime] = useRecoilState(_AllSubJobTime);
  const [displayBoxOpen, setDisplayBoxOpen] = useState(false);

  const masterPiccs = [];
  const [badPiccs, setBadPiccs] = useState([]);
  const [selectedRecord] = useState(undefined);
  const [Loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);

  const displayChangeBox = (bool) => {
    setDisplayBoxOpen(bool);
  };

  const classes = basicStyles();
  const customClasses = TimeSummaryStyles();

  const iconClasses = icons();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const AddTime = () => {
    if (user && user.admin) {
      if (CurrentDate.value !== '') {
        displayChangeBox(true);
      } else {
        toast.error('You must have a Job and Date Selected to enter time...');
      }
    }
    if (user && user.manager) {
      if (
        (CurrentJobNumber.value.length !== 0 ||
          CurrentForeman.value.length !== 0) &&
        CurrentDate.value !== ''
      ) {
        displayChangeBox(true);
      } else {
        toast.error('You must have a Job and Date Selected to enter time...');
      }
      return;
    }
    if (user && user.prefab) {
      if (CurrentForeman.value.length !== 0 && CurrentDate.value !== '') {
        displayChangeBox(true);
      } else {
        toast.error('You must have a Job and Date Selected to enter time...');
      }
      return;
    }
    if (user && user.user) {
      if (CurrentJobNumber.value.length !== 0 && CurrentDate.value !== '') {
        displayChangeBox(true);
      } else {
        toast.error('You must have a Job and Date Selected to enter time...');
      }
    }
  };
  const getUniqueEmployees = (EmployeePids, uniqueEmployees, crews) => {
    crews.forEach((crew) =>
      EmployeePids.push({
        prtmstid: crew.data().data?.Employee[0],
        name: `${crew.data().data?.EmployeeDisplay}`,
        foreman: [crew.data().data.ForemanDisplay]
      })
    );
    EmployeePids.forEach((emp) => {
      const idx = uniqueEmployees
        .map((e) => e.prtmstid)
        .findIndex((e) => e === emp.prtmstid);
      if (idx === -1) {
        uniqueEmployees.push(emp);
      } else {
        uniqueEmployees[idx].foreman.push(emp.foreman[0]);
      }
    });

    uniqueEmployees.sort((a, b) => {
      const fa = a.name.toLowerCase();
      const fb = b.name.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  };

  const TimeDocs = (time, allDocs) => {
    if (!time.empty) {
      time.forEach((d) => {
        const data = d.data();
        const emp = data.data.Employee;
        if (
          allDocs[`Employee${emp}`].time.map((r) => r.id).indexOf(data.id) ===
          -1
        )
          allDocs[`Employee${emp}`].time.push(data);
      });
    }
  };

  const getNewDivisionRecords = () => {
    setLoading(true);
    getDocs(
      query(
        collection(firestore, 'Crew'),
        where('data.Division', '==', CurrentDivision),
        orderBy('data.EmployeeDisplay')
      )
    ).then((crews) => {
      const EmployeePids = [];
      const uniqueEmployees = [];
      getUniqueEmployees(EmployeePids, uniqueEmployees, crews);
      const allTimeCalls = [];
      const allDocs = {};
      for (let e = 0; e < uniqueEmployees.length; e++) {
        let q = query(
          collection(firestore, 'TimeEntries'),
          where('data.Employee', '==', uniqueEmployees[e].prtmstid),
          where('data.WeekEndDate', '==', weekEndDate(CurrentDate.value))
        );
        allDocs[`Employee${uniqueEmployees[e].prtmstid}`] = {
          name: uniqueEmployees[e].name,
          prtmstid: uniqueEmployees[e].prtmstid,
          foreman: uniqueEmployees[e].foreman,
          time: []
        };
        if (OnlyThisJob) {
          q = query(q, where('data.JCTDSCID', '==', CurrentJobNumber.value[0]));
        }
        allTimeCalls.push(getDocs(q));
      }

      Promise.all(allTimeCalls).then((t) => {
        t.forEach((time) => {
          TimeDocs(time, allDocs);
        });
        setEmployeeIds(uniqueEmployees);
        setNewTimeEntries(allDocs);
        setLoading(false);
      });
    });
  };
  const getForemanRecords = () => {
    const allCrewMembers = [];
    const uniqueEmployees = [];
    const allTimeCalls = [];
    const allDocs = {};
    setLoading(true);

    for (let j = 0; j < jobsSelected.ids.length; j++) {
      // 7724
      allCrewMembers.push(
        getDocs(
          query(
            collection(firestore, 'Crew'),
            where('data.JobNumber', '==', jobsSelected.ids[j]),
            where('data.Foreman', '==', CurrentForeman.value[0]),
            orderBy('data.EmployeeDisplay')
          )
        )
      );
      Promise.all(allCrewMembers).then((allCrews) => {
        const EmployeePids = [];
        allCrews.forEach((crews) => {
          getUniqueEmployees(EmployeePids, uniqueEmployees, crews);
          for (let e = 0; e < uniqueEmployees.length; e++) {
            let q = query(
              collection(firestore, 'TimeEntries'),
              where('data.Employee', '==', uniqueEmployees[e].prtmstid),
              where('data.WeekEndDate', '==', weekEndDate(CurrentDate.value)),
              where('CreatedBy', '==', user.email)
            );
            allDocs[`Employee${uniqueEmployees[e].prtmstid}`] = {
              name: uniqueEmployees[e].name,
              prtmstid: uniqueEmployees[e].prtmstid,
              foreman: uniqueEmployees[e].foreman,
              time: []
            };
            if (OnlyThisJob) {
              q = query(
                q,
                where('data.JCTDSCID', '==', CurrentJobNumber.value[0])
              );
            }
            allTimeCalls.push(getDocs(q));
          }
        });
        Promise.all(allTimeCalls).then((t) => {
          t.forEach((time) => {
            TimeDocs(time, allDocs);
          });
          setEmployeeIds(uniqueEmployees);
          setNewTimeEntries(allDocs);
          setLoading(false);
        });
      });
    }
  };
  const getPreFabRecords = () => {
    const allCrewMembers = [];
    const uniqueEmployees = [];
    const allTimeCalls = [];
    const allDocs = {};
    setLoading(true);
    // 7724
    allCrewMembers.push(
      getDocs(
        query(
          collection(firestore, 'Crew'),
          where('data.Foreman', '==', CurrentForeman.value[0]),
          orderBy('data.EmployeeDisplay')
        )
      )
    );
    Promise.all(allCrewMembers).then((allCrews) => {
      const EmployeePids = [];
      allCrews.forEach((crews) => {
        getUniqueEmployees(EmployeePids, uniqueEmployees, crews);
        for (let e = 0; e < uniqueEmployees.length; e++) {
          allDocs[`Employee${uniqueEmployees[e].prtmstid}`] = {
            name: uniqueEmployees[e].name,
            prtmstid: uniqueEmployees[e].prtmstid,
            foreman: uniqueEmployees[e].foreman,
            time: []
          };
          let q = query(
            collection(firestore, 'TimeEntries'),
            where('data.Employee', '==', uniqueEmployees[e].prtmstid),
            where('data.WeekEndDate', '==', weekEndDate(CurrentDate.value))
          );
          if (!(user.admin || user.manager)) {
            q = query(q, where('CreatedBy', '==', user.email));
          }
          allTimeCalls.push(getDocs(q));
        }

        Promise.all(allTimeCalls).then((t) => {
          t.forEach((time) => {
            TimeDocs(time, allDocs);
          });
          setEmployeeIds(uniqueEmployees);
          setNewTimeEntries(allDocs);
          setLoading(false);
        });
      });
    });
  };
  const getNewJobsRecords = () => {
    const allCrewMembers = [];
    const uniqueEmployees = [];
    const allTimeCalls = [];
    const allDocs = {};
    setLoading(true);
    for (let j = 0; j < jobsSelected.ids.length; j++) {
      allCrewMembers.push(
        getDocs(
          query(
            collection(firestore, 'Crew'),
            where('data.JobNumber', '==', jobsSelected.ids[j]),
            orderBy('data.EmployeeDisplay')
          )
        )
      );
    }
    Promise.all(allCrewMembers).then((allCrews) => {
      const EmployeePids = [];
      allCrews.forEach((crews) => {
        getUniqueEmployees(EmployeePids, uniqueEmployees, crews);
        for (let e = 0; e < uniqueEmployees.length; e++) {
          let q = query(
            collection(firestore, 'TimeEntries'),
            where('data.Employee', '==', uniqueEmployees[e].prtmstid),
            where('data.WeekEndDate', '==', weekEndDate(CurrentDate.value))
          );

          allDocs[`Employee${uniqueEmployees[e].prtmstid}`] = {
            name: uniqueEmployees[e].name,
            prtmstid: uniqueEmployees[e].prtmstid,
            foreman: uniqueEmployees[e].foreman,
            time: []
          };
          if (OnlyThisJob) {
            q = query(
              q,
              where('data.JCTDSCID', '==', CurrentJobNumber.value[0])
            );
          }
          allTimeCalls.push(getDocs(q));
        }
        Promise.all(allTimeCalls).then((t) => {
          t.forEach((time) => {
            TimeDocs(time, allDocs);
          });
          setEmployeeIds(uniqueEmployees);
          setNewTimeEntries(allDocs);
          setLoading(false);
        });
      });
      // Promise.all(piccPromises).then(() => {
      //   if (mounted) {
      //     setInvalidPiccs(invps);
      //     setBadPiccs([]);
      //   }
      // });
    });
  };
  const getNewJobWithSubJobsRecords = () => {
    const EmployeePids = [];
    const uniqueEmployees = [];
    const allTimeCalls = [];
    const allDocs = {};
    setLoading(true);
    setLoading(true);
    getDocs(
      query(
        collection(firestore, 'Crew'),
        where('data.Job', '==', CurrentJob),
        orderBy('data.EmployeeDisplay')
      )
    ).then((crews) => {
      getUniqueEmployees(EmployeePids, uniqueEmployees, crews);
      for (let e = 0; e < uniqueEmployees.length; e++) {
        let q = query(
          collection(firestore, 'TimeEntries'),
          where('data.Employee', '==', uniqueEmployees[e].prtmstid),
          where('data.WeekEndDate', '==', weekEndDate(CurrentDate.value))
        );

        allDocs[`Employee${uniqueEmployees[e].prtmstid}`] = {
          name: uniqueEmployees[e].name,
          prtmstid: uniqueEmployees[e].prtmstid,
          foreman: uniqueEmployees[e].foreman,
          time: []
        };
        if (OnlyThisJob) {
          q = query(q, where('data.JCTDSCID', '==', CurrentJobNumber.value[0]));
        }
        allTimeCalls.push(getDocs(q));
      }
    });
    Promise.all(allTimeCalls).then((t) => {
      t.forEach((time) => {
        TimeDocs(time, allDocs);
      });
      setEmployeeIds(uniqueEmployees);
      setNewTimeEntries(allDocs);
      setLoading(false);
    });
  };

  const getCrewRecords = () => {
    if (user && (user.manager || user.admin)) {
      if (CurrentDate.value !== '') {
        if (CurrentJobNumber.value.length > 0) {
          if (allForDiv) {
            getNewDivisionRecords();
            return;
          }
          if (AllSubJobTime) {
            getNewJobWithSubJobsRecords();
            return;
          }
          getNewJobsRecords();
        }
        if (
          CurrentJobNumber.value.length === 0 &&
          CurrentForeman.value.length > 0
        ) {
          getPreFabRecords();
        }
      } else {
        toast.info(
          'Please Select a Date and Job/Foreman to view Time Records',
          {
            toastId: 'missingData'
          }
        );
      }
      return;
    }
    if (user && user.prefab) {
      if (CurrentForeman.value.length > 0 && CurrentDate.value !== '') {
        getPreFabRecords();
      } else {
        toast.info('Please Select a Date, and Foreman to view Time Records', {
          toastId: 'missingData'
        });
      }
      return;
    }
    if (user && user.user) {
      if (
        CurrentForeman.value.length > 0 &&
        CurrentJobNumber.value.length > 0 &&
        CurrentDate.value !== ''
      ) {
        getForemanRecords();
      } else {
        toast.info(
          'Please Select a Job, Date, and Foreman to view Time Records',
          { toastId: 'missingData' }
        );
      }
    }
  };

  const getRecords = (isMounted) => {
    if (CurrentJobNumber.value.length === 0) {
      if (isMounted) setNewTimeEntries([]);
    }
    getCrewRecords();
  };
  const getInvalidPiccsByJob = (job) =>
    new Promise((resolve) => {
      const q = query(
        collection(firestore, 'ENT-Piccs'),
        where('jctdscid', '==', job),
        where('Status', '==', 'Inactive')
      );
      getDocs(q).then((snap) => {
        const docs = [];
        if (!snap.empty) {
          snap.forEach((d) => docs.push(d.data()));
        }
        resolve(docs);
      });
    });
  const getInvalidPiccs = (mounted) => {
    const invps = [];
    const piccPromises = [];
    jobsSelected.ids.forEach((job) => {
      piccPromises.push(
        getInvalidPiccsByJob(job).then((result) =>
          result.forEach((r) => invps.push(r))
        )
      );
    });
    Promise.all(piccPromises).then(() => {
      if (mounted) {
        setInvalidPiccs(invps);
        setBadPiccs([]);
      }
    });
  };
  const parseNumber = (num) => {
    const defaultNum = 0;
    if (Number.isNaN(parseFloat(num))) return defaultNum;
    return parseFloat(num);
  };
  const editJobsSelected = (event, a, b) => {
    const uJobsSelected = { ...jobsSelected };
    uJobsSelected.display = b;
    uJobsSelected.ids = event;
    setJobsSelected(uJobsSelected);
  };
  const checkValidPicc = (atr) => {
    const pArray = atr.Piccs;
    pArray.forEach((p) => {
      if (typeof p === 'undefined') {
        atr.containsInvalidPicc = true;
        const abc = [...badPiccs];
        abc.push(p);
        setBadPiccs((prev) => [...prev, ...abc]);
      } else if (invalidPiccs.map((r) => r.jctmstid).includes(p)) {
        const abc = [...badPiccs];
        abc.push(p);
        atr.containsInvalidPicc = true;
        setBadPiccs((prev) => [...prev, ...abc]);
      }
    });
  };
  const getNewTimeEntries = (tE, filter, mounted) => {
    const aggTimeRecords = [];
    const r = {
      Employee: '',
      EmployeeDisplay: '',
      Foremans: [],
      Date: '',
      // PreFab: user.PreFab,
      Piccs: [],
      Regular: 0,
      Overtime: 0,
      Doubletime: 0,
      Vacation: 0,
      SickLeave: 0,
      Holiday: 0,
      NoWorkRecorded: '',
      LessThan8HoursVerified: '',
      PerDiem: '',
      containsInvalidPicc: ''
    };

    Object.values(tE).forEach((te) => {
      const { name, time, prtmstid, foreman } = te;
      if (time.length > 0) {
        time.forEach((entry) => {
          const idx = aggTimeRecords.findIndex(
            (atr) => atr.Employee === entry.data.Employee
          );
          const d = dayjs(entry.data.Date).format('ddd');
          if (idx === -1) {
            const cR = JSON.parse(JSON.stringify(r));
            if (
              entry.data.NoWorkRecorded !== 'true' &&
              entry.data.TimeCardType !== 'GL'
            ) {
              cR.Piccs.push(entry.data.Picc[0]);
              masterPiccs.push(entry.data.Picc[0]);
            }
            cR.Employee = entry.data.Employee;
            cR.EmployeeDisplay = entry.data.EmployeeDisplay;
            cR.Foremans = foreman;
            cR.Date = entry.data.Date;
            cR[d] = {};
            cR[d].LessThan8HoursVerified =
              entry.data.LessThan8HoursVerified === 'true';
            if (filter === 0 || filter === 7 || filter === 1)
              cR[d].Regular = parseNumber(entry.data.Regular);
            if (filter === 0 || filter === 7 || filter === 2)
              cR[d].Overtime = parseNumber(entry.data.Overtime);
            if (filter === 0 || filter === 7 || filter === 3)
              cR[d].Doubletime = parseNumber(entry.data.Doubletime);
            if (filter === 0 || filter === 7 || filter === 4)
              cR[d].Holiday = parseNumber(entry.data.Holiday);
            if (filter === 0 || filter === 7 || filter === 5)
              cR[d].Vacation = parseNumber(entry.data.Vacation);
            if (filter === 0 || filter === 7 || filter === 6)
              cR[d].SickLeave = parseNumber(entry.data.SickLeave);
            if (filter === 0 || filter === 7)
              cR[d].PerDiem = parseNumber(entry.data.PerDiem);

            if (filter === 0 || filter === 8)
              cR[d].NoWorkRecorded = entry.data.NoWorkRecorded === 'true';
            aggTimeRecords.push(cR);
          } else {
            const cR = aggTimeRecords[idx];
            if (typeof cR[d] === 'undefined')
              cR[d] = {
                Regular: 0,
                Overtime: 0,
                Doubletime: 0,
                Vacation: 0,
                SickLeave: 0,
                Holiday: 0
              };
            if (
              entry.data.NoWorkRecorded !== 'true' &&
              entry.data.TimeCardType !== 'GL'
            ) {
              cR.Piccs.push(entry.data.Picc[0]);
              masterPiccs.push(entry.data.Picc[0]);
            }
            cR[d].LessThan8HoursVerified = !cR[d].LessThan8HoursVerified
              ? entry.data.LessThan8HoursVerified === 'true'
              : cR[d].LessThan8HoursVerified;
            if (filter === 0 || filter === 7 || filter === 1)
              cR[d].Regular += parseNumber(entry.data.Regular);
            if (filter === 0 || filter === 7 || filter === 2)
              cR[d].Overtime += parseNumber(entry.data.Overtime);
            if (filter === 0 || filter === 7 || filter === 3)
              cR[d].Doubletime += parseNumber(entry.data.Doubletime);
            if (filter === 0 || filter === 7 || filter === 4)
              cR[d].Holiday += parseNumber(entry.data.Holiday);
            if (filter === 0 || filter === 7 || filter === 5)
              cR[d].Vacation += parseNumber(entry.data.Vacation);
            if (filter === 0 || filter === 7 || filter === 6)
              cR[d].SickLeave += parseNumber(entry.data.SickLeave);
            if (filter === 0 || filter === 7)
              cR[d].PerDiem = parseNumber(entry.data.PerDiem);
            if (filter === 0 || filter === 8)
              cR[d].NoWorkRecorded = !cR[d].NoWorkRecorded
                ? entry.data.NoWorkRecorded === 'true'
                : cR[d].NoWorkRecorded;
          }
        });
      } else {
        const cR = JSON.parse(JSON.stringify(r));
        cR.Employee = prtmstid;
        cR.EmployeeDisplay = name;
        cR.Foremans = foreman;
        aggTimeRecords.push(cR);
      }
    });
    if (filter === 0) {
      aggTimeRecords.forEach((atr) => {
        checkValidPicc(atr);
      });
    }
    if (mounted) {
      setAggTimeDisplay(aggTimeRecords);
    }
  };
  const displayDailyTotalHrs = (day) => {
    if (typeof day === 'undefined') return '';
    if (day.NoWorkRecorded) {
      return `X<br>${day.PerDiem !== '' &&
          typeof day.PerDiem !== 'undefined' &&
          parseNumber(day.PerDiem) > 0
          ? `$${day.PerDiem.toString()}`
          : ''
        }`;
    }
    return `${parseNumber(day.Regular) +
      parseNumber(day.Overtime) +
      parseNumber(day.Doubletime) +
      parseNumber(day.Holiday) +
      parseNumber(day.Vacation) +
      parseNumber(day.SickLeave)
      }<br>${day.PerDiem !== '' &&
        typeof day.PerDiem !== 'undefined' &&
        parseNumber(day.PerDiem) > 0
        ? `$${day.PerDiem.toString()}`
        : ''
      }`;
  };
  const displayTotalHrs = (r) => {
    let rgHrs = 0;
    let otHrs = 0;
    let dtHrs = 0;
    let hlHrs = 0;
    let vaHrs = 0;
    let slHrs = 0;
    let pdDrs = 0;
    if (typeof r === 'undefined') {
      return null;
    }
    const keys = Object.keys(r);
    keys.forEach((k) => {
      if (['Employee', 'EmployeeDisplay', 'Date'].indexOf(k) === -1) {
        rgHrs += parseNumber(r[k].Regular);
        otHrs += parseNumber(r[k].Overtime);
        dtHrs += parseNumber(r[k].Doubletime);
        hlHrs += parseNumber(r[k].Holiday);
        vaHrs += parseNumber(r[k].Vacation);
        slHrs += parseNumber(r[k].SickLeave);
        pdDrs += parseNumber(r[k].PerDiem);
      }
    });
    return `<span>${rgHrs + otHrs + dtHrs + hlHrs + vaHrs + slHrs
      }</span><br /><span>${pdDrs > 0 ? `$${pdDrs}` : ''}</span>`;
  };
  const selectFilter = (s) => {
    setFilterSelected(s);
    getNewTimeEntries(NewTimeEntries, s, true);
  };
  const getColor = (day) => {
    if (typeof day !== 'undefined') {
      if (
        day.NoWorkRecorded &&
        (day.Doubletime > 0 ||
          day.Overtime > 0 ||
          day.Vacation > 0 ||
          day.Holiday > 0 ||
          day.SickLeave > 0 ||
          day.Regular > 0)
      )
        return '#dbdd2e';
      if (day.Doubletime > 0 || day.Overtime > 0) return 'red';
      if (day.Regular > 8) return 'darkorange';
      if (day.Vacation > 0 || day.Holiday > 0) return 'blue';
      if (day.SickLeave > 0) return 'green';
      if (day.NoWorkRecorded || day.LessThan8HoursVerified)
        return 'darkturquoise';
    }
    return 'black';
  };

  useEffect(() => {
    if (CurrentJobNumber.value.length > 0) {
      const uJobsSelected = { ...jobsSelected };
      uJobsSelected.ids = CurrentJobNumber.value;
      uJobsSelected.display = CurrentJobNumber.display;
      setJobsSelected(uJobsSelected);
    }
  }, [CurrentJobNumber.value]);
  useEffect(() => {
    if (
      CurrentJobNumber.value.length > 0 &&
      jobsSelected.ids.indexOf(CurrentJobNumber.value[0]) === -1
    ) {
      const uRecord = JSON.parse(JSON.stringify(jobsSelected));
      uRecord.ids.push(CurrentJobNumber.value[0]);
      if (uRecord.display.trim() === '')
        uRecord.display = `${CurrentJobNumber.display}`;
      else uRecord.display += `, ${CurrentJobNumber.display}`;
      setJobsSelected(uRecord);
    }
  }, [jobsSelected, OnlyThisJob]);
  useEffect(() => {
    let mounted = true;
    if (mounted) getNewTimeEntries(NewTimeEntries, 0, mounted);
    return () => (mounted = false);
  }, [employeeRecords, NewTimeEntries, OnlyThisJob]);
  useEffect(() => {
    let mounted = true;
    const allEmp = [];
    employeeIds.forEach((ei) => {
      allEmp.push(getDoc(doc(firestore, 'ENT-Employees', ei.prtmstid)));
    });
    const allEmpRecords = [];
    Promise.all(allEmp).then((res) => {
      res.forEach((d) => {
        allEmpRecords.push(d.data());
      });
      if (mounted) setEmployeeRecords(allEmpRecords);
    });
    return () => {
      mounted = false;
    };
  }, [employeeIds, OnlyThisJob]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getInvalidPiccs(mounted);
      getRecords(mounted);
    }
    return () => (mounted = false);
  }, [
    CurrentJobNumber.value,
    CurrentDate.value,
    CurrentForeman.value,
    jobsSelected
  ]);
  useEffect(() => {
    getRecords();
  }, [OnlyThisJob]);

  return (
    <>
      <div style={{ padding: '12px 0px' }}>
        {user && !user.admin && !user.manager ? (
          <Alert style={{ whiteSpace: 'pre-wrap' }} severity='info'>
            You are currently signed in as a DJR-User. You will only be able to
            see time records you have entered. If you need to see time entered
            by all Foremen, please contact the Help Desk and request Manager
            access.
          </Alert>
        ) : null}
      </div>
      {user && (user.admin || user.manager) ? (
        <TimeSummaryFilters
          allForDiv={allForDiv}
          setAllForDiv={setAllForDiv}
          AllSubJobTime={AllSubJobTime}
          setAllSubJobTime={setAllSubJobTime}
          classes={classes}
          jobsSelected={jobsSelected}
          editJobsSelected={editJobsSelected}
          CurrentDivision={CurrentDivision}
          CurrentJobNumber={CurrentJobNumber}
        />
      ) : null}
      {CurrentJobNumber.value.length > 0 && (
        <Grid item xs={12}>
          <Box
            sx={{
              '& *': {
                fontWeight: 'bold'
              }
            }}
          >
            <InputCheckbox
              checkboxes={[
                {
                  value: 'true',
                  label: `View Time for only for ${CurrentJobNumber.display}`
                }
              ]}
              LabelProps={{
                label: ''
              }}
              name='OnlyThisJob'
              onChange={() => {
                setOnlyThisJob(!OnlyThisJob);
              }}
              value={OnlyThisJob.toString()}
            />
          </Box>
        </Grid>
      )}
      <div style={{ padding: matches ? '0px 0px 10px 0px' : '10px 0px' }}>
        {allForDiv
          ? `Time Summary for Crew Members in `
          : `Time Summary for the Crew on ${jobsSelected.ids.length > 1 ? 'Jobs ' : 'Job '
          }`}
        <b>
          {
            // eslint-disable-next-line no-nested-ternary
            allForDiv
              ? `Division ${CurrentDivision}`
              : AllSubJobTime
                ? `${CurrentJobNumber.display} and it's Sub Jobs`
                : jobsSelected.display
          }
        </b>{' '}
        for Week Ending{' '}
        <b>{dayjs(weekEndDate(CurrentDate.value)).format('MMMM D, YYYY')}</b>
      </div>
      <Grid container>
        <Modal open={displayBoxOpen} className={classes.modal}>
          <div className={classes.timePaper}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '8px'
              }}
            >
              <h5 style={{ textDecoration: 'underline' }}>Add Time Record</h5>
            </div>

            <div
              style={{
                position: 'relative',
                flex: '1 1 auto',
                padding: '1rem'
              }}
            >
              <TimeEntryRecord
                record={selectedRecord}
                closePopup={() => displayChangeBox(false)}
                getRecords={getRecords}
              />
            </div>
          </div>
        </Modal>
        <Grid container>
          <Grid item xs={12}>
            {[...new Set(badPiccs)].length > 0 ? (
              <Alert color='warning'>
                {[...new Set(badPiccs)].map((p) => (
                  <div style={{ textAlign: 'left' }} key={p}>{`"${invalidPiccs.filter((r) => r.jctmstid === p)[0]?.PICC
                    } - ${invalidPiccs.filter((r) => r.jctmstid === p)[0]?.Description
                    }" is currently being used and is INACTIVE in JobCost. Please contact your Project Manager or JobCost to reactivate the PICC.`}</div>
                ))}
                <br />
                <div style={{ textAlign: 'left' }}>
                  Employee records with affected PICCs will be highlighted in
                  red.
                </div>
                <div style={{ textAlign: 'left' }}>
                  Please allow up to an hour after the code has been reactivated
                  to see any changes.
                </div>
              </Alert>
            ) : null}
          </Grid>
        </Grid>
        <Grid container style={{ display: 'contents' }}>
          <div
            style={{
              position: 'sticky',
              top: '-1px',
              left: 0,
              right: 0,
              width: '100%',
              height: '56px',
              padding: '8px',
              margin: '8px 0px',
              display: 'flex',
              justifyContent: 'flex-end',
              zIndex: 100,
              alignSelf: 'flex-start'
            }}
          >
            <Grid
              item
              xs={12}
              className={customClasses.button}
              style={{
                justifyContent: 'center',
                whiteSpace: 'nowrap',
                paddingRight: '0px'
              }}
            >
              <TimeSummaryTimeTypesFilters
                customClasses={customClasses}
                AddTime={AddTime}
                selectFilter={selectFilter}
                filterSelected={filterSelected}
                matches={matches}
                iconClasses={iconClasses}
                checkLockout={checkLockout}
              />
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <TableContainer style={{ marginBottom: '16px' }}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: 'white' }}>
                <TableCell className={classes.tableHeader}>Employee</TableCell>
                <TableCell
                  align='center'
                  style={{ padding: '12px 0px' }}
                  className={classes.tableHeader}
                >
                  Mon
                </TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Tue
                </TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Wed
                </TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Thu
                </TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Fri
                </TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Sat
                </TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Sun
                </TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Loading ? (
                <TableRow style={{ textAlign: 'center' }}>
                  <TableCell colSpan={9} style={{ textAlign: 'center' }}>
                    <Loader type='TailSpin' height={56} />
                  </TableCell>
                </TableRow>
              ) : null}
              {Loading
                ? null
                : aggTimeDisplay.map((crew) => (
                  <TableRow
                    className={classes.row1}
                    style={{ cursor: 'pointer' }}
                    key={crew.Employee}
                    onClick={() =>
                      history.push(`time-summary/${crew.Employee}`)
                    }
                    title={`${crew.Foremans.filter((c) => c !== '').join(
                      ', '
                    )} are the Foreman(s) who have this person on their Crew.`}
                  >
                    <TableCell
                      className={classes.tableCell}
                      style={{
                        color: crew.containsInvalidPicc ? 'red' : 'black'
                      }}
                    >
                      {crew.EmployeeDisplay}
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{
                        color: getColor(crew.Mon)
                      }}
                      className={classes.tableCell}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: displayDailyTotalHrs(crew.Mon)
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{
                        color: getColor(crew.Tue)
                      }}
                      className={classes.tableCell}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: displayDailyTotalHrs(crew.Tue)
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{
                        color: getColor(crew.Wed)
                      }}
                      className={classes.tableCell}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: displayDailyTotalHrs(crew.Wed)
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{
                        color: getColor(crew.Thu)
                      }}
                      className={classes.tableCell}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: displayDailyTotalHrs(crew.Thu)
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{
                        color: getColor(crew.Fri)
                      }}
                      className={classes.tableCell}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: displayDailyTotalHrs(crew.Fri)
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{
                        color: getColor(crew.Sat)
                      }}
                      className={classes.tableCell}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: displayDailyTotalHrs(crew.Sat)
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{
                        color: getColor(crew.Sun)
                      }}
                      className={classes.tableCell}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: displayDailyTotalHrs(crew.Sun)
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{
                        color: getColor(crew.Total)
                      }}
                      className={classes.tableCell}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: displayTotalHrs(crew)
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TimeSummaryLegend customClasses={customClasses} />
      </Grid>
    </>
  );
};
TimeSummaryList.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  checkLockout: PropTypes.bool
};
TimeSummaryList.defaultProps = {
  history: {
    push: () => { }
  },
  checkLockout: false
};

export default TimeSummaryList;
