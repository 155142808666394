/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useMemo } from "react";
// import {
//   Autocomplete,
//   Grid,
//   Title,
//   Container,
//   NumberInput,
//   Button,
//   Table,
//   Text,
//   ActionIcon,
// } from "@mantine/core";
import { Autocomplete, Grid, Typography, TextField, Button, Table, Paper, TableHead, TableRow, TableCell, TableBody, useTheme, useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { ToolTipIconButton } from "@aldridge/aldg-ui-components";
import { isTablet, isMobile } from "react-device-detect";

export default function CompletedHoursSection({ form, Employees, Crew }) {
  const [rows, setRows] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  // const record = form.getValues();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const showLabels = useMemo(() => isMobile || isTablet || matches, [isMobile, isTablet, matches]);


  form.watch('id', ({ previousValue, value }) => {
    if (value !== '') {

      const values = form.getValues();
      if (values.data.TimeEntry) {
        setRows(values.data.TimeEntry);
      }

      const employees = values?.data?.EmployeesWorkingOnTask;
      const WorkingEmployees = Crew.filter((e) => employees.includes(e.id));
      setEmployeeList(WorkingEmployees);
    }
  });

  useEffect(() => {
    const employees = form.values?.data?.EmployeesWorkingOnTask;
    const WorkingEmployees = Crew.filter((e) => employees.includes(e.id));
    setEmployeeList(WorkingEmployees);
  }, [Crew]);

  useEffect(() => {
    const timeEntries = Object.values(rows).map(row => ({
      Employee: row.Employee,
      EmployeeDisplay: row.EmployeeDisplay,
      Union: row.Union,
      UnionDisplay: row.UnionDisplay,
      DefaultPerDiem: row.DefaultPerDiem,
      CrewId: row.CrewId,
      Shift: row.Shift,
      Regular: row.Regular,
      Overtime: row.Overtime,
      Doubletime: row.Doubletime,
      IsAldridgeEmployee: row.IsAldridgeEmployee,
      Company: row.Company,
    }));

    console.log('timeEntries', timeEntries);
    form.setFieldValue("data.TimeEntry", timeEntries);
  }, [rows]);

  const addRow = () => {
    const uRows = [...rows];
    uRows.push({ Employee: "", EmployeeDisplay: "", Union: "", UnionDisplay: "", DefaultPerDiem: "", CrewId: "", Shift: "1", Regular: "", Overtime: "", Doubletime: "", id: uRows.length, IsAldridgeEmployee: true, Company: "" });
    setRows(uRows);
  };

  const roundTo25 = (val) => {
    let vVal = val;
    const numValue = parseFloat(vVal);
    if (!Number.isNaN(numValue)) {
      const rounded = Math.round(numValue * 4) / 4;
      vVal = rounded.toString();
    }
    return vVal;
  };
  const updateRow = (employeeKey, field, value) => {
    const row = rows.find(r => r.Employee === employeeKey);
    const vVal = value;
    if (field === "Regular" || field === "Overtime" || field === "Doubletime") {
      // Parse value and round to nearest 0.25

    }
    if (row) {
      row[field] = vVal;
      setRows([...rows]);
    }
  };

  const deleteRow = (employeeKey) => {
    const newRows = rows.filter(row => row.Employee !== employeeKey);
    setRows(newRows);
  };

  const loadEmployees = () => {
    if (form.values?.data?.EmployeesWorkingOnTask) {
      const employees = form.values?.data?.EmployeesWorkingOnTask;
      const WorkingEmployees = Crew.filter((e) => employees.includes(e.id));
      setEmployeeList(WorkingEmployees);
      const newRows = [];
      WorkingEmployees.forEach((employee) => {
        newRows.push({ Employee: employee.data.Employee[0], EmployeeDisplay: employee.data.EmployeeDisplay, Union: employee.data.Union[0], UnionDisplay: employee.data.UnionDisplay, DefaultPerDiem: employee.data.DefaultPerDiem || '', CrewId: employee.id, Shift: "1", Regular: "", Overtime: "", Doubletime: "", IsAldridgeEmployee: employee.data.IsAldridgeEmployee === undefined ? true : employee.data.IsAldridgeEmployee, Company: employee.data.Company?.[0] || '1' });
      });
      setRows(newRows);
      form.setFieldValue("data.TimeEntry", newRows);
    }
  };

  return (
    <Paper sx={{
      padding: 2,
      '& .MuiGrid-item': {
        padding: 1
      }
    }}>
      <Grid container>
        <Grid item xs={12}>
          <h2>
            Completed Hours
          </h2>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <Button onClick={loadEmployees} variant="contained" color="primary">
              Load Employees that Worked on This Task
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ display: "flex", justifyContent: "space-between", fontWeight: "bold" }}>
              Hours Log
              <ToolTipIconButton icon={faPlus} shape="circle" onClick={addRow} />

            </Typography>

            <Grid container spacing={1} sx={{
              width: "100%",

            }}>
              <Grid container item xs={12} sx={{ fontWeight: 'bold', borderBottom: '1px solid rgba(0,0,0,0.12)', display: showLabels ? 'none' : 'flex' }}>
                <Grid item xs={3}>Employee</Grid>
                <Grid item xs={1}>Shift</Grid>
                <Grid item xs={2}>Regular</Grid>
                <Grid item xs={2}>Overtime</Grid>
                <Grid item xs={2}>Doubletime</Grid>
                <Grid item xs={2}>Actions</Grid>
              </Grid>

              {rows.map((row, idx) => (
                <Grid container item xs={12} key={row.Employee} sx={{
                  borderBottom: "1px solid lightgray",
                  backgroundColor: idx % 2 === 0 ? 'white' : 'rgba(0,0,0,0.04)',
                  alignItems: 'center',
                  '& .MuiGrid-item': {
                    padding: 0.5
                  }
                }}>
                  <Grid item md={3} xs={12}>
                    <Autocomplete
                      renderInput={(params) => <TextField {...params} label={showLabels ? 'Employee' : ''} />}
                      style={{ padding: 3 }}
                      options={employeeList}
                      getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                          const employee = employeeList.find((e) => e.data.Employee?.[0] === option);
                          return employee ? `${employee.data.EmployeeDisplay}` : '';
                        }
                        return option.data.EmployeeDisplay;
                      }}
                      getOptionDisabled={(value) => {
                        const record = form.getValues();
                        return record.data.TimeEntry.map(r => r.CrewId).includes(value.id);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.data?.Employee?.[0] === value
                      }
                      value={row.Employee}
                    />
                  </Grid>
                  <Grid item md={1} xs={12}>
                    <Autocomplete
                      renderInput={(params) => <TextField {...params} label={showLabels ? 'Shift' : ''} />}
                      style={{ padding: 3 }}
                      options={["1", "2", "3"]}
                      value={row.Shift}
                      onChange={(event, value) => updateRow(row.Employee, "Shift", value)}
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      fullWidth
                      label={showLabels ? 'Regular' : ''}
                      style={{ padding: 3 }}
                      value={row.Regular}
                      min={0}
                      onChange={(event) => updateRow(row.Employee, "Regular", event.target.value)}
                      onBlur={(event) => updateRow(row.Employee, "Regular", roundTo25(event.target.value))}
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      fullWidth
                      label={showLabels ? 'Overtime' : ''}
                      style={{ padding: 3 }}
                      value={row.Overtime}
                      onChange={(event) => updateRow(row.Employee, "Overtime", event.target.value)}
                      onBlur={(event) => updateRow(row.Employee, "Overtime", roundTo25(event.target.value))}
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      fullWidth
                      label={showLabels ? 'Doubletime' : ''}
                      style={{ padding: 3 }}
                      value={row.Doubletime}
                      onChange={(event) => updateRow(row.Employee, "Doubletime", event.target.value)}
                      onBlur={(event) => updateRow(row.Employee, "Doubletime", roundTo25(event.target.value))}
                    />
                  </Grid>
                  <Grid item md={2} xs={12} sx={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                    <ToolTipIconButton icon={faTrash} color="error" shape="circle" onClick={() => deleteRow(row.Employee)} />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}