/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@mui/material';
import { InputAutocomplete } from '@aldridge/aldg-data-components';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { toast } from 'react-toastify';
import { collection, getDocs, onSnapshot, query, where, writeBatch } from 'firebase/firestore';
import { firestore } from '../firebase';


const TerminatedForeman = (props) => {
  const [selectedForeman, setSelectedForeman] = useState([]);
  const [foremanCrew, setForemanCrew] = useState(0);


  useEffect(() => {
    document.title = 'Terminated Foreman';
  }, []);
  useEffect(() => {
    if (selectedForeman.length > 0) {
      onSnapshot(query(collection(firestore, 'Crew'), where('data.Foreman', '==', selectedForeman[0])), (querySnapshot) => {
        setForemanCrew(querySnapshot.size);
      });
    }
  }, [selectedForeman]);
  const RemoveCrewMembers = () => {
    if (selectedForeman.length > 0) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (confirm("This action cannot be undone. Would you like to remove all the crew members associated to this foreman?")) {
        console.log('Remove Crew Members');
        getDocs(query(collection(firestore, 'Crew'), where('data.Foreman', '==', selectedForeman[0]))).then(async (querySnapshot) => {
          let batch = writeBatch(firestore);
          for (let i = 0; i < querySnapshot.size; i++) {
            if (i % 500 === 0) {
              // eslint-disable-next-line no-await-in-loop
              await batch.commit();
              batch = writeBatch(firestore);
            }
            batch.delete(querySnapshot.docs[i].ref);
          }
          await batch.commit();
          toast.success(`"${querySnapshot.size}" Crew Member(s) Removed.`);
        });

      }
    } else {
      toast.error('Please select a Foreman to remove Crew Members.');
    }
  };
  return (
    <Paper>
      <Grid container
        style={{ padding: '16px' }}>
        <Grid item xs={12}>
          <h1>Terminated Foreman Crew Clean Up</h1>
        </Grid>
        <Grid item xs={12}>
          Please select a Foreman to remove all Crew members associated with them.
        </Grid>
        <Grid item xs={12}>
          <InputAutocomplete
            firestoreOptions={{
              collection: 'ENT-Employees',
              valueKey: 'prtmstid',
              method: 'get',
              where: [['Company', '==', '1']],
              orderBy: ['LegalLastName', 'LegalFirstName']
            }}
            label="Terminated Foreman"
            onChange={(e) => { console.log(e); setSelectedForeman(e); }}
            optionDisplay={(opts) => {
              let display = `${opts.LegalLastName} ${opts.Suffix || ''}, ${opts.PreferredFirstName
                } ${opts.MiddleName} - [${opts.Title} - ${opts.UniversalID
                } (Status: ${opts.Status})]`.replace(/ {2}/g, ' ');
              if (Object.keys(opts).length === 0) return '';
              if (opts.IsAldridgeEmployee === false) {
                display += ` - [${opts.Company}]`;
              }
              return display;
            }}
            value={selectedForeman}
          />
        </Grid>
        <Grid item xs={12} sx={{ py: 1 }}>
          This Foreman has {foremanCrew} Crew Members associated with them.
        </Grid>
        <Grid item xs={12} sx={{ py: 1 }}>
          <ToolTipIconButton label="Remove all Crew Members" icon={faTimes} color="error" onClick={RemoveCrewMembers} />
        </Grid>
      </Grid>
    </Paper>
  );
};


TerminatedForeman.propTypes = {};
TerminatedForeman.defaultProps = {};


export default TerminatedForeman;