import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Modal, Grid, Button, FormHelperText } from '@mui/material';
import { InputAutocomplete } from '@aldridge/aldg-data-components';
import {
  getDoc,
  getDocs,
  onSnapshot,
  setDoc,
  where,
  query,
  collection,
  doc
} from 'firebase/firestore';
import { basicStyles } from '../../theme';
import { firestore, firebaseConfig } from '../../firebase';
import { UserContext } from '../../providers/UserProvider';

const CrewMemberPopup = (props) => {
  const { user } = useContext(UserContext);

  const { closePopup, open, foreman, foremanDisplay, jobnumber } = props;
  const [jobMembers, setJobMembers] = useState([]);
  const [disableE, setDisableE] = useState(false);
  const [disableU, setDisableU] = useState(true);
  const [record, setRecord] = useState({
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    CrewMembers: [],
    Employee: [],
    Union: []
  });
  const mounted = useRef(null);
  const classes = basicStyles();
  const [Job, setJob] = useState('');

  useEffect(() => {
    mounted.current = true;
    if (typeof jobnumber !== 'undefined' && jobnumber.length > 0) {
      getDocs(
        query(
          collection(firestore, 'ENT-Jobs'),
          where('jctdscid', '==', jobnumber)
        )
      ).then((res) => {
        if (mounted.current) {
          setJob(res.docs[0].data().JobNumber);
        }
      });
    }
    return () => {
      mounted.current = false;
    };
  }, [jobnumber]);

  useEffect(() => {
    const allMembers = [];
    let isMounted = true;
    if (foreman === '' || typeof foreman === 'undefined') return () => null;
    const q = query(
      collection(firestore, 'Crew'),
      where('data.Foreman', '==', foreman)
    );
    const unsub = onSnapshot(q, (r) => {
      r.forEach((d) => {
        allMembers.push(d.data());
      });
      if (isMounted) {
        setJobMembers(allMembers);
        setRecord({
          CreatedBy: '',
          CreatedDate: '',
          CrewMembers: []
        });
      }
    });
    return () => {
      isMounted = false;
      unsub();
    };
  }, [foreman]);

  useEffect(() => {
    const allMembers = [];
    let isMounted = true;
    if (jobnumber === '' || typeof jobnumber === 'undefined') return () => null;
    const q = query(
      collection(firestore, 'Crew'),
      where('data.JobNumber', '==', jobnumber)
    );
    const unsub = onSnapshot(q, (r) => {
      r.forEach((d) => {
        allMembers.push(d.data());
      });
      if (isMounted) {
        setJobMembers(allMembers);
        setRecord({
          CreatedBy: '',
          CreatedDate: '',
          CrewMembers: []
        });
      }
    });
    return () => {
      isMounted = false;
      unsub();
    };
  }, [jobnumber]);

  const shouldBeDisabledE = () => {
    if (typeof record.Employee === 'undefined') return false;
    if (record.Employee.length > 0) return true;
    return false;
  };
  const shouldBeDisabledU = () => {
    // console.log(record.CrewMembers);
    if (typeof record.CrewMembers === 'undefined') return false;
    if (record.CrewMembers.length > 0) return true;
    return false;
  };

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      setDisableE(shouldBeDisabledE());
      setDisableU(!shouldBeDisabledU());
    }
    return () => (mounted.current = false);
  }, [record]);

  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...record };
      changedRecord[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changedRecord[`${id}Display`] = displayFromTypeahead;
      }
      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      if (mounted.current) setRecord(changedRecord);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };
  const addToCrew = () => {
    if (record.CrewMembers.length > 0) {
      record.CrewMembers.forEach((rec) => {
        getDoc(doc(firestore, 'Crew', rec)).then((r) => {
          if (r.exists()) {
            const clonedR = JSON.parse(JSON.stringify(r.data()));

            const docRef = doc(collection(firestore, 'Crew'));
            clonedR.id = docRef.id;
            // eslint-disable-next-line prefer-destructuring
            clonedR.data.Foreman = foreman;
            clonedR.data.ForemanDisplay = foremanDisplay;

            if (mounted.current)
              setDoc(doc(firestore, 'Crew', clonedR.id), clonedR, {
                merge: true
              });
            toast.success(
              `${clonedR.data.EmployeeDisplay} [${clonedR.data.UnionDisplay}] added to your crew.`
            );
            closePopup();
          }
        });
      });
    }
    if (
      typeof record.Employee !== 'undefined' &&
      record.Employee.length > 0 &&
      typeof record.Union !== 'undefined' &&
      record.Union.length > 0
    ) {
      const crewTransaction = {
        id: '',
        CreatedBy: record.CreatedBy,
        CreatedDate: record.CreatedDate,
        ModifiedBy: record.ModifiedBy,
        ModifiedDate: record.ModifiedDate,
        data: {
          JobNumber: !user.admin && user.prefab ? '' : jobnumber || '',
          Job: !user.admin && user.prefab ? '' : Job || '',
          Foreman: foreman || '',
          ForemanDisplay: foremanDisplay || '',
          Employee: record.Employee,
          EmployeeDisplay: record.EmployeeDisplay,
          Union: record.Union,
          UnionDisplay: record.UnionDisplay
        }
      };
      if (
        crewTransaction.data.Employee.length > 0 &&
        crewTransaction.data.Union.length > 0
      ) {
        getDocs(
          query(
            collection(firestore, 'Crew'),
            where('data.Foreman', '==', foreman),
            where('data.Job', '==', Job)
          )
        ).then((fCrews) => {
          const docs = [];
          fCrews.forEach((d) => docs.push(d.data()));
          const empUnionExists = docs.filter(
            (res) =>
              res.data.Employee[0] === crewTransaction.data.Employee[0] &&
              res.data.Union[0] === crewTransaction.data.Union[0] &&
              res.data.JobNumber === jobnumber
          );
          if (empUnionExists.length > 0) {
            toast.error(
              `${crewTransaction.data.EmployeeDisplay} [${crewTransaction.data.UnionDisplay}] already exists on your crew.`
            );
          } else {
            const docRef = doc(collection(firestore, 'Crew'));
            crewTransaction.id = docRef.id;
            if (mounted.current)
              setDoc(docRef, JSON.parse(JSON.stringify(crewTransaction)));
            toast.success(
              `${crewTransaction.data.EmployeeDisplay} [${crewTransaction.data.UnionDisplay}] added to your crew.`
            );
            closePopup();
          }
        });
      }
    } else if (record.CrewMembers.length === 0) {
      toast.error(
        'Please Enter both an Employee and a Union to add this member to your crew.'
      );
    }
  };
  const onEmployeeChange = (event, name, displayFromTypeahead) => {
    onChange(event, name, displayFromTypeahead.split(' - [')[0]);
  };
  return (
    <Modal open={open} className={classes.modal}>
      <div className={classes.timePaper}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1rem'
          }}
        >
          <h5 style={{ textDecoration: 'underline' }}>Add Crew Member</h5>
        </div>
        <div
          style={{ position: 'relative', flex: '1 1 auto', padding: '1rem' }}
        >
          {foreman === '' ? (
            <>
              <h3>
                Please select a Foreman before trying to add new Crew Members
              </h3>
              <Button variant='contained' color='error' onClick={closePopup}>
                Close
              </Button>
            </>
          ) : (
            <Grid container>
              <Grid item xs={12}>
                <InputAutocomplete
                  firebaseConfig={firebaseConfig}
                  name='CrewMembers'
                  label='Select Crew Members'
                  value={record.CrewMembers}
                  onChange={onChange}
                  firestoreOptions={{
                    method: 'onSnapshot',
                    collection: 'Crew',
                    orderBy: 'data.EmployeeDisplay',
                    where: [
                      ['data.Job', '==', Job],
                      ['data.Foreman', '==', '']
                    ],
                    valueKey: 'id'
                  }}
                  disabled={disableE}
                  enterprise={false}
                  optionDisplay={[
                    'option_EmployeeDisplay',
                    '[',
                    'option_UnionDisplay',
                    ']'
                  ]}
                  filterDataset={(opts) => {
                    const showThese = [];
                    opts.forEach((r) => {
                      const dne = jobMembers.filter(
                        (m) =>
                          m.data.Employee[0] === r.data.Employee[0] &&
                          m.data.Union[0] === r.data.Union[0]
                      );
                      if (dne.length === 0) {
                        showThese.push(r);
                      }
                    });
                    return showThese;
                  }}
                  multiple
                  disablePortal
                  emptyPlaceholder='All Job Crew Members already exist in your Crew.'
                />
              </Grid>

              <Grid item xs={12}>
                <FormHelperText
                  style={{ paddingTop: '24px', textAlign: 'center' }}
                >
                  &mdash;OR Add New Crew Member&mdash;
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <InputAutocomplete
                  firebaseConfig={firebaseConfig}
                  label='Employee'
                  firestoreOptions={{
                    method: 'get',
                    collection: 'ENT-Employees',
                    where: [
                      ['Status', '==', 'A'],
                      ['EmployeeType', 'in', ['Field', 'Fleet']]
                    ],
                    orderBy: ['LegalLastName', 'PreferredFirstName'],
                    valueKey: 'prtmstid'
                  }}
                  name='Employee'
                  disabled={!disableU}
                  onChange={onEmployeeChange}
                  optionDisplay={(opts) => {
                    let display = `${opts.LegalLastName} ${opts.Suffix || ''}, ${opts.PreferredFirstName
                      } ${opts.MiddleName || ''} - [${opts.Title} - ${opts.UniversalID
                      }]`.replace(/ {2}/g, ' ');
                    if (Object.keys(opts).length === 0) return '';
                    if (opts.IsAldridgeEmployee === false) {
                      display += ` - [${opts.CompanyDisplay}]`;
                    }
                    return display;
                  }}
                  value={record.Employee || []}
                  disablePortal
                />
              </Grid>
              <Grid item xs={12}>
                <InputAutocomplete
                  firebaseConfig={firebaseConfig}
                  label='Working Union'
                  firestoreOptions={{
                    method: 'get',
                    collection: 'ENT-Unions',
                    orderBy: 'UnionNumber',
                    where: [['Status', '==', 'Active']],
                    valueKey: 'prtunmid'
                  }}
                  name='Union'
                  onChange={onChange}
                  disabled={!disableU}
                  optionDisplay={[
                    'option_UnionNumber',
                    ' - ',
                    'option_UnionDescription'
                  ]}
                  value={record.Union || []}
                  disablePortal
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ textAlign: 'right', paddingTop: '10px' }}
              >
                <Button variant='contained' color='primary' onClick={addToCrew}>
                  Add To My Crew
                </Button>
                &nbsp;
                <Button variant='contained' color='error' onClick={closePopup}>
                  Close
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    </Modal>
  );
};

CrewMemberPopup.propTypes = {
  closePopup: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  foreman: PropTypes.string,
  foremanDisplay: PropTypes.string,
  jobnumber: PropTypes.string
};
CrewMemberPopup.defaultProps = {
  foreman: '',
  foremanDisplay: '',
  jobnumber: ''
};

export default CrewMemberPopup;
