/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery, Paper, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import {
  InputAutocomplete,
  InputText,
  InputCheckbox
} from '@aldridge/aldg-data-components';
import { useRecoilValue } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { onSnapshot, setDoc, doc, collection, query } from 'firebase/firestore';
import { firestore, firebaseConfig } from '../../firebase';
import { _CurrentJobNumber } from '../../_Recoil/atoms';
import { UserContext } from '../../providers/UserProvider';
import { basicStyles, icons } from '../../theme';

const Equipment = (props) => {
  const classes = basicStyles();
  const { user } = useContext(UserContext);
  const { history, match } = props;
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const [record, setRecord] = useState({
    id: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      JobNumber: CurrentJobNumber.value[0],
      Equipment: [],
      AEEquipment: 'true',
      Make: '',
      Model: '',
      Year: '',
      SerialNumber: '',
      Description: '',
      LicensePlate: '',
      StateLicense: '',
      JobAssigned: '',
      JobLocation: '',
      FleetJobNumber: ''
    }
  });
  const [equipment, setEquipment] = useState([]);

  useEffect(() => {
    let mounted = true;
    if (match.params.id === 'new') return;
    const unsub = onSnapshot(doc(firestore, 'Equipment', match.params.id), (snap) => {
      if (snap.exists()) {
        const d = snap.data();
        if (mounted) setRecord(d);
      }
    });
    // eslint-disable-next-line consistent-return
    return () => {
      mounted = false;
      unsub();
    };
  }, [match.params.id]);

  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...record };
      changedRecord.data[id] = value;
      if (id === 'AEEquipment') {
        changedRecord.data = {
          JobNumber: CurrentJobNumber.value[0],
          Equipment: [],
          AEEquipment: record.data.AEEquipment !== 'true' ? '' : 'true',
          Make: '',
          Model: '',
          Year: '',
          SerialNumber: '',
          Description: '',
          LicensePlate: '',
          StateLicense: '',
          JobAssigned: '',
          JobLocation: '',
          FleetJobNumber: ''
        };
        setRecord(changedRecord);
        return;
      }
      if (typeof displayFromTypeahead !== 'undefined') {
        changedRecord.data[`${id}Display`] = displayFromTypeahead;
      }
      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      setRecord(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const addEquipment = (e, newRecord) => {
    try {
      const savedRecord = JSON.parse(JSON.stringify(record));
      if (match.params.id === 'new') {
        const docRef = doc(collection(firestore, 'Equipment'));
        savedRecord.id = docRef.id;
        setDoc(docRef, savedRecord);
      } else {
        setDoc(doc(firestore, 'Equipment', savedRecord.id), savedRecord, {
          merge: true
        });
      }
      if (newRecord) {
        setRecord({
          id: '',
          CreatedBy: '',
          CreatedDate: '',
          ModifiedBy: '',
          ModifiedDate: '',
          data: {
            JobNumber: CurrentJobNumber.value[0],
            AEEquipment: 'true',
            Equipment: [],
            NonAEEquipment: '',
            Make: '',
            Model: '',
            Year: '',
            SerialNumber: '',
            Description: '',
            LicensePlate: '',
            StateLicense: '',
            JobAssigned: '',
            JobLocation: '',
            FleetJobNumber: ''
          }
        });
        history.push('/Equipment/new');
      } else {
        history.push('/Equipment');
      }
      toast.success('Equipment saved successfully!', { autoClose: 5000 });
    } catch (err) {
      toast.error('Error saving record.', { autoClose: 5000 });
    }
  };

  const goBack = () => {
    history.push('/Equipment');
  };
  useEffect(() => {
    let mounted = true;
    const q = query(collection(firestore, 'Equipment'));
    const unsub = onSnapshot(q, (res) => {
      const allEquipment = [];
      res.forEach((d) => allEquipment.push({ ...d.data(), id: d.id }));
      if (mounted) setEquipment(allEquipment);
    });

    return () => {
      mounted = false;
      unsub();
    };
  }, []);
  useEffect(() => {
    let mounted = true;
    const updatedEquip = { ...record };
    let unsub = () => { };
    if (record.data.Equipment.length > 0) {
      unsub = onSnapshot(
        doc(firestore, 'ENT-Equipment', record.data.Equipment[0]),
        (snap) => {
          if (snap.exists()) {
            const r = snap.data();
            updatedEquip.data.Make = r.Make;
            updatedEquip.data.Model = r.Model;
            updatedEquip.data.Year = r.Year;
            updatedEquip.data.Description = r.Description;
            updatedEquip.data.SerialNumber = r.SerialNumber;
            updatedEquip.data.LicensePlate = r.LicensePlate;
            updatedEquip.data.StateLicense = r.StateLicense;
            updatedEquip.data.JobAssigned = r.JobAssigned;
            updatedEquip.data.JobLocation = r.JobLocation;
            updatedEquip.data.FleetJobNumber = r.JobNumber;
          } else {
            updatedEquip.data.Make = '';
            updatedEquip.data.Model = '';
            updatedEquip.data.Year = '';
            updatedEquip.data.Description = '';
            updatedEquip.data.SerialNumber = '';
            updatedEquip.data.LicensePlate = '';
            updatedEquip.data.StateLicense = '';
            updatedEquip.data.JobAssigned = '';
            updatedEquip.data.JobLocation = '';
            updatedEquip.data.FleetJobNumber = '';
          }
          if (mounted) setRecord(updatedEquip);
        }
      );
    }
    return () => {
      mounted = false;
      unsub();
    };
  }, [record.data.Equipment]);
  const iconClasses = icons();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div>
      <div
        style={{
          position: 'sticky',
          top: '-1px',
          left: 0,
          right: 0,
          width: '100%',
          padding: '8px 0px 8px 8px',
          display: 'flex',
          justifyContent: 'flex-end',
          zIndex: 100
        }}
      >
        <div
          className={`${iconClasses.container} ${iconClasses.greenContainer}`}
          style={{ margin: matches ? '' : '0px 12px' }}
          title='Save Record'
        >
          <Button
            onClick={addEquipment}
            disableRipple
            className={iconClasses.buttonWrapper}
          >
            <FontAwesomeIcon icon={faSave} className={iconClasses.icon} />
          </Button>
        </div>

        <div
          className={`fa-layers fa-fw ${iconClasses.container} ${iconClasses.blueContainer}`}
          style={{ margin: matches ? '0px -8px' : '0px 12px' }}
          title='Save and New Record'
        >
          <Button
            onClick={(event) => addEquipment(event, true)}
            disableRipple
            className={iconClasses.buttonWrapper}
          >
            <FontAwesomeIcon
              style={{ fontSize: '24px', color: 'white' }}
              icon={faSave}
            />
            <FontAwesomeIcon
              style={{ color: 'black', top: '-14px', left: '16px' }}
              icon={faSparkles}
            />
          </Button>
        </div>
        <div
          className={`${iconClasses.container} ${iconClasses.redContainer}`}
          style={{ margin: '0px 0px 0px 12px' }}
          title='Close Record'
        >
          <Button
            onClick={goBack}
            disableRipple
            className={iconClasses.buttonWrapper}
          >
            <FontAwesomeIcon icon={faTimes} className={iconClasses.icon} />
          </Button>
        </div>
      </div>
      <Paper className={classes.paperWMargin}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            style={{ paddingBottom: '0px', whiteSpace: 'pre-wrap' }}
          >
            <InputCheckbox
              checkboxes={[
                { value: 'true', label: 'This piece of equipment has an AE equipment number.' },
              ]}
              LabelProps={{
                label: '',
              }}
              name="AEEquipment"
              onChange={onChange}
              value={typeof record.data.AEEquipment === 'undefined'
                ? ''
                : record.data.AEEquipment}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {record.data.AEEquipment === 'true' ? (
              <InputAutocomplete
                firebaseConfig={firebaseConfig}
                key={record.id}
                firestoreOptions={{
                  method: 'get',
                  collection: 'ENT-Equipment',
                  valueKey: 'UnitNumber',
                  orderBy: 'UnitNumber'
                }}
                onChange={onChange}
                value={record.data.Equipment || []}
                name='Equipment'
                label='Equipment'
                optionDisplay={['option_UnitNumber', '-', 'option_Description']}
              />
            ) : (
              <InputText
                name='NonAEEquipment'
                label='Equipment (Non Aldridge Equipment)'
                value={record.data.NonAEEquipment || ''}
                onChange={onChange}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <InputText
              label='Serial Number'
              name='SerialNumber'
              onChange={onChange}
              value={record.data.SerialNumber}
              disabled={record.data.AEEquipment === 'true'}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputText
              label='Make'
              name='Make'
              onChange={onChange}
              value={record.data.Make}
              disabled={record.data.AEEquipment === 'true'}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputText
              label='Model'
              name='Model'
              onChange={onChange}
              value={record.data.Model}
              disabled={record.data.AEEquipment === 'true'}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputText
              label='Year'
              name='Year'
              onChange={onChange}
              value={record.data.Year}
              disabled={record.data.AEEquipment === 'true'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <InputText
              label='License Plate'
              name='LicensePlate'
              onChange={onChange}
              value={record.data.LicensePlate}
              disabled={record.data.AEEquipment === 'true'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <InputText
              label='License Plate State'
              name='StateLicense'
              onChange={onChange}
              value={record.data.StateLicense}
              disabled={record.data.AEEquipment === 'true'}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputText
              label='Description'
              name='Description'
              onChange={onChange}
              value={record.data.Description}
              disabled={record.data.AEEquipment === 'true'}
            />
          </Grid>
          <Grid item xs={12} sm={4} hidden={record.data.AEEquipment !== 'true'}>
            <InputText
              label='Job Assigned'
              name='JobAssigned'
              onChange={onChange}
              value={record.data.JobAssigned}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4} hidden={record.data.AEEquipment !== 'true'}>
            <InputText
              label='Fleet Job Number'
              name='FleetJobNumber'
              onChange={onChange}
              value={record.data.FleetJobNumber}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4} hidden={record.data.AEEquipment !== 'true'}>
            <InputText
              label='Job Location'
              name='JobLocation'
              onChange={onChange}
              value={record.data.JobLocation}
              disabled
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
Equipment.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired
};
Equipment.defaultProps = {};

export default Equipment;
