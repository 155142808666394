import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Switch,
  Grid,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { faSunrise, faSunset } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSetRecoilState, useRecoilValue } from 'recoil';
// import PODObject from 'DataStructures/POD';
import { SizeContext } from 'providers/SizeProvider';
import NewDay from './NewDay';
import EndDay from './EndDay';
import {
  _HeaderTitle,
  _CurrentDate,
  _CurrentForeman,
  _CurrentJobNumber
} from '../_Recoil/atoms';
import { UserContext } from '../providers/UserProvider';

const POD = (props) => {
  document.title = 'P.O.D.';
  const [checked, setChecked] = React.useState(false);
  const { history, match } = props;
  const size = useContext(SizeContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const setHeaderTitle = useSetRecoilState(_HeaderTitle);
  useEffect(() => setHeaderTitle('P.O.D.'), []);

  const handleChange = (check) => {
    setChecked(check);
  };

  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const CurrentDate = useRecoilValue(_CurrentDate);
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);

  const { user } = useContext(UserContext);
  const [record, setRecord] = useState({
    id: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    ScreenSize: '',
    data: {
      PreFabRecord: user.prefab,
      Foreman: CurrentForeman.value[0],
      ForemanDisplay: CurrentForeman.shortDisplay,
      JobNumber: CurrentJobNumber.value[0],
      JobNumberDisplay: CurrentJobNumber.display,
      EmployeesWorkingOnTask: [],
      AssociatedPicc: [],
      AllWBS: [],
      Level1: '',
      Level2: '',
      Level3: '',
      Level4: '',
      Date: CurrentDate.value || new Date().toJSON(),
      HideCompleted: 'true',
      Task: '',
      TaskType: user.prefab && !user.admin ? 'PreFab' : 'WBS',
      OrderNumber: '',
      PICC: '',
      ForceNumber: '',
      ShowOnlyMyCrew: 'true',
      NeedByDate: new Date().toJSON(),
      StartDay: {
        ExpectedProductionQuantity: '',
        UoM: '',
        PlannedShiftHours: ''
      },
      EndDay: {
        CompletedProductionQuantity: '',
        PercentComplete: '',
        ObstacleCode: null,
        SubObstacleCode: [],
        HoursImpacted: '',
        PeopleImpacted: '',
        ReasonsForImpact: '',
        ObstacleCode2: [],
        SubObstacleCode2: [],
        HoursImpacted2: '',
        PeopleImpacted2: '',
        ReasonsForImpact2: ''
      }
    }
    // id: '',
    // CreatedBy: '',
    // CreatedDate: '',
    // ModifiedBy: '',
    // ModifiedDate: '',
    // ScreenSize: size,
    // data: {
    //   PreFabRecord: user.prefab || false,
    //   Foreman: CurrentForeman.value[0] || '',
    //   ForemanDisplay: CurrentForeman.shortDisplay || '',
    //   JobNumber: CurrentJobNumber.value[0] || '',
    //   JobNumberDisplay: CurrentJobNumber.display || '',
    //   EmployeesWorkingOnTask: [],
    //   AssociatedPicc: [],
    //   AllWBS: [],
    //   Category1: '',
    //   Category2: '',
    //   Category3: '',
    //   Category4: '',
    //   Date: CurrentDate.value || '',
    //   HideCompleted: 'true',
    //   Task: '',
    //   TaskType: user.prefab && !user.admin ? 'PreFab' : 'WBS',
    //   OrderNumber: '',
    //   PICC: '',
    //   ForceNumber: '',
    //   ShowOnlyMyCrew: 'true',
    //   StartDay: {
    //     ExpectedProductionQuantity: '',
    //     UoM: user.prefab ? 'ea' : '',
    //     PlannedShiftHours: ''
    //   },
    //   EndDay: {
    //     CompletedProductionQuantity: '',
    //     PercentComplete: '',
    //     ObstacleCode: [],
    //     SubObstacleCode: [],
    //     HoursImpacted: '',
    //     PeopleImpacted: '',
    //     ReasonsForImpact: '',
    //     ObstacleCode2: [],
    //     SubObstacleCode2: [],
    //     HoursImpacted2: '',
    //     PeopleImpacted2: '',
    //     ReasonsForImpact2: ''
    //   }
    // }
  });

  useEffect(() => {
    const uRecord = { ...record };
    uRecord.ScreenSize = size;
    setRecord(uRecord);
  }, [size]);

  // useEffect(() => {
  //   const newObject = PODObject.structuredClone();
  //   newObject.data.Foreman = CurrentForeman.value[0] || '';
  //   newObject.data.ForemanDisplay = CurrentForeman.shortDisplay || '';
  //   newObject.data.JobNumber = CurrentJobNumber.value[0] || '';
  //   newObject.data.JobNumberDisplay = CurrentJobNumber.display || '';
  //   newObject.data.Date = CurrentDate.value || '';
  //   newObject.data.TaskType = (user.prefab && !user.admin) ? 'PreFab' : 'WBS';
  //   newObject.data.StartDay.UoM = user.prefab ? 'ea' : '';
  //   setRecord(newObject);
  // }, []);

  useEffect(() => {
    let mounted = true;
    if (typeof match.params.id === 'undefined') {
      if (mounted) {
        setRecord({
          id: '',
          CreatedBy: '',
          CreatedDate: '',
          ModifiedBy: '',
          ModifiedDate: '',
          ScreenSize: '',
          data: {
            PreFabRecord: false,
            Foreman: CurrentForeman.value[0],
            ForemanDisplay: CurrentForeman.shortDisplay,
            JobNumber: CurrentJobNumber.value[0],
            JobNumberDisplay: CurrentJobNumber.display,
            EmployeesWorkingOnTask: [],
            AssociatedPicc: [],
            AllWBS: '',
            Level1: '',
            Level2: '',
            Level3: '',
            Level4: '',
            Date: CurrentDate.value || new Date().toJSON(),
            HideCompleted: 'true',
            Task: '',
            TaskType: user.prefab && !user.admin ? 'PreFab' : 'WBS',
            OrderNumber: '',
            PICC: '',
            ForceNumber: '',
            ShowOnlyMyCrew: 'true',
            NeedByDate: new Date().toJSON(),
            StartDay: {
              ExpectedProductionQuantity: '',
              UoM: '',
              PlannedShiftHours: ''
            },
            EndDay: {
              CompletedProductionQuantity: '',
              PercentComplete: '',
              ObstacleCode: null,
              SubObstacleCode: [],
              HoursImpacted: '',
              PeopleImpacted: '',
              ReasonsForImpact: '',
              ObstacleCode2: [],
              SubObstacleCode2: [],
              HoursImpacted2: '',
              PeopleImpacted2: '',
              ReasonsForImpact2: ''
            }
          }
          // id: '',
          // path: ``,
          // collection: 'Tasks',
          // CreatedBy: '',
          // CreatedDate: '',
          // ModifiedBy: '',
          // ModifiedDate: '',
          // JobNumber: '',
          // data: {
          //   Foreman: CurrentForeman.value[0] || '',
          //   ForemanDisplay: CurrentForeman.shortDisplay || '',
          //   JobNumber: CurrentJobNumber.value[0] || '',
          //   JobNumberDisplay: CurrentJobNumber.display || '',
          //   EmployeesWorkingOnTask: [],
          //   AssociatedPicc: [],
          //   AllWBS: [],
          //   HideCompleted: 'true',
          //   Category1: '',
          //   Category2: '',
          //   Category3: '',
          //   Category4: '',
          //   Date: CurrentDate.value || '',
          //   Task: '',
          //   TaskType: user.prefab && !user.admin ? 'PreFab' : 'WBS',
          //   PICC: [],
          //   ForceNumber: '',
          //   ShowOnlyMyCrew: 'true',
          //   StartDay: {
          //     ExpectedProductionQuantity: '',
          //     UoM: user.prefab ? 'ea' : '',
          //     PlannedShiftHours: ''
          //   },
          //   EndDay: {
          //     CompletedProductionQuantity: '',
          //     PercentComplete: '',
          //     ObstacleCode: [],
          //     SubObstacleCode: [],
          //     HoursImpacted: '',
          //     PeopleImpacted: '',
          //     ReasonsForImpact: ''
          //   }
          // }
        });
      }
    }
    return () => (mounted = false);
  }, [match.params, CurrentForeman, CurrentDate, CurrentJobNumber]);
  return (
    <>
      <div>
        <Grid
          container
          sx={{ padding: '8px', alignItems: 'center' }}
          direction='row'
          justifyContent='center'
        >
          <Grid
            item
            style={
              checked
                ? { color: 'lightgray', cursor: 'pointer' }
                : { color: 'black' }
            }
          >
            <FontAwesomeIcon style={{ margin: '10px' }} icon={faSunrise} />
          </Grid>
          <Grid
            item
            onClick={() => {
              handleChange(false);
            }}
          >
            {matches ? (
              <h1
                style={
                  checked
                    ? { color: 'lightgray', cursor: 'pointer' }
                    : { color: 'black' }
                }
              >
                Start
              </h1>
            ) : (
              <h1
                style={
                  checked
                    ? { color: 'lightgray', cursor: 'pointer' }
                    : { color: 'black' }
                }
              >
                Start of Day
              </h1>
            )}
          </Grid>
          <Grid item>
            <FormControlLabel
              style={{ margin: '0px' }}
              label=''
              control={
                <Switch
                  checked={checked}
                  onChange={() => {
                    handleChange(!checked);
                  }}
                  name='checkedB'
                  color='primary'
                />
              }
            />
          </Grid>
          <Grid
            item
            onClick={() => {
              handleChange(true);
            }}
          >
            {matches ? (
              <h1
                style={
                  !checked
                    ? { color: 'lightgray', cursor: 'pointer' }
                    : { color: 'black' }
                }
              >
                End
              </h1>
            ) : (
              <h1
                style={
                  !checked
                    ? { color: 'lightgray', cursor: 'pointer' }
                    : { color: 'black' }
                }
              >
                End of Day
              </h1>
            )}
          </Grid>
          <Grid
            item
            style={
              !checked
                ? { color: 'lightgray', cursor: 'pointer' }
                : { color: 'black' }
            }
          >
            <FontAwesomeIcon style={{ margin: '10px' }} icon={faSunset} />
          </Grid>
        </Grid>
        {!checked ? (
          <NewDay
            history={history}
            match={match}
            pod={record}
            setPOD={setRecord}
          />
        ) : (
          <EndDay
            history={history}
            match={match}
            pod={record}
            setPOD={setRecord}
          />
        )}
      </div>
    </>
  );
};

POD.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any)
};
POD.defaultProps = {
  history: {
    push: () => { }
  },
  match: {}
};

export default POD;
