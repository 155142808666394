// Helper to handle ResizeObserver loop limit exceeded error
export const debounceResizeObserver = (entries) => {
  window.requestAnimationFrame(() => {
    entries.forEach(() => {
      // Handle resize logic here if needed
    });
  });
};

// Error handler for ResizeObserver
export const handleResizeObserverError = () => {
  const resizeObserverError = Error(
    'ResizeObserver loop completed with undelivered notifications.'
  );
  const resizeObserverLoop = Error('ResizeObserver loop limit exceeded');

  window.addEventListener('error', (e) => {
    if (e.error === resizeObserverError || e.error === resizeObserverLoop) {
      e.stopImmediatePropagation();
    }
  });
};
