/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
// import { InputNumber } from '@aldridge/aldg-data-components';
import { signInWithGoogle, signInWithMicrosoft } from '../firebase';
import SignInButton from '../_GlobalComponents/SignInButton';

const SignIn = () => {
  const [error] = useState(null);

  // const [phoneAttempt, setPhoneAttempt] = useState('');
  // const [, setVerifyAttempt] = useState('');
  // const signInWithPhoneCallback = (e) => {
  //   setPhoneAttempt(e);
  // };
  // const verifyCodeCallback = (e) => {
  //   setVerifyAttempt(e);
  // };
  return (
    <Grid container justifyContent='center'>
      <h1>Sign In To Use the Application</h1>
      <br />
      <br />
      <br />
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        direction='row'
        style={{ padding: '35px' }}
      >
        {error !== null && <div>{error}</div>}
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          direction='column'
        >
          <Grid item xs={12}>
            <Typography variant="h6">Aldridge Employees Sign In using Google</Typography>
          </Grid>
          <Grid item xs={12}>
            <SignInButton
              onClick={signInWithGoogle}
              mode='dark'
              AuthType='Google'
            />
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: '30px' }} />
          <Grid item xs={12}>
            <SignInButton onClick={signInWithMicrosoft} mode="light" AuthType="Microsoft" />
          </Grid>
          <Grid item style={{ paddingBottom: '30px' }} />
          {/* <Grid item xs={12} xl container spacing={2} justifyContent="center" alignItems="center" direction="column">
            <Grid item xs={12}>
              <InputNumber
                id="userPhoneNumberLogIn"
                name="userPhoneNumberLogIn"
                label="Phone Number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                }}
              />

            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={() => { signInWithPhone(signInWithPhoneCallback); }}>Request SMS Code</Button>
            </Grid>
          </Grid>
          <Grid item xs={4} xl container spacing={2} justifyContent="space-between" alignItems="center" direction="column">
            {
              phoneAttempt
                ? (
                  <>
                    <Grid item xl={6}>
                      <InputNumber
                        id="userVerificationCode"
                        name="userVerificationCode"
                        label="Verification Code"
                      />
                    </Grid>
                    <Grid item xl={6}>
                      <Button variant="contained" color="primary" onClick={() => { verifyCode(verifyCodeCallback); }}>Verify Code</Button>
                    </Grid>
                  </>
                )
                : null
            }
          </Grid>
        */}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default SignIn;
