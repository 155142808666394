/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
// import {
//   Autocomplete,
//   Grid,
//   Title,
//   Container,
//   NumberInput,
//   Button,
//   Text,
//   MultiSelect,
//   CheckIcon,
//   CloseIcon,
// } from "@mantine/core";
import { Grid, TextField, Select, Typography, FormControl, InputLabel, MenuItem, Paper, Button, FormHelperText, Autocomplete } from "@mui/material";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import CrewMemberPopup from "components/pod/CrewMemberPopup";
import { NaNtoNum } from "@aldridge/aldg-helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { firestore } from "../../firebase";

export default function PlannedSection({ form, CurrentJobNumber, user, piccs, CurrentForeman, timeOfDay, Crew, getCrew }) {
  const [open, setOpen] = useState(false);
  // const record = form.getValues();

  const onEmployeeWorkingOnTaskChange = (event, value) => {
    const newEmployees = [];
    const EmployeeRecords = [];
    let newEmployeesDisplay = '';
    if (value.length > 0) {
      value.forEach((employee) => {
        if (typeof employee === 'string') {
          const EmployeeRecord = Crew.find((e) => e.id === employee);
          if (EmployeeRecord) {
            newEmployees.push(EmployeeRecord.id);
            newEmployeesDisplay += `${EmployeeRecord.value}, `;
            EmployeeRecords.push(EmployeeRecord);
          }
        } else {
          newEmployees.push(employee.id);
          newEmployeesDisplay += `${employee.value}, `;
          EmployeeRecords.push(employee);
        }
      });
      form.setFieldValue("data.EmployeesWorkingOnTask", newEmployees);
      form.setFieldValue("data.EmployeesWorkingOnTaskDisplay", newEmployeesDisplay.length > 0 ? newEmployeesDisplay.slice(0, -2) : '');
    } else {
      form.setFieldValue("data.EmployeesWorkingOnTask", []);
      form.setFieldValue("data.EmployeesWorkingOnTaskDisplay", '');
    }
  };

  const suggestedHours = (() => {
    const record = form.getValues();
    const hrs = piccs.filter((picc) => {
      if (
        typeof record.data.Level1 !== 'undefined' &&
        record.data.Level1 !== ''
      ) {
        if (
          typeof record.data.Level2 !== 'undefined' &&
          record.data.Level2 !== ''
        ) {
          if (
            typeof record.data.Level3 !== 'undefined' &&
            record.data.Level3 !== ''
          ) {
            if (
              typeof record.data.Level4 !== 'undefined' &&
              record.data.Level4 !== ''
            ) {
              return (
                record.data.Level1 === picc.data.Level1 &&
                record.data.Level2 === picc.data.Level2 &&
                record.data.Level3 === picc.data.Level3 &&
                record.data.Level4 === picc.data.Level4
              );
            }
            return (
              record.data.Level1 === picc.data.Level1 &&
              record.data.Level2 === picc.data.Level2 &&
              record.data.Level3 === picc.data.Level3 &&
              picc.data.Level4 === ''
            );
          }
          return (
            record.data.Level1 === picc.data.Level1 &&
            record.data.Level2 === picc.data.Level2 &&
            picc.data.Level3 === '' &&
            picc.data.Level4 === ''
          );
        }
        return (
          record.data.Level1 === picc.data.Level1 &&
          picc.data.Level2 === '' &&
          picc.data.Level3 === '' &&
          picc.data.Level4 === ''
        );
      }
      return undefined;
    });
    return hrs;
  })();

  const getHrs = () => {
    const record = form.getValues();
    if (typeof suggestedHours !== 'undefined') {
      const display =
        suggestedHours[0]?.data.EstimatedHours ||
        suggestedHours[0]?.data.TotalHours;
      if (
        (record.data.StartDay.PlannedShiftHours === '' &&
          suggestedHours.length > 0) ||
        parseFloat(display) > parseFloat(record.data.StartDay.PlannedShiftHours)
      )
        return true;
    }
    return false;
  };

  const getEmps = () => {
    const record = form.getValues();
    if (typeof suggestedHours !== 'undefined') {
      if (
        parseFloat(suggestedHours[0]?.data.EstimatedQuantity) >
        parseFloat(record.data.EmployeesWorkingOnTask.length)
      )
        return true;
    }
    return false;
  };
  return (
    <>
      <CrewMemberPopup
        open={open}
        closePopup={() => { setOpen(false); getCrew(); }}
        foreman={form.values.data.Foreman}
        foremanDisplay={form.values.data.ForemanDisplay}
        jobnumber={CurrentJobNumber.value[0]}
      />
      <Paper
        sx={{
          padding: 2,
          '& .MuiGrid-item': {
            padding: 1
          }
        }}
      >
        <Grid container style={{ margin: 0 }}>
          <Grid item xs={12}>
            <h2>
              Planned Hours and Employees
            </h2>
          </Grid>
          <Grid item xs={12}>
            <Paper variant="outlined">
              <Grid container>
                <Grid item xs={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    {getHrs() ? (
                      <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: 'goldenrod', padding: 1, margin: 3 }} />

                    ) : (
                      <FontAwesomeIcon icon={faCheck} style={{ color: "green", padding: 1, margin: 3 }} />
                    )}
                    Suggested Planned Hours Based on WBS Task:    <b>
                      {typeof suggestedHours !== 'undefined'
                        ? suggestedHours[0]?.data.EstimatedHours ||
                        suggestedHours[0]?.data.TotalHours ||
                        'N/A'
                        : 'N/A'}
                    </b></Typography>
                </Grid>
                <Grid item xs={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    {getEmps() ? (
                      <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: 'goldenrod', padding: 1, margin: 3 }} />
                    ) : (
                      <FontAwesomeIcon icon={faCheck} style={{ color: "green", padding: 1, margin: 3 }} />
                    )}
                    Suggested # of People Based on WBS Task:{' '}
                    <b>
                      {typeof suggestedHours !== 'undefined'
                        ? suggestedHours[0]?.data.EstimatedQuantity || 'N/A'
                        : 'N/A'}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <TextField
              type="number"
              fullWidth
              label="Task Hours (Not Total Hours)"
              value={form.values?.data.StartDay.PlannedShiftHours}
              onChange={(event) =>
                form?.setFieldValue("data.StartDay.PlannedShiftHours", event.target.value)
              }
              disabled={timeOfDay !== 'StartDay'}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              label="Employees Working On Task"
              options={Crew}
              renderInput={(params) => <TextField {...params} label="Employees Working On Task" />}
              {...form?.getInputProps("data.EmployeesWorkingOnTask")}
              onChange={onEmployeeWorkingOnTaskChange}
              isOptionEqualToValue={(option, value) => option.id === value}
              disableCloseOnSelect
              multiple
              getOptionLabel={(opt) => {
                if (typeof opt === 'object' && !Array.isArray(opt)) {
                  return `${opt.data.EmployeeDisplay} ${opt.data.UnionDisplay}`;
                }
                const employee = Crew.find((e) => opt === e.id);

                if (employee) {
                  return employee ? `${employee.data.EmployeeDisplay} ${employee.data.UnionDisplay}` : '';
                }
                return '';
              }}
              getOptionKey={(option) => option.id}
            />
          </Grid>
          <Grid
            item xs={2}
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "center",
            }}
          >
            <Button variant="contained" color="primary" onClick={() => setOpen(true)}
              style={{ textSizeAdjust: 'auto' }}>
              MISSING CREW MEMBERS?
            </Button>
          </Grid>
          <Grid item xs={12} display='flex' style={{ textAlign: 'left' }}>
            <FormHelperText>
              Total Man Hours: {NaNtoNum(form.values?.data.StartDay.PlannedShiftHours) * NaNtoNum(form.values?.data.EmployeesWorkingOnTask?.length)}
            </FormHelperText>
          </Grid>
        </Grid>

      </Paper >
    </>
  );
}
