/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import { Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { faSave, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { collection, setDoc, doc, onSnapshot } from 'firebase/firestore';
import { useForm } from '@mantine/form';
import { firestore } from '../../firebase';
import {
  _CurrentDate,
  _CurrentForeman,
  _CurrentJobNumber
} from '../../_Recoil/atoms';
import { UserContext } from '../../providers/UserProvider';
// import DailyReportForm from './DailyReportForm';
import DailyReportForm from './DailyReportForm2';
import { icons } from '../../theme';

const DailyReport = (props) => {
  const { history, match } = props;
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const CurrentDate = useRecoilValue(_CurrentDate);
  const { user } = useContext(UserContext);
  const ForemanSigRef = useRef({});
  const CustomerSigRef = useRef({});

  const form = useForm({
    initialValues: {
      id: '',
      CreatedBy: '',
      CreatedDate: '',
      ModifiedBy: '',
      ModifiedDate: '',
      data: {
        JobNumber: CurrentJobNumber.value[0],
        JobNumberDisplay: CurrentJobNumber.display,
        Foreman: CurrentForeman.value[0],
        ForemanDisplay: CurrentForeman.shortDisplay || '',
        Date: CurrentDate.value,
        DateDisplay: CurrentDate.display,
        Weather: '',
        NumberOfAEPersonnelOnsite: '',
        Subcontractors: [],
        Equipments: [],
        InternalNotes: '',
        ExternalNotes: '',
        ForemanSignature: '',
        ForemanSignaturePrint: '',
        ExtraWorkChangesDetail: '',
        CustomerSignature: '',
        CustomerSignaturePrint: ''
      }
    },
  });

  useEffect(() => {
    let mounted = true;
    if (match.params.id === 'new') {
      return () => null;
    }
    const unsub = onSnapshot(doc(firestore, 'DailyReport', match.params.id), (snap) => {
      if (snap.exists) {
        const d = snap.data();
        if (mounted) {
          form.initialize(d);
          form.setInitialValues(d);
          form.setValues(d);
        }
        if (ForemanSigRef.current && d.data.ForemanSignature !== '')
          ForemanSigRef.current.fromDataURL(d.data.ForemanSignature, {
            ratio: 1
          });
        if (CustomerSigRef.current && d.data.CustomerSignature !== '')
          CustomerSigRef.current.fromDataURL(d.data.CustomerSignature, {
            ratio: 1
          });
      }
    });
    return () => {
      mounted = false;
      unsub();
    };
  }, []);

  const close = () => {
    history.push(`/daily-report`);
  };

  const iconClasses = icons();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmit = async (values) => {
    // eslint-disable-next-line no-unreachable
    try {
      if (match.params.id === 'new') {
        const docRef = doc(collection(firestore, 'DailyReport'));
        const updatedRec = {
          id: docRef.id,
          CreatedBy: user.email,
          CreatedDate: new Date().toJSON(),
          ModifiedBy: user.email,
          ModifiedDate: new Date().toJSON(),
          data: { ...values.data }
        };
        await setDoc(docRef, updatedRec);
        toast.success('Form saved successfully!', { autoClose: 5000 });
        history.push(`/daily-report`);
        return;
      }
      const updatedRec = {
        id: values.id,
        CreatedBy: values.CreatedBy || user.email,
        CreatedDate: values.CreatedDate || new Date().toJSON(),
        ModifiedBy: user.email,
        ModifiedDate: new Date().toJSON(),
        data: { ...values.data }
      };
      await setDoc(doc(firestore, 'DailyReport', values.id), updatedRec, { merge: true });
      history.push(`/daily-report`);
      toast.success('Form saved successfully!', { autoClose: 5000 });
    } catch (err) {
      toast.error('Error occured while saving form.', { autoClose: 5000 });
    }
  };

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)} onReset={form.onReset}>
        <div
          style={{
            position: 'sticky',
            top: '-1px',
            left: 0,
            right: 0,
            width: '100%',
            padding: '8px',
            paddingRight: '0px',
            display: 'flex',
            justifyContent: 'flex-end',
            zIndex: 100
          }}
        >
          <div
            className={`${iconClasses.container} ${iconClasses.greenContainer}`}
            style={{ margin: matches ? '0px -8px' : '0px 12px' }}
            title='Save Record'
          >
            <Button
              type="submit"
              disableRipple
              className={iconClasses.buttonWrapper}
            >
              <FontAwesomeIcon icon={faSave} className={iconClasses.icon} />
            </Button>
          </div>
          <div
            className={`${iconClasses.container} ${iconClasses.redContainer}`}
            style={{ margin: matches ? '0px 0px 0px 12px' : '0px 0px 0px 12px' }}
            title='Close Record'
          >
            <Button
              onClick={close}
              disableRipple
              className={iconClasses.buttonWrapper}
            >
              <FontAwesomeIcon icon={faTimes} className={iconClasses.icon} />
            </Button>
          </div>
        </div>
        <DailyReportForm
          ForemanSigRef={ForemanSigRef}
          CustomerSigRef={CustomerSigRef}
          form={form}
        />
      </form>
    </>
  );
};

DailyReport.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any)
};
DailyReport.defaultProps = {
  match: {
    params: {}
  },
  history: {
    push: () => { }
  }
};
export default DailyReport;
