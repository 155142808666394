/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  InputAutocomplete,
  InputCheckbox,
} from '@aldridge/aldg-data-components';
import {
  Grid,
  FormHelperText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@mui/material';
import Chip from '@mui/material/Chip';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from 'providers/UserProvider';
import { existsWithLength } from '@aldridge/aldg-helpers';
import { firestore, firebaseConfig } from '../../firebase';
import {
  accordionClass,
  accordionSummaryClass,
  accordionDetailsClass
} from '../../theme';

const TimeSummaryFilters = (props) => {
  const {
    allForDiv,
    setAllForDiv,
    AllSubJobTime,
    setAllSubJobTime,
    classes,
    jobsSelected,
    editJobsSelected,
    CurrentDivision,
    CurrentJobNumber
  } = props;

  const ac = accordionClass();
  const acsc = accordionSummaryClass();
  const acdc = accordionDetailsClass();
  const { user } = useContext(UserContext);

  const jobFilterClauses = (user.IsAldridgeEmployee && !existsWithLength(user.JobAssignment)) ? [['JobStatus', '==', 'Open']] : [['JobStatus', '==', 'Open'], ['jctdscid', 'in', user.JobAssignment?.slice(0, 10) || []]];
  const NonAldridgeWithNoJobAssignments = useMemo(() => !user.IsAldridgeEmployee && !existsWithLength(user.JobAssignment), [user.IsAldridgeEmployee, user.JobAssignment]);
  return (
    <>
      <Accordion classes={ac}>
        <AccordionSummary
          classes={{ root: acsc.root, expanded: acsc.expanded }}
          expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
        >
          Additional Time Summary Filters
        </AccordionSummary>
        <AccordionDetails classes={acdc}>
          <Grid container>
            <Grid item xs={12}>
              <FormHelperText style={{ width: '100%', textAlign: 'center' }}>
                The <b>Division</b> and <b>All Sub Job</b> View is driven off of
                your Job Details <b>Job Selection</b>. If you want to view{' '}
                <u>
                  all the Sub Jobs of a different Job or a different Division
                </u>
                , please change your Job Details Information.
              </FormHelperText>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.gridItem}
              style={{ flexBasis: '100%' }}
            >
              {allForDiv ? null : (
                <>
                  {AllSubJobTime ? null : (
                    <>
                      {NonAldridgeWithNoJobAssignments ? (
                        <Typography>
                          You have not been assigned any jobs. Please reach out to the Project Team and the Agile Department to get assigned to a job.
                        </Typography>
                      ) : (
                        <InputAutocomplete
                          firebaseConfig={firebaseConfig}
                          name='MultipleJobs'
                          label='Multiple Job Selection'
                          firestoreOptions={{
                            method: 'get',
                            collection: 'ENT-Jobs',
                            valueKey: 'jctdscid',
                            where: jobFilterClauses,
                            orderBy: 'JobNumber'
                          }}
                          value={
                            jobsSelected.ids.length > 0 ? jobsSelected.ids : []
                          }
                          onChange={editJobsSelected}
                          optionDisplay={(opts) => {
                            if (typeof opts.JobNumber === 'undefined') return '';
                            return `${opts.JobNumber}.${opts.SubJobNumber} - ${opts.JobDescription}`;
                          }}
                          renderTags={(options, getTagProps) =>
                            options.map((opts, index) =>
                              opts.jctdscid === CurrentJobNumber.value[0] ? (
                                <Chip
                                  label={`${opts.JobNumber}.${opts.SubJobNumber} - ${opts.JobDescription}`}
                                  size='small'
                                  {...getTagProps({ index })}
                                  disabled
                                />
                              ) : (
                                <Chip
                                  label={`${opts.JobNumber}.${opts.SubJobNumber} - ${opts.JobDescription}`}
                                  size='small'
                                  {...getTagProps({ index })}
                                />
                              )
                            )
                          }
                          multiple
                        />

                      )}
                    </>
                  )}
                </>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.gridItem}
              style={{ flexBasis: '100%' }}
            >
              {jobsSelected.length > 1 || allForDiv ? null : (
                <InputCheckbox
                  checkboxes={[{ value: 'true', label: `View Time for all Sub Jobs for ${CurrentJobNumber.display}` }]}
                  LabelProps={{
                    label: '',
                  }}
                  name='showAllDivision'
                  onChange={() => {
                    setAllSubJobTime(!AllSubJobTime);
                  }}
                  value={AllSubJobTime ? 'true' : 'false'}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.gridItem}
              style={{ flexBasis: '100%' }}
            >
              <InputCheckbox
                checkboxes={[{ value: 'true', label: `View Time for all of Division ${CurrentDivision}` }]}
                LabelProps={{
                  label: '',
                }}
                name='showAllDivision'
                onChange={() => {
                  setAllForDiv(!allForDiv);
                }}
                value={allForDiv ? 'true' : 'false'}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

TimeSummaryFilters.propTypes = {
  allForDiv: PropTypes.bool.isRequired,
  setAllForDiv: PropTypes.func.isRequired,
  AllSubJobTime: PropTypes.bool.isRequired,
  setAllSubJobTime: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  jobsSelected: PropTypes.objectOf(PropTypes.any).isRequired,
  editJobsSelected: PropTypes.func.isRequired,
  CurrentDivision: PropTypes.string.isRequired,
  CurrentJobNumber: PropTypes.objectOf(PropTypes.any).isRequired
};
TimeSummaryFilters.defaultProps = {};
export default TimeSummaryFilters;
