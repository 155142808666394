import React, { useContext, useState } from 'react';
import { Button, Grid, Switch, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import QuickAdd from '_GlobalComponents/QuickAdd';
import { collection, doc, setDoc } from 'firebase/firestore';
import { UserContext } from 'providers/UserProvider';
import { toast } from 'react-toastify';
import { existsWithLength } from '@aldridge/aldg-helpers';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { faUserGroup } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { firestore } from '../../firebase';
import AdminForm from './ExternalEmployeeEntryForm';

const EmployeeDataDefault = {
  id: '',
  CreatedBy: '',
  CreatedDate: '',
  ModifiedBy: '',
  ModifiedDate: '',
  PreferredFirstName: '',
  LegalLastName: '',
  Suffix: '',
  EmployeeType: 'Field',
  Email: '',
  EmployeeNumber: '',
  Company: '',
  Title: '',
  Status: 'A',
  UniversalID: '',
  prtmstid: ''
};

const ExternalEmployeeEntry = (props) => {
  const { Employees, ExternalUsers, reloadEmployees, reloadUsers } = props;
  const user = useContext(UserContext);
  const gridApiRef = useGridApiRef(null);
  const [record, setRecord] = useState(EmployeeDataDefault);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const isExistingUser = (row) => {
    const userExists = ExternalUsers.some((u) => u.email === row.Email);
    return userExists;
  };

  const columns = [
    {
      field: 'IsUser',
      headerName: 'DJR User?',
      width: 100,
      type: 'boolean',
      valueGetter: (params) => isExistingUser(params.row)
    },
    {
      field: 'Name',
      headerName: 'Name',
      width: 250,
      valueGetter: (params) =>
        `${params?.row.LegalLastName}, ${params?.row.PreferredFirstName} ${params?.row.Suffix}`
    },
    { field: 'EmployeeNumber', headerName: 'Employee #', width: 100 },
    { field: 'EmployeeType', headerName: 'Employee Type', width: 150 },
    { field: 'Company', headerName: 'Company', width: 100 },

    { field: 'Title', headerName: 'Title', width: 200 },
    {
      field: 'Status',
      headerName: 'Status',
      flex: 1,
      type: 'action',
      valueGetter: (params) => params.row.Status,
      renderCell: (params) => (
        <div>
          <Typography variant='caption'>Inactive</Typography>
          <Switch
            checked={params.row.Status === 'A'}
            onChange={(e) => {
              const uRecord = JSON.parse(JSON.stringify(params.row));
              uRecord.Status = e.target.checked ? 'A' : 'I';
              setDoc(doc(firestore, 'ENT-Employees', uRecord.id), uRecord, {
                merge: true
              }).then(() => {
                toast.success(
                  `Employee "${uRecord.LegalLastName}, ${uRecord.PreferredFirstName
                  }" has been successfully ${uRecord.Status === 'A' ? 'activated' : 'inactivated'
                  }.`
                );
              });
            }}
          />
          <Typography variant='caption'>Active</Typography>
        </div>
      )
    }
  ];

  const validateRecord = (rec) => {
    const errorArray = [];

    if (rec.PreferredFirstName === '') {
      errorArray.push({
        valid: false,
        column: 'PreferredFirstName',
        message: 'Preferred First Name is required'
      });
    }
    if (rec.LegalLastName === '') {
      errorArray.push({
        valid: false,
        column: 'LegalLastName',
        message: 'Legal Last Name is required'
      });
    }
    if (rec.Email === '') {
      errorArray.push({
        valid: false,
        column: 'Email',
        message: 'Email is required'
      });
    }
    if (rec.EmployeeNumber === '') {
      errorArray.push({
        valid: false,
        column: 'EmployeeNumber',
        message: 'Employee Number is required'
      });
    }
    if (rec.Company === '') {
      errorArray.push({
        valid: false,
        column: 'Company',
        message: 'Company is required'
      });
    }
    if (rec.Title === '') {
      errorArray.push({
        valid: false,
        column: 'Title',
        message: 'Title is required'
      });
    }

    const isDuplicate = Employees.some(
      (row) =>
        row.Company === rec.Company &&
        row.EmployeeNumber === rec.EmployeeNumber &&
        row.id !== rec.id
    );

    if (isDuplicate) {
      errorArray.push({
        valid: false,
        column: 'EmployeeNumber',
        message: `Employee Number "${rec.EmployeeNumber}" already exists in company "${rec.Company}"`
      });
    }

    return errorArray.length
      ? errorArray
      : [{ valid: true, message: 'Success' }];
  };

  const updateRow = (row) => {
    setRecord(row);
  };

  const addToUsers = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    selectedRows.forEach((row) => {
      const userRecord = {
        email: row.Email,
        group: 'App-DJR-User',
        name: `${row.PreferredFirstName} ${row.LegalLastName}`,
        phone: row.Phone || '',
        IsAldridgeEmployee: false
      };
      const docRef = doc(collection(firestore, 'Users'), userRecord.email);
      setDoc(docRef, userRecord, { merge: true });
    });
    reloadUsers();
    gridApiRef.current.setRowSelectionModel([]);
  };

  const handleSave = (closeDrawer) => {
    const uRecord = JSON.parse(JSON.stringify(record));
    const docRef = !existsWithLength(uRecord.id)
      ? doc(collection(firestore, 'ENT-Employees'))
      : doc(collection(firestore, 'ENT-Employees'), uRecord.id);

    uRecord.prtmstid = `${uRecord?.Company}-${uRecord?.EmployeeNumber}`;
    uRecord.UniversalID = uRecord.prtmstid;
    uRecord.IsAldridgeEmployee = false;
    uRecord.id = docRef.id;
    if (uRecord.Status === '') uRecord.Status = 'A';

    const valid = validateRecord(uRecord);
    const isSuccessful = valid[0].valid === true;

    if (isSuccessful) {
      setDoc(docRef, JSON.parse(JSON.stringify(uRecord)), { merge: true }).then(
        () => {
          reloadEmployees();
          closeDrawer();
          toast.success('External Employee has been saved successfully.', {
            style: { bottom: '25px' }
          });
        }
      );
    } else {
      toast.error(
        <>
          Record is not valid. Please correct the following errors:
          <ul>
            {valid.map((v) => (
              <li key={v.id}>- {v.message}</li>
            ))}
          </ul>
        </>,
        { style: { bottom: '25px', textAlign: 'left' } }
      );
    }
  };

  const handleCancel = () => {
    setRecord(EmployeeDataDefault);
  };

  return (
    <Grid container sx={{ padding: 1 }}>
      <Grid item xs={12}>
        <QuickAdd
          DataGridProps={{
            apiRef: gridApiRef,
            containerHeight: '500px',
            checkboxSelection: true,
            isRowSelectable: (params) => !isExistingUser(params.row),
            onRowSelectionModelChange: (model) => setRowSelectionModel(model)
          }}
          anchor='right'
          clearRecord={() => setRecord(EmployeeDataDefault)}
          columns={columns}
          drawerSize='40%'
          // eslint-disable-next-line react/destructuring-assignment
          form={
            <AdminForm
              currentRecord={record}
              setCurrentRecord={setRecord}
              user={user.user}
            />
          }
          formTitle='Add External Employee'
          handleCancel={handleCancel}
          handleSave={handleSave}
          rows={Employees}
          updateRow={updateRow}
        />
      </Grid>
      <Grid item xs={12} sx={{ py: 1 }} display='flex' alignItems='left'>
        <Button
          onClick={() => addToUsers()}
          color='primary'
          variant='contained'
          disabled={rowSelectionModel.length === 0}
        >
          <FontAwesomeIcon icon={faUserGroup} style={{ marginRight: '8px' }} />{' '}
          Add to User Collection
        </Button>
      </Grid>
    </Grid>
  );
};

ExternalEmployeeEntry.propTypes = {
  Employees: PropTypes.array,
  ExternalUsers: PropTypes.array,
  reloadEmployees: PropTypes.func,
  reloadUsers: PropTypes.func
};

ExternalEmployeeEntry.defaultProps = {
  Employees: [],
  ExternalUsers: [],
  reloadEmployees: () => { },
  reloadUsers: () => { }
};
export default ExternalEmployeeEntry;
