import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { faTrash, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import {
  deleteDoc,
  onSnapshot,
  query,
  where,
  collection,
  doc
} from 'firebase/firestore';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { _CurrentJobNumber } from '../../_Recoil/atoms';
import { firestore } from '../../firebase';

const gridStyles = makeStyles(
  () => ({
    root: {
      '& .MuiDataGrid-footerContainer': {
        justifyContent: 'flex-start',
        marginLeft: '-12px'
      },
      '& .MuiDataGrid-columnsContainer': {
        backgroundColor: '#fff'
      },
      '& .MuiDataGrid-row:nth-child(odd)': {
        backgroundColor: '#efefef'
      },
      '& .MuiDataGrid-row:nth-child(even)': {
        backgroundColor: '#fff'
      }
    }
  }),
  { index: 1 }
);

const EquipmentList = (props) => {
  const { history } = props;
  const [equipment, setEquipment] = useState([]);
  const [rows, setRows] = useState(equipment);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const GridApiRef = useGridApiRef();
  const gridClasses = gridStyles();

  useEffect(() => {
    let mounted = true;
    if (CurrentJobNumber.value.length === 0 && mounted) {
      setEquipment([]);
      return;
    }
    const q = query(
      collection(firestore, 'Equipment'),
      where('data.JobNumber', '==', CurrentJobNumber.value[0])
    );
    const unsub = onSnapshot(q, (res) => {
      const allEquipment = [];
      res.forEach((d) => allEquipment.push(d.data()));
      if (mounted) setEquipment(allEquipment);
    });
    // eslint-disable-next-line consistent-return
    return () => {
      mounted = false;
      unsub();
    };
  }, [CurrentJobNumber]);

  useEffect(() => {
    let mounted = true;
    if (mounted) setRows(equipment);
    return () => (mounted = false);
  }, [equipment]);

  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const handleSelect = (idx) => {
    history.push(`/Equipment/${idx}`);
  };
  const addNew = () => {
    if (CurrentJobNumber.value.length > 0) history.push(`/Equipment/new`);
    else toast.error('You must select a Job before adding Equipment.');
  };
  const deleteRecords = () => {
    const selectedRows = Array.from(GridApiRef.current.getSelectedRows()?.entries());
    selectedRows.forEach((s) => {
      const [id] = s;
      deleteDoc(doc(firestore, 'Equipment', id));
    });
  };

  const columns = [
    {
      field: 'EquipmentNumber',
      headerName: 'Unit Number',
      valueGetter: (params) =>
        params.row.data.AEEquipment === 'true' ||
          typeof params.row.data.AEEquipment === 'undefined'
          ? params.row.data.Equipment[0]
          : params.row.data.NonAEEquipment,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'Make',
      headerName: 'Make',
      valueGetter: (params) => params.row.data.Make,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'Model',
      headerName: 'Model',
      valueGetter: (params) => params.row.data.Model,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'Description',
      headerName: 'Description',
      valueGetter: (params) => params.row.data.Description,
      flex: getFlex(15),
      width: getWidth(300)
    }
  ];
  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100%',
          padding: matches ? '20px 0' : '10px 0',
          justifyContent: 'flex-end'
        }}
      >
        <ToolTipIconButton icon={faTrash} color="error" tooltipText='Delete Selected Records' onClick={deleteRecords} />
        <ToolTipIconButton icon={faPlus} tooltipText='Add New Record' onClick={addNew} />
      </div>
      <div style={{ height: '100%', width: '100%' }}>
        <AldgDataGrid
          autoHeight
          checkboxSelection
          onRowClick={(row) => handleSelect(row.id)}
          className={gridClasses.root}
          rows={rows}
          columns={columns}
          apiRef={GridApiRef}
        />
      </div>
    </>
  );
};

EquipmentList.propTypes = {
  history: PropTypes.objectOf(PropTypes.any)
};

EquipmentList.defaultProps = {
  history: {
    push: () => { }
  }
};

export default EquipmentList;
