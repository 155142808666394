/* eslint-disable no-unused-vars */
import React, { useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Modal,
  useMediaQuery,
  useTheme,
  Backdrop,
  Button,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useRecoilState } from 'recoil';
import { InputAutocomplete, InputDate } from '@aldridge/aldg-data-components';
import dayjs from 'dayjs';
import { UserContext } from 'providers/UserProvider';
import { existsWithLength } from '@aldridge/aldg-helpers';
import {
  accordionClass,
  accordionSummaryClass,
  accordionDetailsClass,
  basicStyles
} from '../../theme';
import {
  _CurrentJobNumber,
  _CurrentForeman,
  _CurrentDate
} from '../../_Recoil/atoms';
import { firebaseConfig } from '../../firebase';

// 59bb5309f0eb15d7a7d12015e835690dTz01MDIxMixFPTE2OTM3NjQ2NTY3NzYsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=
const ChangeForm = (props) => {
  const { closePopup } = props;
  const [CurrentJobNumber, setCurrentJobNumber] =
    useRecoilState(_CurrentJobNumber);
  const [CurrentForeman, setCurrentForeman] = useRecoilState(_CurrentForeman);
  const [CurrentDate, setCurrentDate] = useRecoilState(_CurrentDate);
  const [popupTransaction, setPopupTransaction] = useState({
    JobNumber: CurrentJobNumber.value,
    JobNumberDisplay: CurrentJobNumber.display,
    Foreman: CurrentForeman.value,
    ForemanDisplay: CurrentForeman.shortDisplay,
    Date: CurrentDate.value
  });
  const handleChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedTransaction = { ...popupTransaction };
      changedTransaction[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changedTransaction[`${id}Display`] = displayFromTypeahead;
      }
      setPopupTransaction(changedTransaction);
    } catch (err) {
      toast.error(err.message);
    }
  };
  const updateRecords = () => {
    if (popupTransaction.JobNumberDisplay !== CurrentJobNumber.display)
      setCurrentJobNumber({
        value: popupTransaction.JobNumber,
        display: popupTransaction.JobNumberDisplay
      });
    if (popupTransaction.ForemanDisplay !== CurrentForeman.display)
      setCurrentForeman({
        value: popupTransaction.Foreman,
        display: popupTransaction.ForemanDisplay,
        shortDisplay: popupTransaction.ForemanDisplay?.split(' - [')[0]
      });
    if (popupTransaction.Date !== CurrentDate.value)
      setCurrentDate({
        value:
          typeof popupTransaction.Date !== 'undefined' &&
            popupTransaction.Date !== ''
            ? dayjs(popupTransaction.Date).format('YYYY-MM-DD')
            : '',
        display:
          typeof popupTransaction.Date !== 'undefined' &&
            popupTransaction.Date !== ''
            ? dayjs(popupTransaction.Date).format('ddd MMMM D, YYYY')
            : ''
      });
    closePopup();
  };

  const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const useStyles = makeStyles(
    {
      line: {
        padding: '16px'
      },
      foremanLine: {
        padding: '0px 16px'
      },
      button: {
        [theme.breakpoints.down('sm')]: {
          padding: '6px 8px'
        }
      }
    },
    { index: 1 }
  );

  const customClasses = useStyles();
  const { user } = useContext(UserContext);

  const jobFilterClauses = (user.IsAldridgeEmployee && !existsWithLength(user.JobAssignment)) ? [['JobStatus', '==', 'Open']] : [['JobStatus', '==', 'Open'], ['jctdscid', 'in', user.JobAssignment?.slice(0, 10) || []]];

  const NonAldridgeWithNoJobAssignments = useMemo(() => !user.IsAldridgeEmployee && !existsWithLength(user.JobAssignment), [user.IsAldridgeEmployee, user.JobAssignment]);

  return (
    <Grid container>
      <Grid item xs={12} className={customClasses.line}>
        {NonAldridgeWithNoJobAssignments ? (
          <Typography>
            You have not been assigned any jobs. Please reach out to the Project Team and the Agile Department to get assigned to a job.
          </Typography>
        ) : (<InputAutocomplete
          firebaseConfig={firebaseConfig}
          name='JobNumber'
          label='Job Number'
          firestoreOptions={{
            method: 'get',
            valueKey: 'jctdscid',
            collection: 'ENT-Jobs',
            where: jobFilterClauses,
            orderBy: 'JobNumber'
          }}
          value={popupTransaction.JobNumber}
          optionDisplay={(opts) => {
            if (typeof opts.JobNumber === 'undefined') return '';
            return `${opts.JobNumber}.${opts.SubJobNumber} - ${opts.JobDescription}`;
          }}
          disablePortal
          onChange={handleChange}
        />
        )}
      </Grid>
      <Grid item xs={12} className={customClasses.foremanLine}>
        <InputAutocomplete
          firebaseConfig={firebaseConfig}
          name='Foreman'
          label='Foreman'
          value={popupTransaction.Foreman}
          firestoreOptions={{
            method: 'get',
            valueKey: 'prtmstid',
            collection: 'ENT-Employees',
            where: [['Status', '==', 'A']],
            orderBy: ['LegalLastName', 'PreferredFirstName']
          }}
          optionDisplay={(opts) => {
            let display = `${opts.LegalLastName} ${opts.Suffix || ''}, ${opts.PreferredFirstName
              } ${opts.MiddleName || ''} - [${opts.Title} - ${opts.UniversalID
              }]`.replace(/ {2}/g, ' ');
            if (Object.keys(opts).length === 0) return '';
            if (opts.IsAldridgeEmployee === false) {
              display += ` - [${opts.CompanyDisplay}]`;
            }
            return display;
          }}
          disablePortal
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} className={customClasses.line}>
        <InputDate
          name='Date'
          type='date'
          label='Date'
          value={popupTransaction.Date}
          onChange={handleChange}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          textAlign: 'right',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginRight: '14px'
        }}
      >
        <Button
          color='primary'
          variant='contained'
          onClick={updateRecords}
          className={customClasses.button}
        >
          Update
        </Button>
        &nbsp;
        <Button
          color='error'
          variant='contained'
          onClick={closePopup}
          className={customClasses.button}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

const DailyToolbar = () => {
  const [CurrentJobNumber] = useRecoilState(_CurrentJobNumber);
  const [CurrentForeman] = useRecoilState(_CurrentForeman);
  const [CurrentDate] = useRecoilState(_CurrentDate);
  const [displayBoxOpen, setDisplayBoxOpen] = useState(false);

  const displayChangeBox = (bool) => {
    setDisplayBoxOpen(bool);
  };
  const theme = useTheme();
  const customClasses = makeStyles(
    () => ({
      container: {
        display: 'grid',
        gridGap: theme.spacing(3)
      },
      label: { padding: '0px 4px' },
      editText: {
        textTransform: 'none',
        marginLeft: '5px'
      }
    }),
    { index: 1 }
  )();
  const ac = accordionClass();
  const acsc = accordionSummaryClass();
  const acdc = accordionDetailsClass();
  const classes = basicStyles();

  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container>
      <Modal
        open={displayBoxOpen}
        onClose={() => displayChangeBox(false)}
        BackdropComponent={Backdrop}
        className={classes.modal}
      >
        <div className={classes.modalPaper}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: matches ? '6px' : '12px'
            }}
          >
            <h5 style={{ textDecoration: 'underline' }}>
              Update Job / Foreman / Date
            </h5>
          </div>

          <div
            style={{
              position: 'relative',
              flex: '1 1 auto',
              padding: matches ? '6px' : '12px'
            }}
          >
            <ChangeForm closePopup={() => displayChangeBox(false)} />
          </div>
        </div>
      </Modal>
      <Grid className={customClasses.container} item xs={12}>
        <Accordion classes={ac} defaultExpanded>
          <AccordionSummary
            classes={{ root: acsc.root, expanded: acsc.expanded }}
            expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
          >
            <Button
              classes={{
                root: acsc.outLinedButton,
                outlined: acsc.outlined,
                label: customClasses.label
              }}
              variant='outlined'
              onClick={(event) => {
                event.stopPropagation();
                displayChangeBox(true);
              }}
            >
              <FontAwesomeIcon
                className={customClasses.editIcon}
                icon={faPen}
              />
              <div className={customClasses.editText}> Update Job Details </div>
            </Button>
          </AccordionSummary>
          <AccordionDetails classes={acdc}>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <Grid container>
                  <b>Job: </b>
                  <p className={customClasses.editText}>
                    {CurrentJobNumber.display}
                  </p>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container>
                  <b>Date: </b>
                  <p className={customClasses.editText}>
                    {CurrentDate.display}
                  </p>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container>
                  <b>Foreman: </b>
                  <p className={customClasses.editText}>
                    {CurrentForeman.shortDisplay}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

ChangeForm.propTypes = {
  closePopup: PropTypes.func
};
ChangeForm.defaultProps = {
  closePopup: () => { }
};

export default DailyToolbar;
