/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilValue } from 'recoil';
import {
  Button,
  useMediaQuery,
  useTheme,
  Grid,
  Paper,
  Autocomplete,
  TextField
} from '@mui/material';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Files } from '@aldridge/aldg-data-components';
import SignatureCanvas from 'react-signature-canvas';
import { onSnapshot, collection, query, where } from 'firebase/firestore';
// import { Autocomplete, TextInput, Textarea } from '@mantine/core';
// import "@mantine/core/styles.css";
// import "@mantine/dates/styles.css";
import { existsWithLength } from '@aldridge/aldg-helpers';
import { toast } from 'react-toastify';
import AddEquipTable from './AddEquipTable';
import AddSubsTable from './AddSubsTable';
import { UserContext } from '../../providers/UserProvider';
import { config, firebaseConfig, firestore } from '../../firebase';
import { _CurrentDate, _CurrentJobNumber } from '../../_Recoil/atoms';
import { basicStyles } from '../../theme';
import { checkPOD } from '../../utils/checkLockout';

export default function DailyReportForm({
  ForemanSigRef,
  CustomerSigRef,
  form
}) {
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const CurrentDate = useRecoilValue(_CurrentDate);
  // const record = form.getValues();
  const { user } = useContext(UserContext);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [EquipmentOpts, setEquipmentOpts] = useState([]);
  const [EquipmentLoading, setEquipmentLoading] = useState(false);
  const [SubcontractorsOpts, setSubcontractorOpts] = useState([]);
  const [JobNumbers, setJobNumbers] = useState([]);

  useEffect(() => {
    let mounted = true;
    const whereClauses = (user.IsAldridgeEmployee && !existsWithLength(user.JobAssignment)) ? [['JobStatus', '==', 'Open']] : [['JobStatus', '==', 'Open'], ['jctdscid', 'in', user.JobAssignment?.slice(0, 10) || []]];
    const NonAldridgeWithNoJobAssignments = !user.IsAldridgeEmployee && !existsWithLength(user.JobAssignment);
    if (NonAldridgeWithNoJobAssignments) {
      toast.error('You have not been assigned any jobs. Please reach out to the Project Team and the Agile Department to get assigned to a job.');
      return () => {
        mounted = false;
      };
    }

    let q = query(collection(firestore, 'ENT-Jobs'));
    if (existsWithLength(whereClauses)) {
      whereClauses.forEach((clause) => {
        q = query(q, where(clause[0], clause[1], clause[2]));
      });
    }
    const unsub = onSnapshot(q, (res) => {
      const allJobNumbers = [];
      res.forEach((d) => allJobNumbers.push({ ...d.data(), value: `${d.data().JobNumber}.${d.data().SubJobNumber} - ${d.data().JobDescription}` }));
      if (mounted) setJobNumbers(allJobNumbers);
    });

    return () => {
      mounted = false;
      unsub();
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if (CurrentJobNumber.value.length > 0) {
      setEquipmentLoading(true);
      const q = query(
        collection(firestore, 'Equipment'),
        where('data.JobNumber', '==', CurrentJobNumber.value[0])
      );
      const unsub = onSnapshot(q, (res) => {
        const allEquipment = [];
        res.forEach((d) =>
          allEquipment.push({
            ...d.data(),
            id: d.data().id,
            value: d.data().data.EquipmentDisplay
              ? `${d.data().data.EquipmentDisplay}`
              : `${d.data().data.Description} `
          })
        );
        if (mounted) {
          setEquipmentOpts([...new Set([...allEquipment])]);
          setEquipmentLoading(false);
        }
      });

      return () => {
        mounted = false;
        unsub();
      };
    }
    return () => {
      mounted = false;
    };
  }, [CurrentJobNumber.value]);

  useEffect(() => {
    let mounted = true;
    if (CurrentJobNumber.value.length > 0) {
      const q = query(
        collection(firestore, 'Subcontractor'),
        where('data.JobNumber', '==', CurrentJobNumber.value[0])
      );
      const unsub = onSnapshot(q, (res) => {
        const allSubcontractors = [];
        res.forEach((d) => allSubcontractors.push({ ...d.data(), id: d.data().id, value: d.data().data?.SubcontractorDisplay ? `${d.data().data?.SubcontractorDisplay} (${d.data().id.slice(0, 4)})` : `NA (${d.data().id})` || `null (${new Date().getTime().toString()})` }));
        if (mounted) {
          setSubcontractorOpts(allSubcontractors);
        }
      });

      return () => {
        mounted = false;
        unsub();
      };
    }
    return () => {
      mounted = false;
    };
  }, [CurrentJobNumber.value]);

  const checkLockout = useMemo(() => {
    const record = form.getValues();
    return checkPOD(user, record);
  }, []);

  // Prevent form submission on Enter key
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' && e.target.tagName?.toUpperCase() !== 'TEXTAREA') {
        e.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);
  useEffect(() => {
    let mounted = true;
    if (CurrentJobNumber.value.length > 0 && mounted) {
      const record = form.getValues();
      const rec = record.data;
      rec.JobNumber = CurrentJobNumber.value[0];
      rec.JobNumberDisplay = CurrentJobNumber.display;
      form.setFieldValue('data', rec);
    }
    return () => {
      mounted = false;
    };
  }, [CurrentJobNumber]);

  const classes = basicStyles();
  const updateSignature = (name, clear) => {
    const dataUrl =
      name === 'Foreman'
        ? ForemanSigRef.current.toDataURL()
        : CustomerSigRef.current.toDataURL();
    const record = form.getValues();
    const rec = record.data;
    rec[`${name}Signature`] = clear ? '' : dataUrl;
    form.setFieldValue(`data`, rec);
  };
  const clearSignature = (name) => {
    if (name === 'Foreman') {
      ForemanSigRef.current.clear();
    } else {
      CustomerSigRef.current.clear();
    }
    updateSignature(name, true);
  };

  const onJobNumberChange = (event, name) => {
    const record = form.getValues();
    const rec = record.data;
    const getJob =
      event?.length !== 0
        ? JobNumbers.filter(
          (job) =>
            job.JobNumber === event.split('.')[0] &&
            job.SubJobNumber === event.split('.')[1].slice(0, 3)
        )[0]
        : '';
    rec[name] = event;
    rec.JobNumber = getJob?.jctdscid || '';
    form.setFieldValue(`data`, rec);
  };
  return (
    <Paper className={classes.simplePrimaryPaper} variant='outlined'>
      <Grid container spacing={1} sx={{ padding: '4px', '& .MuiFormLabel-root': { fontWeight: 'bold' } }}>
        <Grid item xs={12}>
          <h2>{`Daily Job Report For ${CurrentDate.display}`}</h2>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Autocomplete
            renderInput={(params) => (
              <TextField {...params} label='Job Number' />
            )}
            options={JobNumbers}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                const job = JobNumbers.find((j) => j.jctdscid === option);
                if (job) {
                  return job.value;
                }
              }
              if (option.value) {
                return option.value;
              }
              return '';
            }}
            disabled={CurrentJobNumber.value.length > 0 || checkLockout}
            onChange={(event, value) => onJobNumberChange(value)}
            isOptionEqualToValue={(option, value) => option.jctdscid === value}
            value={form.values.data.JobNumber || null}
          />
        </Grid>
        <Grid item sm={4} xs={12} justifyItems='left'>
          <TextField
            fullWidth
            type='number'
            label='# of AE Personnel on Jobsite'
            disabled={checkLockout}
            value={form.values.data.NumberOfAEPersonnelOnsite || ''}
            onChange={(e) => {
              const record = form.getValues();
              const rec = record.data;
              rec.NumberOfAEPersonnelOnsite = e.target.value;
              form.setFieldValue('data', rec);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label='Weather'
            value={form.values.data.Weather || ''}
            onChange={(e) => {
              const record = form.getValues();
              const rec = record.data;
              rec.Weather = e.target.value;
              form.setFieldValue('data', rec);
            }}
            disabled={checkLockout}
          />
        </Grid>
        {typeof form.values.data.JobNumber !== 'undefined' &&
          form.values.data.JobNumber.length > 0 ? (
          <Grid item xs={12}>
            <AddEquipTable
              form={form}
              Equipment={EquipmentOpts}
              checkLockout={checkLockout}
              EquipmentLoading={EquipmentLoading}
            />
          </Grid>
        ) : null}
        {typeof form.values.data.JobNumber !== 'undefined' &&
          form.values.data.JobNumber.length > 0 ? (
          <Grid item xs={12}><AddSubsTable form={form} Subcontractors={SubcontractorsOpts} checkLockout={checkLockout} /></Grid>
        ) : null}
        {!(
          typeof form.values.data.JobNumber !== 'undefined' &&
          form.values.data.JobNumber.length > 0
        ) ? (
          <h4 style={{ padding: '27px 0px 0px 4px' }}>
            Input a Job Number to see Equipment, Subcontractors, and Files.
          </h4>
        ) : null}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label='Daily Notes - Internal'
            disabled={checkLockout}
            multiline
            minRows={3}
            value={form.values.data.InternalNotes || ''}
            onChange={(e) => {
              const record = form.getValues();
              const rec = record.data;
              rec.InternalNotes = e.target.value;
              form.setFieldValue('data', rec);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label='Daily Notes for GC - External'
            disabled={checkLockout}
            multiline
            minRows={3}
            value={form.values.data.ExternalNotes || ''}
            onChange={(e) => {
              const record = form.getValues();
              const rec = record.data;
              rec.ExternalNotes = e.target.value;
              form.setFieldValue('data', rec);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label='Extra Work / Changes Detail'
            disabled={checkLockout}
            multiline
            minRows={3}
            value={form.values.data.ExtraWorkChangesDetail || ''}
            onChange={(e) => {
              const record = form.getValues();
              const rec = record.data;
              rec.ExtraWorkChangesDetail = e.target.value;
              form.setFieldValue('data', rec);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <SignatureCanvas
                ref={(ref) => (ForemanSigRef.current = ref)}
                canvasProps={{
                  width: 300,
                  height: 150,
                  style: { border: '1px solid black' }
                }}
                onEnd={() => updateSignature('Foreman')}
              />
              <Button
                variant='contained'
                color='error'
                className={classes.button}
                startIcon={<FontAwesomeIcon icon={faTimes} />}
                onClick={() => clearSignature('Foreman')}
                style={{ margin: matches ? '5px' : '10px' }}
              >
                Clear Signature
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label='Foreman - Signature (Print)'
                value={form.values.data.ForemanSignaturePrint || ''}
                onChange={(e) => {
                  const record = form.getValues();
                  const rec = record.data;
                  rec.ForemanSignaturePrint = e.target.value;
                  form.setFieldValue('data', rec);
                }}
                disabled={checkLockout}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <SignatureCanvas
                ref={CustomerSigRef}
                canvasProps={{
                  width: 300,
                  height: 150,
                  style: { border: '1px solid black' }
                }}
                onEnd={() => updateSignature('Customer')}
              />
              <Button
                variant='contained'
                color='error'
                className={classes.button}
                startIcon={<FontAwesomeIcon icon={faTimes} />}
                onClick={() => clearSignature('Customer')}
                style={{ margin: matches ? '5px' : '10px' }}
              >
                Clear Signature
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label='Customer - Signature (Print)'
                value={form.values.data.CustomerSignaturePrint || ''}
                onChange={(e) => {
                  const record = form.getValues();
                  const rec = record.data;
                  rec.CustomerSignaturePrint = e.target.value;
                  form.setFieldValue('data', rec);
                }}
                disabled={checkLockout}
              />
            </Grid>
          </Grid>
        </Grid>
        {typeof form.values.data.JobNumberDisplay !== 'undefined' &&
          form.values.data.JobNumberDisplay !== '' ? (
          <Grid item xs={12}>
            <Files
              label='Files'
              name='Files'
              folder={`${form.values.data.JobNumber || CurrentJobNumber}/${CurrentDate.value
                }/${form.values.data.Foreman}`}
              bucket={config.storageBucket}
              firebaseConfig={firebaseConfig}
              getFilesFunction='getFiles'
              multiple
            />
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
}
