/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
// import {
//   Autocomplete,
//   Grid,
//   Text,
//   Textarea,
// } from "@mantine/core";
import { Grid, Autocomplete, Textarea, TextField } from '@mui/material';
import {
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where
} from 'firebase/firestore';
import { existsWithLength } from '@aldridge/aldg-helpers';
import { firestore } from '../../../firebase';

export default function WBSTaskSection({ form, PICCs }) {

  const [JobNumber, setJobNumber] = useState(form.values?.data?.JobNumber);
  form.watch('data.JobNumber', ({ value }) => {
    if (existsWithLength(value)) {
      setJobNumber(value);
    }
  });
  const [AllWBS, setAllWBS] = React.useState([]);

  const distinctList = (obj, name) => {
    const record = form.getValues();
    let options = [];
    const Level1 = Array.isArray(record.data.Level1)
      ? record.data.Level1[0]
      : record.data.Level1;
    const Level2 = Array.isArray(record.data.Level2)
      ? record.data.Level2[0]
      : record.data.Level2;
    const Level3 = Array.isArray(record.data.Level3)
      ? record.data.Level3[0]
      : record.data.Level3;
    const Level4 = Array.isArray(record.data.Level4)
      ? record.data.Level4[0]
      : record.data.Level4;
    switch (name) {
      case 'Level1':
        options = [
          ...new Set(
            obj.filter((o) => o.data.Level1 !== '').map((o) => o.data[name])
          )
        ];
        break;
      case 'Level2':
        options = [
          ...new Set(
            obj
              .filter((o) => o.data.Level1 === Level1 && o.data.Level2 !== '')
              .map((o) => o.data[name])
          )
        ];
        break;
      case 'Level3':
        options = [
          ...new Set(
            obj
              .filter(
                (o) =>
                  o.data.Level1 === Level1 &&
                  o.data.Level2 === Level2 &&
                  o.data.Level3 !== ''
              )
              .map((o) => o.data[name])
          )
        ];
        break;
      case 'Level4':
        options = [
          ...new Set(
            obj
              .filter(
                (o) =>
                  o.data.Level1 === Level1 &&
                  o.data.Level2 === Level2 &&
                  o.data.Level3 === Level3 &&
                  o.data.Level4 !== ''
              )
              .map((o) => o.data[name])
          )
        ];
        break;
      default:
        options = [];
    }
    return options.map((o) => ({ value: o, label: o }));
  };



  const WBSFormat = (wbs) => {
    const WBS = AllWBS.find((w) => w.id === wbs);
    if (WBS) {
      return `${WBS?.data?.Level1} / ${WBS?.data?.Level2} / ${WBS?.data?.Level3} / ${WBS?.data?.Level4}  ${typeof WBS?.data?.TotalHours !== 'undefined'
        ? `- Hours: ${WBS?.data?.TotalHours}`
        : ''
        } [Task Complete: ${WBS?.data?.Complete}]`.replace(/\/ {2}/g, '');
    }
    if (typeof wbs === 'object') {
      return `${wbs?.data?.Level1} / ${wbs?.data?.Level2} / ${wbs?.data?.Level3} / ${wbs?.data?.Level4}  ${typeof wbs?.data?.TotalHours !== 'undefined'
        ? `- Hours: ${wbs?.data?.TotalHours}`
        : ''
        } [Task Complete: ${wbs?.data?.Complete}]`.replace(/\/ {2}/g, '');
    }
    return wbs;
  };
  return (
    <Grid item xs={12}>
      <Grid container>
        {distinctList(PICCs, 'Level1').length === 0 ? (
          <Grid item xs={12}>
            <h3>
              {' '}
              {typeof JobNumber === 'undefined' ||
                JobNumber?.length === 0
                ? 'Please select a Job Number to see WBS tasks.'
                : 'There are no WBS tasks set up. Please set up your WBS tasks in the WBS Tab.'}
            </h3>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Autocomplete
                renderInput={(params) => (
                  <TextField {...params} label='All WBS' />
                )}
                options={PICCs}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    const allPicc = PICCs.find((p) => p.id === option);
                    return WBSFormat(allPicc);
                  }
                  return WBSFormat(option);
                }}
                placeholder='Select a WBS...'
                {...form?.getInputProps('data.AllWBS')}
                value={
                  (Array.isArray(form?.values?.data?.AllWBS)
                    ? form?.values?.data?.AllWBS[0]
                    : form?.values?.data?.AllWBS) || null
                }
                isOptionEqualToValue={(option, value) => option.id === value}
                onChange={(event, value) => {
                  if (value) {
                    form.setFieldValue('data.AllWBS', value.id);
                    form.setFieldValue('data.AllWBSDisplay', WBSFormat(value));
                    form.setFieldValue('data.Level1', value.data.Level1);
                    form.setFieldValue('data.Level2', value.data.Level2);
                    form.setFieldValue('data.Level3', value.data.Level3);
                    form.setFieldValue('data.Level4', value.data.Level4);
                  } else {
                    form.setFieldValue('data.AllWBS', '');
                    form.setFieldValue('data.AllWBSDisplay', '');
                    form.setFieldValue('data.Level1', '');
                    form.setFieldValue('data.Level2', '');
                    form.setFieldValue('data.Level3', '');
                    form.setFieldValue('data.Level4', '');
                  }
                }}
                getOptionKey={(option) => option.id}
              />
            </Grid>
            {distinctList(PICCs, 'Level1').length > 0 && (
              <Grid item xs={6}>
                <Autocomplete
                  renderInput={(params) => (
                    <TextField {...params} label='Level 1' />
                  )}
                  options={distinctList(PICCs, 'Level1')}
                  isOptionEqualToValue={(option, value) => option.value === value}
                  {...form?.getInputProps('data.Level1')}
                  value={
                    (Array.isArray(form.values?.data?.Level1)
                      ? form.values?.data?.Level1[0]
                      : form.values?.data?.Level1) || null
                  }
                  onChange={(event, value) => {
                    if (value) {
                      form.setFieldValue('data.Level1', value.value);
                      form.setFieldValue('data.Level1Display', value.value);
                    } else {
                      form.setFieldValue('data.Level1', '');
                      form.setFieldValue('data.Level1Display', '');
                    }
                  }}

                />
              </Grid>
            )}
            {distinctList(PICCs, 'Level2').length > 0 && (
              <Grid item xs={6}>
                <Autocomplete
                  renderInput={(params) => (
                    <TextField {...params} label='Level 2' />
                  )}
                  options={distinctList(PICCs, 'Level2')}
                  isOptionEqualToValue={(option, value) => option.value === value}
                  {...form?.getInputProps('data.Level2')}
                  value={
                    (Array.isArray(form.values?.data?.Level2)
                      ? form.values?.data?.Level2[0]
                      : form.values?.data?.Level2) || null
                  }
                  onChange={(event, value) => {
                    if (value) {
                      form.setFieldValue('data.Level2', value.value);
                      form.setFieldValue('data.Level2Display', value.value);
                    } else {
                      form.setFieldValue('data.Level2', '');
                      form.setFieldValue('data.Level2Display', '');
                    }
                  }}
                />
              </Grid>
            )}
            {distinctList(PICCs, 'Level3').length > 0 && (
              <Grid item xs={6}>
                <Autocomplete
                  renderInput={(params) => (
                    <TextField {...params} label='Level 3' />
                  )}
                  options={distinctList(PICCs, 'Level3')}
                  isOptionEqualToValue={(option, value) => option.value === value}
                  {...form?.getInputProps('data.Level3')}
                  value={
                    (Array.isArray(form.values?.data?.Level3)
                      ? form.values?.data?.Level3[0]
                      : form.values?.data?.Level3) || null
                  }
                  onChange={(event, value) => {
                    if (value) {
                      form.setFieldValue('data.Level3', value.value);
                      form.setFieldValue('data.Level3Display', value.value);
                    } else {
                      form.setFieldValue('data.Level3', '');
                      form.setFieldValue('data.Level3Display', '');
                    }
                  }}
                />
              </Grid>
            )}
            {distinctList(PICCs, 'Level4').length > 0 && (
              <Grid item xs={6}>
                <Autocomplete
                  renderInput={(params) => (
                    <TextField {...params} label='Level 4' />
                  )}
                  options={distinctList(PICCs, 'Level4')}
                  isOptionEqualToValue={(option, value) => option.value === value}
                  {...form?.getInputProps('data.Level4')}
                  value={
                    (Array.isArray(form.values?.data?.Level4)
                      ? form.values?.data?.Level4[0]
                      : form.values?.data?.Level4) || null
                  }
                  onChange={(event, value) => {
                    if (value) {
                      form.setFieldValue('data.Level4', value.value);
                      form.setFieldValue('data.Level4Display', value.value);
                    } else {
                      form.setFieldValue('data.Level4', '');
                      form.setFieldValue('data.Level4Display', '');
                    }
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                multiline
                rows={3}
                label='Task Details'
                fullWidth
                {...form?.getInputProps('data.Task')}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}
