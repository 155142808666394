/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
// import {
//   Autocomplete,
//   Grid,
//   Title,
//   Container,
//   NumberInput,
//   Select,
//   TextInput,
// } from "@mantine/core";
import { Grid, TextField, Select, FormControl, InputLabel, MenuItem, Paper } from "@mui/material";

export default function DailyProductionSection({ form, checkLockout, timeOfDay }) {
  return (
    <Paper sx={{
      padding: 2,
      '& .MuiGrid-item': {
        padding: 1
      }
    }}>
      <Grid container style={{ margin: 0 }}>
        <Grid item xs={12}>
          <h2>Daily Production</h2>
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            fullWidth
            label="Expected Production Quantity"
            value={form.values.data.StartDay.ExpectedProductionQuantity || ''}
            onChange={(event) => {
              form?.setFieldValue("data.StartDay.ExpectedProductionQuantity", event.target.value);
            }}
            disabled={checkLockout || timeOfDay !== 'StartDay'}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Expected Quantity UOM</InputLabel>
            <Select
              value={form.values.data.StartDay.UoM || ''}
              onChange={(event) => form.setFieldValue("data.StartDay.UoM", event.target.value)}
              disabled={checkLockout || timeOfDay !== 'StartDay'}
            >
              <MenuItem value="Feet">Feet</MenuItem>
              <MenuItem value="Yards">Yards</MenuItem>
              <MenuItem value="Cubic Yards">Cubic Yards</MenuItem>
              <MenuItem value="Pounds">Pounds</MenuItem>
              <MenuItem value="Each">Each</MenuItem>
              <MenuItem value="Square Feet">Square Feet</MenuItem>
              <MenuItem value="Tons">Tons</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            fullWidth
            label="Completed Production Quantity"
            value={form.values.data.EndDay.CompletedProductionQuantity || ''}
            onChange={(event) => {
              form?.setFieldValue("data.EndDay.CompletedProductionQuantity", event.target.value);
            }}
            onBlur={(event) => {
              if (form.values.data.EndDay.PercentComplete === '') {
                const expected = parseFloat(
                  form.values.data.StartDay.ExpectedProductionQuantity
                );
                const completed = parseFloat(
                  event.target.value
                );
                if (Number.isNaN(expected) || Number.isNaN(completed)) {
                  // form?.setFieldValue("data.EndDay.PercentComplete", '0%');
                } else {
                  const percentNumber = (completed / expected) * 100;
                  const percent = `${(percentNumber > 100 ? 100 : percentNumber).toFixed(2)}%`;
                  form?.setFieldValue("data.EndDay.PercentComplete", percent);
                }
              }
            }}
            disabled={timeOfDay !== 'EndDay'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Percent Complete"
            value={form.values.data.EndDay.PercentComplete || ''}
            {...form?.getInputProps("data.EndDay.PercentComplete")}
            onChange={(event) => {
              form?.setFieldValue("data.EndDay.PercentComplete", event.target.value);
            }}
            disabled={timeOfDay !== 'EndDay'}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
