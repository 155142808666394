import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import QuickAdd from '_GlobalComponents/QuickAdd';
import { Grid } from '@mui/material';
import { existsWithLength } from '@aldridge/aldg-helpers';
import { collection, doc, setDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { UserContext } from 'providers/UserProvider';
import CompaniesColumns from './columns/CompaniesColumns';
import CompanyManagementForm from './CompanyManagementForm';
import { firestore } from '../../firebase';

const CompaniesDefault = {
  CompanyNumber: '',
  CompanyName: '',
  ModifiedBy: '',
  ModifiedDate: '',
  CreatedBy: '',
  CreatedDate: '',
};

const CompanyManagement = (props) => {
  const { Companies } = props;
  const gridApiRef = useGridApiRef(null);
  const [record, setRecord] = useState({
    id: '',
    CompanyNumber: '',
    CompanyName: '',
    ModifiedBy: '',
    ModifiedDate: '',
    CreatedBy: '',
    CreatedDate: '',
  });
  const user = useContext(UserContext);
  const updateRow = (row) => {
    setRecord(row);
  };

  const validateRecord = (row) => {
    let valid = true;
    if (!row.CompanyName) {
      valid = false;
      toast.error('Company Name is required', { style: { bottom: '25px', textAlign: 'left' } });
    }
    if (!row.CompanyNumber) {
      valid = false;
      toast.error('Company Number is required', { style: { bottom: '25px', textAlign: 'left' } });
    }
    return valid;
  };
  const handleSave = (closeDrawer) => {
    console.log('record', record);
    const updatedRecord = record;
    updatedRecord.ModifiedBy = user.user.email;
    updatedRecord.ModifiedDate = new Date().toJSON();
    console.log('id', record.id);
    if (!record.id) {
      const { id } = doc(collection(firestore, 'Companies'));
      updatedRecord.CreatedBy = user.user.email;
      updatedRecord.CreatedDate = new Date().toJSON();
      updatedRecord.id = id;
    }
    console.log('updated', updatedRecord);
    const docRef = !existsWithLength(record.id)
      ? doc(collection(firestore, 'Companies'))
      : doc(collection(firestore, 'Companies'), record.id);
    if (validateRecord(updatedRecord)) {
      setDoc(docRef, updatedRecord, { merge: true }).then(() => {
        toast.success('Record Saved Successfully');
        closeDrawer();
      });
    }
    setRecord(CompaniesDefault);
  };

  const handleCancel = () => {
    setRecord(CompaniesDefault);
  };

  return (
    <Grid container sx={{ padding: 1 }}>
      <QuickAdd
        rows={Companies}
        columns={CompaniesColumns}

        apiRef={gridApiRef}
        getRowId={(row) => row.CompanyNumber}
        DataGridProps={{
          getRowId: (row) => row.CompanyNumber,
        }}
        canAdd
        updateRow={updateRow}
        handleSave={handleSave}
        handleCancel={handleCancel}
        formTitle="Company Management"
        form={<CompanyManagementForm currentRecord={record} setCurrentRecord={setRecord} user={user.user} />}
        ToolbarProps={{
          canDelete: false,
        }}
      />
    </Grid>
  );
};

CompanyManagement.propTypes = {
  Companies: PropTypes.array,
};
CompanyManagement.defaultProps = {
  Companies: [],
};
export default CompanyManagement;