/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  setDoc
} from 'firebase/firestore';
import {
  AldgQuickAdd,
  InputAutocomplete
} from '@aldridge/aldg-data-components';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { existsWithLength } from '@aldridge/aldg-helpers';
import QuickAdd from '_GlobalComponents/QuickAdd';
import { firebaseConfig, firestore } from '../firebase';
import { _HeaderTitle } from '../_Recoil/atoms';
import { basicStyles } from '../theme';
import ErrorBoundary from '../utils/ErrorBoundary';
import { UserContext } from '../providers/UserProvider';

const Admin = (props) => {
  document.title = 'Admin Page';
  const { match, history } = props;
  const { user } = useContext(UserContext);
  const classes = basicStyles();
  const setHeaderTitle = useSetRecoilState(_HeaderTitle);
  const [currentRecord, setCurrentRecord] = useState({});
  const [Loading, setLoading] = useState(false);

  const apiRef = useGridApiRef();

  const [rows, setRows] = useState([]);
  const columns = [
    {
      field: 'email',
      headerName: 'User',
      valueGetter: (params) =>
        `${params.row.name || ''} [${params.row.email || ''}]`,
      flex: 50,
      canEdit: true,
      inputType: 'InputAutocomplete',
      config: { firebaseConfig },
      firestoreOptions: {
        valueKey: 'email',
        collection: 'Users',
        orderBy: 'name',
        method: 'get'
      },
      optionDisplay: (opt) => `${opt.name || ''} [${opt.email || ''}]`
    },
    {
      field: 'IsAldridgeEmployee',
      headerName: 'Is Aldridge Employee',
      type: 'boolean',

      flex: 50
    },
    {
      field: 'JobAssignment',
      inputType: 'InputAutocomplete',
      valueGetter: (params) => params.row.JobAssignmentDisplay || '',
      valueFormatter: (params) => {
        <Grid container>
          {existsWithLength(params.value) ? (
            params.value.split(', ').forEach((x) => (
              <Grid item xs={12}>
                {x}
              </Grid>
            ))
          ) : (
            <Grid item xs={12} />
          )}
        </Grid>;
      },
      flex: 50,
      canEdit: true,
      config: { firebaseConfig },
      firestoreOptions: {
        method: 'get',
        valueKey: 'jctdscid',
        collection: 'ENT-Jobs',
        where: [['JobStatus', '==', 'Open']],
        orderBy: 'JobNumber'
      },
      optionDisplay: (opts) => {
        if (typeof opts.JobNumber === 'undefined') return '';
        return `${opts.JobNumber}.${opts.SubJobNumber} - ${opts.JobDescription}`;
      }
    }
  ];

  const refreshData = (mounted) => {
    onSnapshot(query(collection(firestore, `Users`)), (docs) => {
      const allUsers = [];
      docs.forEach((dc) => {
        allUsers.push(dc.data());
      });
      setRows(allUsers);
    });

    if (mounted) setLoading(false);
  };

  useEffect(() => {
    setHeaderTitle('Admin');
    refreshData();
  }, []);

  const handleSave = () => {
    setDoc(
      doc(firestore, `Users`, currentRecord.email),
      {
        dateModified: new Date().toJSON(),
        email: currentRecord.email,
        group: currentRecord.group,
        name: currentRecord.name,
        phone: currentRecord.phone,
        JobAssignment: currentRecord.JobAssignment,
        JobAssignmentDisplay: currentRecord.JobAssignmentDisplay
      },
      {
        merge: true
      }
    );

    setCurrentRecord((prev) => ({
      ...prev,
      dateModified: new Date().toJSON(),
      email: '',
      group: '',
      name: '',
      phone: '',
      JobAssignment: [],
      JobAssignmentDisplay: ''
    }));
    refreshData();
  };

  const UpdateRow = (row) => {
    setCurrentRecord({
      dateModified: row.dateModified || new Date().toJSON(),
      email: row.email || '',
      group: row.group || '',
      name: row.name || '',
      phone: row.phone || '',
      JobAssignment: row.JobAssignment || [],
      JobAssignmentDisplay: row.JobAssignmentDisplay || ''
    });
  };

  return (
    <ErrorBoundary componentName='Admin' user={user}>
      <Grid className={classes.gridItem}>
        <Typography align='left' variant='h5'>
          Job Assignment
        </Typography>
        <QuickAdd
          DataGridProps={{
            apiRef,
            pagination: true,
            getRowId: (row) => row.email,
            loading: Loading,
            // onCellClick: (cell, event) => {
            //   if (cell.field === 'Delete') {
            //     event.preventDefault();
            //     event.stopPropagation();
            //   }
            // },
            containerHeight: '400px'
          }}
          anchor='right'
          canAdd={false}
          columns={columns}
          form={
            <>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                padding={1}
              >
                <Grid item xs={12}>
                  <InputAutocomplete
                    firestoreOptions={{
                      valueKey: 'email',
                      collection: 'Users',
                      orderBy: 'name',
                      method: 'onSnapshot'
                    }}
                    label='User'
                    name='email'
                    onChange={(x, y, z, a) => {
                      setCurrentRecord((prev) => ({
                        ...prev,
                        ...a?.[0]
                      }));
                    }}
                    optionDisplay={(opt) => `${opt.name} [${opt.email}]`}
                    value={currentRecord.email || []}
                    disablePortal
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputAutocomplete
                    firestoreOptions={{
                      valueKey: 'jctdscid',
                      collection: 'ENT-Jobs',
                      orderBy: 'JobNumber',
                      method: 'get',
                      where: [['JobStatus', '==', 'Open']]
                    }}
                    getOptionKey={(opt) => opt.jctdscid}
                    optionDisplay={(opt) =>
                      `${opt.JobNumber}.${opt.SubJobNumber} - ${opt.JobDescription}`
                    }
                    label='Jobs Assignment'
                    name='JobAssignment'
                    onChange={(ids, field, display, objs) => {
                      setCurrentRecord((prev) => ({
                        ...prev,
                        JobAssignment: ids,
                        JobAssignmentDisplay: display
                      }));
                    }}
                    value={currentRecord.JobAssignment || []}
                    multiple
                    disableCloseOnSelect
                    disablePortal
                    display='flex'
                    flex={10}
                  />
                </Grid>
              </Grid>
            </>
          }
          formTitle='Job Assignment'
          handleSave={handleSave}
          rows={rows}
          updateRow={UpdateRow}
        />
      </Grid>
    </ErrorBoundary>
  );
};

Admin.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any)
};
Admin.defaultProps = {
  match: {
    params: {}
  },
  history: {
    push: () => { }
  }
};
export default Admin;
