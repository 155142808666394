/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
// import {
//   Autocomplete,
//   Grid,
//   Title,
//   Container,
//   NumberInput,
//   Text,
//   TextInput,
//   Button,
//   Select,
// } from "@mantine/core";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Select,
  Paper,
  Autocomplete
} from '@mui/material';

import { collection, getDocs, onSnapshot, orderBy, query } from 'firebase/firestore';
import { Files } from '@aldridge/aldg-data-components';
import { existsWithLength } from '@aldridge/aldg-helpers';
import { firebaseConfig, firestore } from '../../firebase';

export default function ObstaclesSection({
  form,
  requiresAddOb,
  setRequiresAddOb,
  checkLockout
}) {
  const [Obstacles, setObstacles] = useState([]);
  const [SubObstacles, setSubObstacles] = useState([]);
  const [SubObstacles2, setSubObstacles2] = useState([]);

  form.watch('data.EndDay.ObstacleCode', ({ previousValue, value }) => {
    if (existsWithLength(value)) {
      const q = query(
        collection(
          firestore,
          `Obstacles/${value[0]}/DetailedObstacles`
        ),
        orderBy('data.DetailedObstacleCode')
      );
      getDocs(q).then((res) => {
        const allSubObstacles = [];
        res.forEach((d) =>
          allSubObstacles.push({
            id: d.data().id,
            ...d.data().data,
            value: `${d.data().data.DetailedObstacleCode} ${d.data().data.DetailedObstacleDescription
              }`
          })
        );
        setSubObstacles(allSubObstacles);
      });

    }
  });

  form.watch('data.EndDay.ObstacleCode2', ({ previousValue, value }) => {
    if (existsWithLength(value)) {
      const q = query(
        collection(
          firestore,
          `Obstacles/${value[0]}/DetailedObstacles`
        ),
        orderBy('data.DetailedObstacleCode')
      );
      getDocs(q).then((res) => {
        const allSubObstacles = [];
        res.forEach((d) =>
          allSubObstacles.push({
            id: d.data().id,
            ...d.data().data,
            value: `${d.data().data.DetailedObstacleCode} ${d.data().data.DetailedObstacleDescription
              }`
          })
        );
        setSubObstacles2(allSubObstacles);
      });

    }
  });

  useEffect(() => {
    let mounted = true;
    const q = query(collection(firestore, 'Obstacles'), orderBy('data.ObstacleCode'));
    const unsub = onSnapshot(q, (res) => {
      const allObstacles = [];
      res.forEach((d) => allObstacles.push({ ...d.data(), id: d.id, value: `${d.data().data.ObstacleCode} ${d.data().data.ObstacleDescription}` }));
      if (mounted) setObstacles(allObstacles);
    });

    return () => {
      mounted = false;
      unsub();
    };
  }, []);

  const calculateImpactedHours = (val) => {
    const record = form.getValues();
    let hours = parseFloat(record.data.EndDay.HoursImpacted);
    let people = parseFloat(record.data.EndDay.PeopleImpacted);
    if (typeof val !== 'undefined') {
      hours = parseFloat(record.data.EndDay.HoursImpacted2);
      people = parseFloat(record.data.EndDay.PeopleImpacted2);
    }
    if (Number.isNaN(hours)) hours = 0;
    if (Number.isNaN(people)) people = 0;
    return hours * people;
  };

  return (
    <Paper
      sx={{
        padding: 2,
        '& .MuiGrid-item': {
          padding: 1
        }
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h6' style={{ textAlign: 'center' }}>
            Obstacles
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            options={Obstacles}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                const obstacle = Obstacles.find((o) => o.id === option);
                return obstacle ? obstacle.value : '';
              }
              return option.value;
            }}
            value={form.values.data.EndDay.ObstacleCode?.[0] || null}
            isOptionEqualToValue={(option, value) => option.id === value}
            onChange={(event, newValue) => {
              if (newValue) {
                form?.setFieldValue('data.EndDay.ObstacleCode', [newValue.id]);
                form?.setFieldValue('data.EndDay.ObstacleCodeDisplay', newValue.value);
              } else {
                form?.setFieldValue('data.EndDay.ObstacleCode', []);
                form?.setFieldValue('data.EndDay.ObstacleCodeDisplay', '');
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Obstacle Code"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            options={SubObstacles}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                const subObstacle = SubObstacles.find((o) => o.id === option);
                return subObstacle ? subObstacle.value : '';
              }
              return option.value;
            }}
            value={form.values.data.EndDay.SubObstacleCode?.[0] || null}
            isOptionEqualToValue={(option, value) => option.id === value}
            disabled={SubObstacles.length === 0}
            onChange={(event, newValue) => {
              if (newValue) {
                form?.setFieldValue('data.EndDay.SubObstacleCode', [newValue.id]);
                form?.setFieldValue('data.EndDay.SubObstacleCodeDisplay', newValue.value);
              } else {
                form?.setFieldValue('data.EndDay.SubObstacleCode', []);
                form?.setFieldValue('data.EndDay.SubObstacleCodeDisplay', '');
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Sub Obstacle Code"
                placeholder={
                  SubObstacles.length === 0
                    ? 'Please Select an Obstacle Code First...'
                    : 'Select a Sub Obstacle Code...'
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type='number'
            fullWidth
            label='Task Hours Impacted'
            {...form?.getInputProps('data.EndDay.HoursImpacted')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type='number'
            fullWidth
            label='# of People Impacted'
            {...form?.getInputProps('data.EndDay.PeopleImpacted')}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography>
            Total Hours Impacted:{' '}
            {form.values.data.EndDay.HoursImpacted *
              form.values.data.EndDay.PeopleImpacted}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            rows={2}
            fullWidth
            label='Reason For Impact'
            {...form?.getInputProps('data.EndDay.ReasonsForImpact')}
            value={form.values.data.EndDay.ReasonsForImpact}
          />
        </Grid>
        {!requiresAddOb ? (
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'right' }}
          >
            <Button
              variant='outlined'
              style={{ textTransform: 'none' }}
              onClick={() => {
                setRequiresAddOb(true);
              }}
            >
              <u>
                <i>
                  Another obstacle is <b>absolutely</b> necessary
                </i>
              </u>
              .
            </Button>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <h3>Secondary Obstacle</h3>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={Obstacles}
                value={form.values.data.EndDay.ObstacleCode2?.[0] || null}
                onChange={(event, value) => {
                  if (value) {
                    form?.setFieldValue('data.EndDay.ObstacleCode2', [value.id]);
                    form?.setFieldValue('data.EndDay.ObstacleCode2Display', value.value);
                  } else {
                    form?.setFieldValue('data.EndDay.ObstacleCode2', []);
                    form?.setFieldValue('data.EndDay.ObstacleCode2Display', '');
                  }
                }}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    const subObstacle = Obstacles.find((o) => o.id === option);
                    return subObstacle ? subObstacle.value : '';
                  }
                  return option.value;
                }}
                disabled={checkLockout}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Obstacle Code"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={SubObstacles2}
                value={form.values.data.EndDay.SubObstacleCode2?.[0] || null}
                isOptionEqualToValue={(option, value) => option.id === value}
                disabled={checkLockout || SubObstacles2.length === 0}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    const subObstacle = SubObstacles2.find((o) => o.id === option);
                    return subObstacle ? subObstacle.value : '';
                  }
                  return option.value;
                }}
                onChange={(event, value) => {
                  if (value) {
                    form?.setFieldValue('data.EndDay.SubObstacleCode2', [value.id]);
                    form?.setFieldValue('data.EndDay.SubObstacleCode2Display', value.value);
                  } else {
                    form?.setFieldValue('data.EndDay.SubObstacleCode2', []);
                    form?.setFieldValue('data.EndDay.SubObstacleCode2Display', '');
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sub Obstacle Code"
                    placeholder={
                      SubObstacles2.length === 0
                        ? 'Please Select an Obstacle Code First...'
                        : 'Select a Sub Obstacle Code...'
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type='number'
                fullWidth
                label='Task Hours Impacted'
                {...form?.getInputProps('data.EndDay.HoursImpacted2')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type='number'
                fullWidth
                label='# of People Impacted'
                {...form?.getInputProps('data.EndDay.PeopleImpacted2')}
              />
            </Grid>
            <Grid item xs={12}>
              Total Hours Impacted: {calculateImpactedHours(true)}
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                rows={2}
                fullWidth
                label='Reasons For Impact'
                {...form?.getInputProps('data.EndDay.ReasonsForImpact2')}
                value={form.values.data.EndDay.ReasonsForImpact2}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'right' }}
            >
              {' '}
              <Button
                variant='outlined'
                style={{ textTransform: 'none' }}
                onClick={() => {
                  setRequiresAddOb(false);
                }}
              >
                <i>Nevermind, a second obstacle isn&apos;t necessary.</i>
              </Button>{' '}
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Files
            label='Obstacle Photos'
            name='ObstaclePhotos'
            folder={`${form.values.data.JobNumber}/${form.values.data.Date}/${form.values.data.Foreman}/Obstacles`}
            bucket={firebaseConfig.storageBucket}
            firebaseConfig={firebaseConfig}
            getFilesFunction='getFiles'
            multiple
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
