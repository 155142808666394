/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Drawer, Grid, Typography } from '@mui/material';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { grey } from '@mui/material/colors';
import { AldgDataList } from '@aldridge/aldg-data-components';
import { existsWithLength } from '@aldridge/aldg-helpers';

/**
 * QuickAdd is a component that displays a data list with a drawer that contains a form to add or update a record.
 * @param {object} props The properties of the component.
 * @param {string} props.anchor The anchor position of the drawer. 'left', 'right', 'top', 'bottom'
 * @param {boolean} props.canAdd If the user can add a new record
 * @param {array} props.columns The columns to display in the data list
 * @param {object} props.DataGridProps The properties to pass to the Data Grid
 * @param {number} props.drawerSize The size of the drawer in percent
 * @param {node} props.form The form to display in the drawer
 * @param {function} props.handleCancel The function to call when the cancel button is clicked
 * @param {function} props.handleSave The function to call when the save button is clicked
 * @param {array} props.rows The rows to display in the data list
 * @param {function} props.updateRow The function to call when a row is clicked
 */
const QuickAdd = (props) => {
  const {
    additButtons,
    anchor,
    canAdd,
    canEdit,
    columns,
    drawerSize,
    form,
    handleCancel,
    handleSave,
    rows,
    updateRow,
    loading,
    DataGridProps,
    formTitle,
    clearRecord,
    editable
  } = props;

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const handleRowClick = (row) => {
    const rowdata = row.row;
    setOpen(true);
    updateRow(rowdata);
  };

  return (
    <>
      <Drawer
        open={open}
        PaperProps={{
          sx: {
            width: ['left', 'right'].includes(anchor) ? drawerSize : '100%',
            height: ['top', 'bottom'].includes(anchor) ? drawerSize : '100%'
          }
        }}
        anchor={anchor}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-end'
          alignContent='space-between'
          sx={{ height: '100%' }}
          padding={2}
        >
          <Grid item xs={12} sx={{ height: '90%', overflow: 'auto' }}>
            <Typography variant='h6' gutterBottom color={grey[900]}>
              {formTitle}
            </Typography>
            {form}
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant='contained'
              color='success'
              onClick={() => handleSave(closeDrawer)} // Pass closeDrawer to handleSave
            >
              save
            </Button>
            &nbsp;
            <Button
              variant='contained'
              color='error'
              onClick={() => {
                handleCancel();
                toggleDrawer(false);
              }}
            >
              cancel
            </Button>
          </Grid>
        </Grid>
      </Drawer>

      <Grid container>
        <Grid item xs={12} paddingTop={2}>
          <AldgDataList
            {...DataGridProps}
            editable={editable}
            loading={loading}
            ToolbarProps={{
              additionalButtons: [
                ...(canAdd
                  ? [
                      {
                        tooltipText: 'Add',
                        icon: faPlus,
                        color: 'primary',
                        onClick: () => {
                          toggleDrawer(true);
                          clearRecord();
                        }
                      }
                    ]
                  : []),
                ...additButtons
              ]
            }}
            rows={rows}
            onRowClick={(params, event) => {
              const isStatus =
                event.target.dataset.field === 'Status' ||
                existsWithLength(event.target.checked);
              if (canEdit && !isStatus) handleRowClick(params);
            }}
            columns={columns}
          />
        </Grid>
      </Grid>
    </>
  );
};

QuickAdd.propTypes = {
  /** Additional buttons for the toolbar */
  additButtons: PropTypes.array,
  /** The anchor position of the drawer. 'left', 'right', 'top', 'bottom' */
  anchor: PropTypes.string,
  /** If the user can add a new record */
  canAdd: PropTypes.bool,
  /** If the user can edit a record */
  canEdit: PropTypes.bool,
  /** The columns to display in the data list */
  columns: PropTypes.array,
  /** The properties to pass to the Data Grid */
  DataGridProps: PropTypes.object,
  /** The size of the drawer in percent */
  drawerSize: PropTypes.string,
  /** The form to display in the drawer */
  form: PropTypes.node,
  /** If the data list is loading */
  loading: PropTypes.bool,
  /** The function to call when the cancel button is clicked */
  handleCancel: PropTypes.func,
  /** The function to call when the save button is clicked */
  handleSave: PropTypes.func,
  /** The rows to display in the data list */
  rows: PropTypes.array,
  /** The function to call when a row is clicked */
  updateRow: PropTypes.func,
  /** The title of the form */
  formTitle: PropTypes.string,
  /** The function to clear the form */
  clearRecord: PropTypes.func,
  /** If the data list is editable */
  editable: PropTypes.bool
};

QuickAdd.defaultProps = {
  additButtons: [],
  anchor: 'left',
  canAdd: true,
  canEdit: true,
  columns: [],
  DataGridProps: {},
  drawerSize: '30%',
  form: null,
  loading: false,
  handleCancel: () => {},
  handleSave: () => {},
  rows: [],
  updateRow: () => {},
  formTitle: 'Quick Add',
  clearRecord: () => {},
  editable: false
};
export default QuickAdd;
