/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
// import { ActionIcon, Grid, Container, Table, Text, Title, Autocomplete, TextInput, Textarea } from '@mantine/core';
import { Button, Grid, Table, Typography, Autocomplete, TextField, TableHead, TableRow, TableBody, TableCell, useTheme, useMediaQuery } from '@mui/material';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { isMobile, isTablet } from 'react-device-detect';

export default function AddRecordTable({ form, Subcontractors, checkLockout }) {
  // const record = form.getValues();
  const Headers = ['Subcontractor', 'Tasks Completed', '# Of Personnel'];
  const subcontractorTransactions = {
    Subcontractor: [],
    TasksCompleted: '',
    NumberOfSubPersonnel: ''
  };
  const [recordSubRows, setRecordSub] = useState(form.values.data.Subcontractors);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const showLabels = useMemo(() => isMobile || isTablet || matches, [isMobile, isTablet, matches]);

  form.watch('data.Subcontractors', ({ value }) => {
    setRecordSub(value);
  });


  const addRow = (type, transaction) => {
    const record = form.getValues();
    const r = record.data[type];
    const upd = [...r, { id: (r.length || 0) + 1, ...transaction }];
    const rec = record?.data;
    rec[type] = upd;
    setRecordSub(upd);
    form.setFieldValue('data', rec);
  };

  const onSubcontractorChange = (value, id) => {
    const record = form.getValues();
    const subRows = record.data.Subcontractors || [];
    const upd = subRows.find(row => row.id === id);
    upd.Subcontractor = [value.id];
    upd.SubcontractorDisplay = value.value;
    const rec = record.data;
    rec.Subcontractors = subRows;
    form.setFieldValue('data', rec);
  };
  const updateSubcontractorRow = (value, field, id) => {
    const record = form.getValues();
    const subsRows = record.data.Subcontractors || [];
    const upd = subsRows.map(row => (row.id === id ? { ...row, [field]: value } : row));
    const rec = record.data;
    rec.Subcontractors = upd;
    setRecordSub(upd);
    form.setFieldValue('data', rec);
  };

  const deleteRow = (id) => {
    const record = form.getValues();
    const subsRows = record.data.Subcontractors;
    const upd = subsRows.filter(row => row.id !== id);
    const rec = record.data;
    rec.Subcontractors = upd;
    setRecordSub(upd);
    form.setFieldValue('data', rec);
  };

  return (
    <Grid container
      sx={{
        border: "1px solid lightgray",
        padding: "1rem",
        borderRadius: "5px",
        backgroundColor: "white",
        margin: "1rem 0",
      }}
    >
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Typography variant='h6' sx={{ textAlign: "center" }}>
          Subcontractors
        </Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography style={{ display: "flex", justifyContent: "space-between" }}>
            <b>Add Subcontractors</b>
            <ToolTipIconButton icon={faPlus} shape='circle' onClick={() => addRow('Subcontractors', subcontractorTransactions)} />
          </Typography>
          <Grid container spacing={1} sx={{
            width: "100%",
          }}>
            <Grid container item xs={12} sx={{ fontWeight: 'bold', borderBottom: '1px solid rgba(0,0,0,0.12)', display: showLabels ? 'none' : 'flex' }}>
              {Headers.map((header, idx) => {
                const widths = [5, 4, 2, 1];
                return (
                  <Grid item key={header} xs={12} md={widths[idx]}>
                    {header}
                  </Grid>
                );
              })}
            </Grid>

            {recordSubRows?.map((row, idx) => (
              <Grid container item xs={12} key={row.id} sx={{
                borderBottom: "1px solid lightgray",
                backgroundColor: idx % 2 === 0 ? 'white' : '#f5f5f5',
                alignItems: 'center',
                '& .MuiGrid-item': {
                  padding: 0.5
                }
              }}>
                <Grid item xs={12} md={5}>
                  <Autocomplete
                    options={Subcontractors}
                    disabled={checkLockout}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        const eq = Subcontractors.find(equip => equip.id === option);
                        if (eq) {
                          return eq.data.SubcontractorDisplay;
                        }
                        return '';
                      }
                      return option.data.SubcontractorDisplay;
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value}
                    renderInput={(params) => <TextField {...params} label={showLabels ? 'Subcontractor' : ''} />}
                    onChange={(event, value) => onSubcontractorChange(value, row.id)}
                    placeholder={Subcontractors.length > 0 ? "Select Subcontractor..." : "There are No Subcontractor Available..."}
                    value={row.Subcontractor?.[0] || null}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    multiline
                    minRows={1}
                    label={showLabels ? 'Tasks Completed' : ''}
                    value={row.TasksCompleted || ''}
                    disabled={checkLockout}
                    onChange={(event) => updateSubcontractorRow(event.target.value, 'TasksCompleted', row.id)}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    fullWidth
                    value={row.NumberOfSubPersonnel || ''}
                    label={showLabels ? 'Number of Sub Personnel' : ''}
                    disabled={checkLockout}
                    onChange={(event) => updateSubcontractorRow(event.target.value, 'NumberOfSubPersonnel', row.id)}
                  />
                </Grid>
                <Grid item xs={12} md={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <ToolTipIconButton icon={faTrash} color='error' shape='circle' onClick={() => deleteRow(row.id)} />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};