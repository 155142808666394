import { NaNtoNum } from '@aldridge/aldg-helpers';

const getWBSDescription = (r) => {
  const display = [];
  if (r.data.TaskType === 'Task') {
    display.push(r.data.PICCDisplay);
    display.push(r.data.Task);
    return display.join(' / ');
  }
  if (r.data.TaskType === 'PreFab') {
    display.push(r.data.OrderNumberDisplay);
    if (r.data.Task !== '') display.push(r.data.Task);
  }
  if (r.data.TaskType === 'WBS') {
    if ((r.data.Level1 || '').toString().trim() !== '') {
      display.push(r.data.Level1);
    }
    if ((r.data.Level2 || '').toString().trim() !== '') {
      display.push(r.data.Level2);
    }
    if ((r.data.Level3 || '').toString().trim() !== '') {
      display.push(r.data.Level3);
    }
    if ((r.data.Level4 || '').toString().trim() !== '') {
      display.push(r.data.Level4);
    }
    if (r.data.Task !== '') {
      display.push(r.data.Task);
    }
  }
  return display.join(' / ');
};

const getManHours = (task) => {
  const taskHours = NaNtoNum(task.data.StartDay.PlannedShiftHours);
  const EmployeesWorking =
    task.data.EmployeesWorkingOnTask.length > 0
      ? task.data.EmployeesWorkingOnTask.length
      : 1;

  return taskHours * EmployeesWorking;
};
export default getWBSDescription;
export { getManHours };
