/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState, useMemo } from "react";
// import {
//   Autocomplete,
//   Button,
//   Checkbox,
//   Grid,
//   Select,
//   TextInput,
// } from "@mantine/core";
import { Autocomplete, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { collection, onSnapshot, query } from "firebase/firestore";
import { useFunctionCall } from "@aldridge/aldg-helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { DatePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { ToolTipIconButton } from "@aldridge/aldg-ui-components";
import { firestore } from "../../../firebase";

export default function PrefabSection({ form, PICCs, CurrentJobNumber }) {
  const [Orders, setOrders] = useState([]);

  const { data, loading, error, functionCall } = useFunctionCall('otdOrders');


  useEffect(() => {
    const record = form.getValues();
    functionCall({
      job: form.values.data.JobNumber || '',
      hideCompleted: record.data.HideCompleted || ''
    });
  }, [form.values.data.JobNumber, form.values.data.HideCompleted]);

  useEffect(() => {
    if (data) {
      setOrders(data);
    }
    if (error) {
      console.log(error);
    }
  }, [data, error]);

  console.log(form.values);
  return (
    <Grid container>
      <Grid item xs={12} style={{ display: "flex", padding: 1, marginTop: 25, marginLeft: 50 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={form.values?.data?.HideCompleted === 'true'}
              onChange={(event) => {
                form.setFieldValue("data.HideCompleted", `${event.target.checked}`);
              }}
            />
          }
          label="Hide Completed Tickets"
        />
      </Grid>
      <Grid item xs={8}>
        <Autocomplete
          value={form.values?.data?.OrderNumber}
          options={loading ? [] : Orders}
          loading={loading}
          getOptionLabel={(opts) => {
            const order = Orders.find(opt => opt.id === opts[0]);
            if (order) return `${order.data.OrderNumber} - ${order.data.BriefDescription} [${order.data.Status}]`;
            if (typeof opts === 'object' && !Array.isArray(opts)) return `${opts.data.OrderNumber} - ${opts.data.BriefDescription} [${opts.data.Status}]`;
            return '';
          }}
          renderInput={(params) => <TextField {...params} label="Order Number" />}
          isOptionEqualToValue={(option, value) => option.id === value?.[0]}
          onChange={(event, value) => {
            if (value) {
              form.setFieldValue("data.OrderNumber", [value.id]);
              form.setFieldValue("data.OrderNumberDisplay", `${value.data.OrderNumber} - ${value.data.BriefDescription} [${value.data.Status}]`);
              form.setFieldValue("data.BriefDescription", value.data.BriefDescription);
              form.setFieldValue("data.Status", value.data.Status);

              form.setFieldValue("data.JobNumber", value.data.JobNumber[0] || '');
              form.setFieldValue("data.JobNumberDisplay", value.data.JobNumberDisplay);

              form.setFieldValue("data.PICC", value.data.Picc || []);
              form.setFieldValue("data.PICCDisplay", value.data.PiccDisplay);
              form.setFieldValue("data.ScopeOfWork", value.data.ScopeOfWork || '');
              form.setFieldValue("data.TypeOfWork", value.data.TypeOfWork || '');
              form.setFieldValue("data.NeedByDate", value.data.DateNeededBy || '');
              form.setFieldValue("data.IsGLTicket", value.data.IsGLTicket || '');
              form.setFieldValue("data.GLCode", value.data.GLCode || '');
            } else {
              form.setFieldValue("data.OrderNumber", []);
              form.setFieldValue("data.OrderNumberDisplay", '');
              form.setFieldValue("data.BriefDescription", '');
              form.setFieldValue("data.Status", '');

              form.setFieldValue("data.JobNumber", '');
              form.setFieldValue("data.JobNumberDisplay", '');

              form.setFieldValue("data.PICC", []);
              form.setFieldValue("data.PICCDisplay", '');
              form.setFieldValue("data.ScopeOfWork", '');
              form.setFieldValue("data.TypeOfWork", '');
              form.setFieldValue("data.NeedByDate", '');
            }
          }}
        />
      </Grid>
      <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {form.values?.data.OrderNumber?.length > 0 && (
          <ToolTipIconButton icon={faArrowUpRightFromSquare} tooltipText="View Order in OTD" onClick={() => {
            window.open(`https://aldg-otd.web.app/requests/${form.values?.data.OrderNumber?.[0]}`, '_blank');
          }} />
        )}
      </Grid>
      <Grid item xs={3}>
        <DatePicker
          label="Need by Date"
          value={dayjs(form?.values?.data?.NeedByDate)}
          onChange={(value) => form.setFieldValue("data.NeedByDate", value)}
          slotProps={{
            textField: {
              fullWidth: true,
              disabled: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel>Type of Work</InputLabel>
          <Select
            label="Type of Work"
            placeholder="Select a Type of Work..."
            fullWidth
            {...form?.getInputProps("data.TypeOfWork")}
            onChange={(event, value) => {
              form.setFieldValue("data.TypeOfWork", event.target.value);
            }}
            value={form.values?.data?.TypeOfWork || ''}
            disabled
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="Welding/ Fabrication/ Jigs">Welding/ Fabrication/ Jigs</MenuItem>
            <MenuItem value="Underground/ Ductbank">Underground/ Ductbank</MenuItem>
            <MenuItem value="Cable/ Wire Prep">Cable/ Wire Prep</MenuItem>
            <MenuItem value="Building Lighting">Building Lighting</MenuItem>
            <MenuItem value="Poles/ Luminaries">Poles/ Luminaries</MenuItem>
            <MenuItem value="Conduit bending/ Nipples/ Racks">Conduit bending/ Nipples/ Racks</MenuItem>
            <MenuItem value="JBox Prep">JBox Prep</MenuItem>
            <MenuItem value="Device/ Equipment Prep">Device/ Equipment Prep</MenuItem>
            <MenuItem value="Material Kitting">Material Kitting</MenuItem>
            <MenuItem value="Concrete Precast">Concrete Precast</MenuItem>
            <MenuItem value="CNC">CNC</MenuItem>
            <MenuItem value="Equipment Racks">Equipment Racks</MenuItem>
            <MenuItem value="Carpenter">Carpenter</MenuItem>
            <MenuItem value="R&D">R&D</MenuItem>
            <MenuItem value="Rework">Rework</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>

      </Grid>
      <Grid item xs={9}>
        <TextField
          label="Scope of Work"
          {...form?.getInputProps("data.ScopeOfWork")}
          value={form.values?.data?.ScopeOfWork || ''}
          disabled
          multiline
          rows={2}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          label="PICC"
          options={PICCs}
          renderInput={(params) => <TextField {...params} label="PICC" />}
          getOptionLabel={(opts) => {
            const picc = PICCs.find((p) => p.jctmstid === opts);
            if (picc) {
              return `${picc.PICC} - ${picc.Description}`;
            }
            if (opts.jctmstid) {
              return `${opts.PICC} - ${opts.Description}`;
            }
            return '';
          }}
          placeholder={PICCs.length > 0 ? "Select a PICC..." : "There are No PICCs Available..."}
          isOptionEqualToValue={(option, value) => option.jctmstid === value}
          disabled={PICCs.length === 0}
          {...form?.getInputProps("data.PICCDisplay")}
          value={form.values?.data?.PICC?.[0] || null}
          onChange={(event, value) => {
            if (value) {
              form.setFieldValue("data.PICC", [value.jctmstid]);
              form.setFieldValue(
                'data.PICCDisplay',
                `${value.PICC} - ${value.Description}`
              );
            } else {
              form.setFieldValue("data.PICC", []);
              form.setFieldValue("data.PICCDisplay", '');
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Additional Work Details (Task)"
          {...form?.getInputProps("data.Task")}
          multiline
          rows={2}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}
