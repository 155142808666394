import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, useMediaQuery, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { InputAutocomplete, InputText } from '@aldridge/aldg-data-components';
import { useRecoilValue } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { onSnapshot, doc, setDoc, collection } from 'firebase/firestore';
import { firestore, firebaseConfig } from '../../firebase';
import { _CurrentJobNumber } from '../../_Recoil/atoms';
import { UserContext } from '../../providers/UserProvider';
import { basicStyles, icons } from '../../theme';

const Subcontractor = (props) => {
  const classes = basicStyles();
  const { user } = useContext(UserContext);
  const { history, match } = props;
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const [record, setRecord] = useState({
    id: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      JobNumber: CurrentJobNumber.value[0],
      Subcontractor: [],
      Name: '',
      VendorNumber: '',
      ContractNumber: ''
    }
  });

  useEffect(() => {
    let mounted = true;
    if (match.params.id === 'new') return;
    onSnapshot(doc(firestore, 'Subcontractor', match.params.id), (snap) => {
      if (snap.exists) {
        const d = snap.data();
        if (mounted) setRecord(d);
      }
    });
    // eslint-disable-next-line consistent-return
    return () => (mounted = false);
  }, [match.params.id]);

  const onChange = (event, name, displayFromTypeahead, fullRecord) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...record };
      changedRecord.data[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changedRecord.data[`${id}Display`] = displayFromTypeahead;
      }
      if (id === 'Subcontractor') {
        if (fullRecord?.length > 0) {
          changedRecord.data.VendorNumber = fullRecord[0].VendorNumber;
          changedRecord.data.Name = fullRecord[0].Name;
          changedRecord.data.ContractNumber = fullRecord[0].ContractNumber;
          changedRecord.data.Description = fullRecord[0].Description;
          changedRecord.data.SerialNumber = fullRecord[0].SerialNumber;
        } else {
          changedRecord.data.VendorNumber = '';
          changedRecord.data.Name = '';
          changedRecord.data.ContractNumber = '';
          changedRecord.data.Description = '';
          changedRecord.data.SerialNumber = '';
        }
      }
      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      setRecord(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const addSubcontractor = (e, newRecord) => {
    if (record.data.Subcontractor.length !== 0) {
      try {
        const savedRecord = JSON.parse(JSON.stringify(record));
        if (match.params.id === 'new') {
          const docRef = doc(collection(firestore, 'Subcontractor'));
          savedRecord.id = docRef.id;
          setDoc(docRef, savedRecord);
        } else {
          setDoc(doc(firestore, 'Subcontractor', savedRecord.id), {
            merge: true
          });
        }
        if (newRecord) {
          history.push('/Subcontractor/new');
          setRecord({
            id: '',
            CreatedBy: '',
            CreatedDate: '',
            ModifiedBy: '',
            ModifiedDate: '',
            data: {
              JobNumber: CurrentJobNumber.value[0],
              Subcontractor: [],
              Name: '',
              VendorNumber: '',
              ContractNumber: ''
            }
          });
        } else {
          history.push('/Subcontractor');
        }
        // closeRecord();
        toast.success(
          `Subcontractor ${record.data.SubcontractorDisplay} saved successfully!`,
          { autoClose: 5000 }
        );
      } catch (err) {
        toast.error('Error saving record.', { autoClose: 5000 });
      }
    } else {
      toast.warn('Please select a subcontractor before submitting.');
    }
  };

  const goBack = () => {
    history.push('/Subcontractor');
  };
  const iconClasses = icons();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <div
        style={{
          position: 'sticky',
          top: '-1px',
          left: 0,
          right: 0,
          width: '100%',
          padding: '8px',
          paddingRight: '0px',
          display: 'flex',
          justifyContent: 'flex-end',
          zIndex: 100
        }}
      >
        <div
          className={`${iconClasses.container} ${iconClasses.greenContainer}`}
          style={{ margin: matches ? '' : '0px 12px' }}
          title='Save Record'
        >
          <Button
            onClick={addSubcontractor}
            disableRipple
            className={iconClasses.buttonWrapper}
          >
            <FontAwesomeIcon icon={faSave} className={iconClasses.icon} />
          </Button>
        </div>
        <div
          className={`fa-layers fa-fw ${iconClasses.container} ${iconClasses.blueContainer}`}
          style={{ margin: matches ? '0px -8px' : '0px 12px' }}
          title='Save and New Record'
        >
          <Button
            onClick={(event) => addSubcontractor(event, true)}
            disableRipple
            className={iconClasses.buttonWrapper}
          >
            <FontAwesomeIcon
              style={{ fontSize: '24px', color: 'white' }}
              icon={faSave}
            />
            <FontAwesomeIcon
              style={{ color: 'black', top: '-14px', left: '16px' }}
              icon={faSparkles}
            />
          </Button>
        </div>

        <div
          className={`${iconClasses.container} ${iconClasses.redContainer}`}
          style={{ margin: '0px 0px 0px 12px' }}
          title='Close Record'
        >
          <Button
            onClick={goBack}
            disableRipple
            className={iconClasses.buttonWrapper}
          >
            <FontAwesomeIcon
              icon={faTimes}
              className={iconClasses.icon}
              onClick={goBack}
            />
          </Button>
        </div>
      </div>
      <Paper className={classes.paperWMargin}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputAutocomplete
              firebaseConfig={firebaseConfig}
              onChange={onChange}
              firestoreOptions={{
                method: 'get',
                valueKey: 'id',
                orderBy: 'jctdscid',
                collection: 'ENT-Subcontractors'
              }}
              value={record.data.Subcontractor || []}
              name='Subcontractor'
              label='Subcontractor'
              optionDisplay={(opts) => {
                if (Object.keys(opts).length === 0) return '';
                if (opts.ContractNumber !== '') {
                  return `${opts.Name.trim()} [Contract #: ${opts.ContractNumber
                    }] [${opts.VendorNumber}]`;
                }
                return `${opts.Name.trim()} [${opts.VendorNumber}]`;
              }}
              // optionDisplay={["option_ContractNumber", "-", "option_Name"]}
              filterDataset={(opts) =>
                opts
                  .filter(
                    (r) =>
                      r.jctdscid === CurrentJobNumber.value[0] ||
                      r.jctdscid === ''
                  )
                  .sort((a, b) => b.jctdscid.localeCompare(a.jctdscid))
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputText
              label='Vendor Number'
              name='VendorNumber'
              value={record.data.VendorNumber}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputText
              label='Contract Number'
              name='ContractNumber'
              value={record.data.ContractNumber}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputText
              label='Name'
              name='Name'
              value={record.data.Name}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <InputText
              label='Notes'
              name='Notes'
              value={record.data.Notes || ''}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
Subcontractor.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired
};
Subcontractor.defaultProps = {};

export default Subcontractor;
