/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from '@mantine/form';
// import {
//   MantineProvider,
//   Button,
//   Text,
//   Flex,
// } from "@mantine/core";
import { useRecoilValue } from 'recoil';
import {
  _CurrentForeman,
  _CurrentJobNumber,
  _CurrentDate
} from '_Recoil/atoms';
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  doc,
  setDoc,
  getDocs
} from 'firebase/firestore';
import { useRouteMatch } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { checkPOD } from 'utils/checkLockout';
import { toast } from 'react-toastify';
import { Typography, Grid, Button } from '@mui/material';
import { existsWithLength } from '@aldridge/aldg-helpers';
import { firestore } from '../../firebase';
import TaskWBSSection from './TaskWBSSection';
import DailyProductionSection from './DailyProductionSection';
import PlannedSection from './PlannedSection';
import CompletedHoursSection from './CompletedHoursSection';
import ObstaclesSection from './ObstaclesSection';

export default function PODForm3({ timeOfDay, currentRow, user, history }) {
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const CurrentDate = useRecoilValue(_CurrentDate);
  const [requiresAddOb, setRequiresAddOb] = React.useState(false);
  const match = useRouteMatch();
  const form = useForm({
    // mode: 'uncontrolled',
    initialValues: {
      id: '',
      CreatedBy: '',
      CreatedDate: '',
      ModifiedBy: '',
      ModifiedDate: '',
      ScreenSize: '',
      data: {
        PreFabRecord: false,
        Foreman: CurrentForeman.value[0],
        ForemanDisplay: CurrentForeman.shortDisplay,
        JobNumber: CurrentJobNumber.value[0],
        JobNumberDisplay: CurrentJobNumber.display,
        EmployeesWorkingOnTask: [],
        AssociatedPicc: [],
        AllWBS: '',
        Level1: '',
        Level2: '',
        Level3: '',
        Level4: '',
        Date: CurrentDate.value || new Date().toJSON(),
        HideCompleted: 'true',
        Task: '',
        TaskType: user.prefab && !user.admin ? 'PreFab' : 'WBS',
        OrderNumber: '',
        IsGLTicket: false,
        GLCode: '',
        PICC: '',
        ForceNumber: '',
        ShowOnlyMyCrew: 'true',
        NeedByDate: new Date().toJSON(),
        StartDay: {
          ExpectedProductionQuantity: '',
          UoM: '',
          PlannedShiftHours: ''
        },
        EndDay: {
          CompletedProductionQuantity: '',
          PercentComplete: '',
          ObstacleCode: [],
          SubObstacleCode: [],
          HoursImpacted: '',
          PeopleImpacted: '',
          ReasonsForImpact: '',
          ObstacleCode2: [],
          SubObstacleCode2: [],
          HoursImpacted2: '',
          PeopleImpacted2: '',
          ReasonsForImpact2: ''
        }
      }
    }
  });
  const checkLockout = checkPOD(user, form.getValues());
  const mounted = useRef(false);

  useEffect(() => {
    if (currentRow?.id !== '') form.setValues(currentRow);
  }, [currentRow]);

  const [Employees, setEmployees] = useState([]);
  const [ENTJobs, setENTJobs] = useState([]);
  const [ENTPICCs, setENTPICCs] = useState([]);
  const [PICCs, setPICCs] = useState([]);
  const [Crew, setCrew] = useState([]);

  useEffect(() => {
    const empQuery = query(
      collection(firestore, 'ENT-Employees'),
      where('Status', '==', 'A'),
      where('EmployeeType', 'in', ['Field', 'Fleet'])
    );
    getDocs(empQuery).then((res) => {
      const allEmployees = [];
      res.forEach((d) => {
        let display = `${d.data().LegalLastName} ${d.data().Suffix || ''}, ${d.data().PreferredFirstName
          } ${d.data().MiddleName || ''} - [${d.data().Title} - ${d.data().UniversalID
          }]`.replace(/ {2}/g, ' ');
        if (d.data().IsAldridgeEmployee !== true) {
          display += ` - [${d.data().Company}]`;
        }
        allEmployees.push({ ...d.data(), value: display });
      });
      setEmployees(allEmployees);
    });

    const whereClauses = (user.IsAldridgeEmployee && !existsWithLength(user.JobAssignment)) ? [['JobStatus', '==', 'Open']] : [['JobStatus', '==', 'Open'], ['jctdscid', 'in', user.JobAssignment?.slice(0, 10) || []]];
    const NonAldridgeWithNoJobAssignments = !user.IsAldridgeEmployee && !existsWithLength(user.JobAssignment);
    if (NonAldridgeWithNoJobAssignments) {
      toast.error('You have not been assigned any jobs. Please reach out to the Project Team and the Agile Department to get assigned to a job.');
      return () => {
        mounted.current = false;
      };
    }
    let jobs = query(
      collection(firestore, 'ENT-Jobs'),
    );
    if (existsWithLength(whereClauses)) {
      whereClauses.forEach((clause) => {
        jobs = query(jobs, where(clause[0], clause[1], clause[2]));
      });
    } else {
      jobs = query(jobs, where('JobStatus', '==', 'Open'));
    }
    getDocs(jobs).then((res) => {
      const allJobs = [];
      res.forEach((d) => {
        allJobs.push(d.data());
      });
      setENTJobs(allJobs);
    });

    // eslint-disable-next-line consistent-return
    return () => {
      mounted.current = false;
    };
  }, []);

  const getCrew = () => {
    const record = form.getValues();
    const foreman = record?.data?.Foreman;
    const jobNumber = record?.data?.JobNumber;
    const crew = (() => {
      const col = collection(firestore, 'Crew');
      if (!user.admin && user.prefab && existsWithLength(foreman)) {
        return query(col, where('data.Foreman', '==', foreman));
      }
      if (existsWithLength(foreman) && !existsWithLength(jobNumber)) {
        return query(col, where('data.Foreman', '==', foreman));
      }
      if (existsWithLength(jobNumber) && !existsWithLength(foreman)) {
        return query(col, where('data.JobNumber', '==', jobNumber));
      }
      if (existsWithLength(jobNumber) && existsWithLength(foreman)) {
        return query(col, where('data.Foreman', '==', foreman), where('data.JobNumber', '==', jobNumber));
      }
    })();
    getDocs(crew).then((res) => {
      const allEmployees = [];
      res.forEach((d) =>
        allEmployees.push({ ...d.data(), value: `${d.data().data.EmployeeDisplay} ${d.data().data.UnionDisplay}` })
      );
      if (mounted) {
        setCrew(allEmployees.sort((a, b) => a.value.localeCompare(b.value)));
      }
    });
  };
  const getPiccs = () => {
    const record = form.getValues();
    const jobNumber = record?.data?.JobNumber;
    if (existsWithLength(jobNumber)) {
      let wbsCodes = collection(firestore, 'PICC');
      wbsCodes = query(wbsCodes, where('data.JobNumber', '==', jobNumber));
      getDocs(wbsCodes).then((res) => {
        const allPICCs = [];
        res.forEach((d) =>
          allPICCs.push(d.data())
        );
        setPICCs(allPICCs);
      });

      let entPiccs = collection(firestore, 'ENT-Piccs');
      entPiccs = query(
        entPiccs,
        where('Status', '!=', 'Invalid'),
        where('jctdscid', '==', jobNumber),
        orderBy('Status', 'asc'),
        orderBy('PICC', 'asc')
      );
      getDocs(entPiccs).then((res) => {
        const allENTPICCs = [];
        res.forEach((d) =>
          allENTPICCs.push({
            ...d.data(),
            value: `${d.data().PICC} - ${d.data().Description}`
          })
        );
        if (mounted) {
          setENTPICCs(allENTPICCs);
        }
      });
    }
  };

  useEffect(() => {
    getCrew();
    getPiccs();

  }, []);
  form.watch('data.Foreman', ({ value }) => {
    getCrew();
  });
  form.watch('data.JobNumber', ({ value }) => {
    getPiccs();
    getCrew();
  });

  useEffect(() => {
    mounted.current = true;
    if (match.params.id === 'new') {
      return () => null;
    }
    const fRef = query(doc(collection(firestore, 'Tasks'), match.params.id));
    onSnapshot(fRef, (snap) => {
      if (snap.exists() && mounted) {
        form.setValues(snap.data());
      }
    });
    return () => (mounted.current = false);
  }, [match.params.id]);

  // Prevent form submission on Enter key
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' && e.target.tagName?.toUpperCase() !== 'TEXTAREA') {
        e.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);


  const handleSubmit = async () => {
    // TO DO Validate
    const record = form.getValues();
    console.log(record);
    try {
      if (checkLockout) {
        toast.error(
          'You cannot modify/create this record for this date anymore.'
        );
        return;
      }
      const savedRecord = JSON.parse(JSON.stringify(record));
      if (match.params.id === 'new') {
        const RECORDID = doc(collection(firestore, 'Tasks'));
        savedRecord.id = RECORDID.id;
        savedRecord.CreatedBy = user.email;
        savedRecord.CreatedDate = new Date().toJSON();
        console.log('SUBMITTING RECORD', savedRecord);
      }
      savedRecord.ModifiedBy = user.email;
      savedRecord.ModifiedDate = new Date().toJSON();

      const dRef = doc(collection(firestore, 'Tasks'), savedRecord.id);
      setDoc(dRef, savedRecord, { merge: true }).then(() => {
        toast.success('Form saved successfully!', { autoClose: 5000 });
        history.push(`/pod`);
      });
      const analytics = getAnalytics();
      logEvent(analytics, 'pod_modified', {
        user: record.ModifiedBy,
        date: record.ModifiedDate,
        recordID: record.id
      });
    } catch (err) {
      toast.error('Error occured while saving form.', { autoCloseq: 5000 });
    }
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
          Job Number: {CurrentJobNumber.display}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container sx={{
          '& .MuiFormLabel-root': {
            fontWeight: 'bold'
          }
        }}>
          <form
            onSubmit={form.onSubmit(handleSubmit)}
            style={{ width: '100%' }}
          >
            <Grid
              item
              xs={12}
              sx={{
                padding: 1,
                margin: 1,
                position: 'sticky',
                top: '0',
                left: '0',
                right: '0',
                backgroundColor: '#fff',
                zIndex: 1100,
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                transition: 'top 0.3s ease-out'
              }}
            >
              {!checkLockout ? (
                <Button
                  type='submit'
                  color='success'
                  style={{ margin: 2 }}
                  variant='contained'
                >
                  Save
                </Button>
              ) : null}
              <Button
                type='button'
                color='error'
                style={{ margin: 2 }}
                onClick={() => {
                  history.push('/pod');
                  form.reset();
                }}
                variant='contained'
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TaskWBSSection
                form={form}
                PICCs={PICCs}
                ENTPICCs={ENTPICCs}
                ENTJobs={ENTJobs}
                CurrentJobNumber={CurrentJobNumber}
                CurrentForeman={CurrentForeman}
                Employees={Employees}
                checkLockout={checkLockout}
                timeOfDay={timeOfDay}
              />
            </Grid>
            <Grid item xs={12}>
              <DailyProductionSection
                form={form}
                checkLockout={checkLockout}
                timeOfDay={timeOfDay}
              />
            </Grid>
            <Grid item xs={12}>
              <PlannedSection
                form={form}
                CurrentJobNumber={CurrentJobNumber}
                Crew={Crew}
                user={user}
                piccs={PICCs}
                timeOfDay={timeOfDay}
                getCrew={getCrew}
              />
            </Grid>
            <Grid item xs={12}>
              {timeOfDay === 'EndDay' && (
                <CompletedHoursSection form={form} Employees={Employees} Crew={Crew} />
              )}
            </Grid>
            <Grid item xs={12}>
              {timeOfDay === 'EndDay' && (
                <ObstaclesSection
                  form={form}
                  requiresAddOb={requiresAddOb}
                  setRequiresAddOb={setRequiresAddOb}
                  checkLockout={checkLockout}
                />
              )}
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
}