import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { useRecoilValue } from 'recoil';
import { getDoc, doc } from 'firebase/firestore';
import { NaNtoNum } from '@aldridge/aldg-helpers';
import {
  _CurrentJobNumber
} from '../../_Recoil/atoms';

import { firestore } from '../../firebase';
import { pdfStyles } from '../../theme';
import existsWithLength from '../../utils/existsWithLength';
import Header from './PDFHeader';
import { ObstacleDisplay, parsePercentComplete, taskCheck, taskDescription } from './PDFFunctions';

const DailyJobPDF = React.forwardRef((props, ref) => {
  const { transaction, files, external } = props;
  const pdfClasses = pdfStyles();
  const t = transaction;
  const recoilJob = useRecoilValue(_CurrentJobNumber);
  const [jobDetails, setJobDetails] = useState({
    ProjectManager: '',
    Location: ''
  });

  useEffect(() => {
    let mounted = true;
    if (recoilJob.value.length > 0)
      getDoc(doc(firestore, 'ENT-Jobs', recoilJob.value[0])).then((r) => {
        if (r.exists) {
          const record = r.data();
          if (mounted)
            setJobDetails({
              ProjectManager: record.ProjectManager,
              Location: `${record.City}, ${record.State}`
            });
        }
      });
    return () => (mounted = false);
  }, [recoilJob.value]);

  return (
    <>
      <div className={pdfClasses.root} ref={ref}>
        <Grid container>
          <Header recoilJob={recoilJob} jobDetails={jobDetails} task={t} />
          <Grid item xs={12}>
            <div className={pdfClasses.titleBox}>
              Plan of the Day for {t.Date}
            </div>
          </Grid>

          <Grid item xs={3}>
            <div className={pdfClasses.subTitleBox}>Task</div>
          </Grid>
          <Grid item xs={2}>
            <div className={pdfClasses.subTitleBoxSecond}>
              Planned / Installed Qty
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={pdfClasses.subTitleBox}>Reason Code</div>
          </Grid>
          <Grid item xs={2}>
            <div className={pdfClasses.subTitleBoxSecond}>
              Planned / Impacted Hrs
            </div>
          </Grid>
          <Grid item xs={taskCheck ? 1 : 2}>
            <div
              className={pdfClasses.subTitleBox}
              style={{ fontSize: '10px' }}
            >
              Percent Complete
            </div>
          </Grid>
          {taskCheck ? (
            <Grid item xs={1}>
              <div
                className={pdfClasses.subTitleBox}
                style={{ fontSize: '10px' }}
              >
                FA/FO #
              </div>
            </Grid>
          ) : null}
          {t.Tasks.map((_task) => (
            <React.Fragment key={_task.id}>
              <Grid item xs={3}>
                <div className={pdfClasses.fillable}>
                  {taskDescription(_task)}
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className={pdfClasses.fillable}>
                  {`${_task.data.StartDay?.ExpectedProductionQuantity} ${NaNtoNum(_task.data.EndDay?.CompletedProductionQuantity) > 0
                    ? ` / ${_task.data.EndDay?.CompletedProductionQuantity}`
                    : ''
                    }  ${typeof _task.data.StartDay?.UoM !== 'undefined'
                      ? _task.data.StartDay.UoM
                      : ''
                    }`}{' '}
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className={pdfClasses.fillable}>
                  {ObstacleDisplay(_task)}
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className={pdfClasses.fillable}>{`${_task.data.StartDay?.PlannedShiftHours
                  } ${NaNtoNum(_task.data.EndDay?.HoursImpacted) > 0
                    ? ` / ${_task.data.EndDay?.HoursImpacted}`
                    : ''
                  }`}</div>
              </Grid>
              <Grid item xs={taskCheck ? 1 : 2}>
                <div className={pdfClasses.fillable}>
                  {_task.data.EndDay ? `${Math.round(parsePercentComplete(_task))}%` : null}
                </div>
              </Grid>
              {taskCheck ? (
                <Grid item xs={1}>
                  <div className={pdfClasses.fillable}>
                    {_task.data.ForceNumber}
                  </div>
                </Grid>
              ) : null}
              {ObstacleDisplay(_task) !== '' ? (
                <>
                  <Grid item xs={3}>
                    <div className={pdfClasses.fillable}>Impact Notes</div>
                  </Grid>
                  <Grid item xs={9}>
                    <div className={pdfClasses.fillable}>
                      {_task.data.EndDay.ReasonsForImpact}
                      {_task.data.EndDay.ReasonsForImpact !== '' &&
                        typeof _task.data.EndDay.ReasonsForImpact2 !==
                        'undefined' &&
                        _task.data.EndDay.ReasonsForImpact2 !== ''
                        ? `\r\n${_task.data.EndDay.ReasonsForImpact2}`
                        : ''}
                    </div>
                  </Grid>
                </>
              ) : null}
            </React.Fragment>
          ))}

          <Grid item xs={12}>
            <div className={pdfClasses.subTitleBox}>Onsite Personnel</div>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={3}>
                <div className={pdfClasses.subTitleBox}>Name</div>
              </Grid>
              <Grid item xs={3}>
                <div className={pdfClasses.subTitleBox}>PICC</div>
              </Grid>
              <Grid item xs={2}>
                <div className={pdfClasses.subTitleBox}>Union</div>
              </Grid>
              <Grid item xs={1}>
                <div className={pdfClasses.subTitleBox}>Shift</div>
              </Grid>
              <Grid item xs={1}>
                <div className={pdfClasses.subTitleBox}>ST</div>
              </Grid>
              <Grid item xs={1}>
                <div className={pdfClasses.subTitleBox}>OT</div>
              </Grid>
              <Grid item xs={1}>
                <div className={pdfClasses.subTitleBox}>DT</div>
              </Grid>
              {t.TimeEntries.map((te) => te.data.NoWorkRecorded === 'true' ? null : (
                <React.Fragment key={te.id}>
                  <Grid item xs={3}>
                    <div className={pdfClasses.fillable}>
                      {te.data.EmployeeDisplay}
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className={pdfClasses.fillable}>
                      {te.data.PiccDisplay}
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className={pdfClasses.fillable}>
                      {te.data.UnionDisplay}
                    </div>
                  </Grid>
                  <Grid item xs={1}>
                    <div className={pdfClasses.fillable}>{te.data.Shift}</div>
                  </Grid>
                  <Grid item xs={1}>
                    <div className={pdfClasses.fillable}>
                      {te.data.Regular}
                    </div>
                  </Grid>
                  <Grid item xs={1}>
                    <div className={pdfClasses.fillable}>
                      {te.data.Overtime}
                    </div>
                  </Grid>
                  <Grid item xs={1}>
                    <div className={pdfClasses.fillable}>
                      {te.data.Doubletime}
                    </div>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={7}>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={pdfClasses.subTitleBox}>Subcontractors</div>
                  </Grid>

                  <Grid item xs={4}>
                    <div className={pdfClasses.subTitleBox}>Company</div>
                  </Grid>

                  <Grid item xs={5}>
                    <div className={pdfClasses.subTitleBox}>
                      Tasks Completed{' '}
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className={pdfClasses.subTitleBox}>Personnel</div>
                  </Grid>
                  {t.DailyReport.map((dr) => dr.data.Subcontractors.map((sub) => (
                    <React.Fragment key={sub.Subcontractor[0]}>
                      <Grid item xs={4}>
                        <div className={pdfClasses.fillable}>
                          {sub.SubcontractorDisplay}
                        </div>
                      </Grid>
                      <Grid item xs={5}>
                        <div className={pdfClasses.fillable}>
                          {sub.TasksCompleted}
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div className={pdfClasses.fillable}>
                          {sub.NumberOfSubPersonnel}
                        </div>
                      </Grid>
                    </React.Fragment>
                  )))}
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={pdfClasses.subTitleBox}>Equipment</div>
                  </Grid>

                  <Grid item xs={8}>
                    <div className={pdfClasses.subTitleBox}>Unit</div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className={pdfClasses.subTitleBox}>Hours</div>
                  </Grid>
                  {t.DailyReport.map((dr) => dr.data.Equipments?.map((equips) => (
                    <React.Fragment key={equips.Equipment[0]}>
                      <Grid item xs={8}>
                        <div className={pdfClasses.fillable}>
                          {equips.EquipmentDisplay}
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className={pdfClasses.fillable}>
                          {equips.Hours}
                        </div>
                      </Grid>
                    </React.Fragment>
                  )))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ marginTop: '5px' }}>
            <div className={pdfClasses.titleBox}>Notes</div>
          </Grid>
          {t?.DailyReport[0]?.data?.InternalNotes !== '' && !external && (
            <>
              <Grid item xs={12}>
                <div className={pdfClasses.subTitleBox}>Internal Notes</div>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ textAlign: 'left', whiteSpace: 'break-spaces' }}
              >
                <div className={pdfClasses.fillable}>
                  {t?.DailyReport[0]?.data?.InternalNotes}
                </div>
              </Grid>
            </>
          )}
          {t?.DailyReport[0]?.data?.ExternalNotes !== '' ? (
            <>
              <Grid item xs={12}>
                <div className={pdfClasses.subTitleBox}>External Notes</div>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ textAlign: 'left', whiteSpace: 'break-spaces' }}
              >
                <div className={pdfClasses.fillable}>
                  {t?.DailyReport[0]?.data?.ExternalNotes}
                </div>
              </Grid>
            </>
          ) : null}
          {t?.DailyReport[0]?.data?.ExtraWorkChangesDetail !== '' ? (
            <>
              <Grid item xs={12}>
                <div className={pdfClasses.subTitleBox}>Extra Work/Changes</div>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ textAlign: 'left', whiteSpace: 'break-spaces' }}
              >
                <div className={pdfClasses.fillable}>
                  {t?.DailyReport[0]?.data?.ExtraWorkChangesDetail}
                </div>
              </Grid>
            </>
          ) : null}
          {t.DailyReport.map((_signature) => (
            <Grid key={_signature.id} item xs={12}>
              <Grid container>
                <Grid item xs={3}>
                  <div className={pdfClasses.signatureBox}>
                    AE Foreman Signature
                  </div>
                </Grid>
                <Grid item xs={9}>
                  <div className={pdfClasses.signatureBoxSecond}>
                    {_signature.data?.ForemanSignature !== '' ? (
                      <img src={_signature.data?.ForemanSignature || ''} alt='Foreman Signature' />
                    ) : null}
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className={pdfClasses.signatureBox}>
                    AE Foreman Signature (Print)
                  </div>
                </Grid>
                <Grid item xs={9}>
                  <div className={pdfClasses.signatureBoxSecond}>
                    {_signature.data?.ForemanSignaturePrint}
                  </div>
                </Grid>
                <Grid item xs={12} hidden />

                <Grid item xs={3}>
                  <div className={pdfClasses.signatureBox}>
                    Customer Authorization for Changes
                  </div>
                </Grid>
                <Grid item xs={9}>
                  <div className={pdfClasses.signatureBoxSecond}>
                    {_signature.data?.CustomerSignature !== '' ? (
                      <img src={_signature.data?.CustomerSignature || ''} alt='Customer Signature' />
                    ) : null}
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className={pdfClasses.signatureBox}>
                    Customer Signature (Print)
                  </div>
                </Grid>
                <Grid item xs={9}>
                  <div className={pdfClasses.signatureBoxSecond}>
                    {_signature.data?.CustomerSignaturePrint}
                  </div>
                </Grid>
              </Grid>
              {existsWithLength(files) ? (
                <>
                  <Grid key={files} item xs={12} style={{ marginTop: '8px' }}>
                    <div className={pdfClasses.titleBox}>Files</div>
                  </Grid>
                  <Grid
                    container
                    direction='row'
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      border: existsWithLength(files) ? '1px solid' : 'none'
                    }}
                  >
                    {files
                      .filter((i) => i.thumbnail !== '')
                      .map((img) => (
                        <Grid item xs={12} key={img.thumbnail}>
                          <img src={img.url} style={{ width: '100%' }} alt={`img ${img.url}`} />
                        </Grid>
                      ))}
                  </Grid>
                </>
              ) : null}
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
});

DailyJobPDF.propTypes = {
  transaction: PropTypes.objectOf(PropTypes.any),
  files: PropTypes.arrayOf(PropTypes.any),
  external: PropTypes.bool
};
DailyJobPDF.defaultProps = {
  transaction: {},
  files: [],
  external: false
};

export default DailyJobPDF;