/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
// import {
//   Autocomplete,
//   Grid,
//   Textarea,
//   TextInput,
// } from "@mantine/core";
import { Autocomplete, Grid, TextField } from '@mui/material';

export default function UnplannedWorkSection({
  form,
  PICCs,
  Employees,
  checkLockout,
  timeOfDay
}) {

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          label='Task Details'
          value={form.values?.data?.Task}
          {...form?.getInputProps('data.Task')}
          disabled={checkLockout || timeOfDay !== 'StartDay'}
          multiline
          rows={1}
          fullWidth
        />
      </Grid>
      {(form.values.data.GLCode === '' ||
        typeof form.values.data.GLCode === 'undefined') && (
          <>
            <Grid item xs={12}>
              <Autocomplete
                renderInput={(params) => <TextField {...params} label='PICC' />}
                value={form.values?.data?.PICC?.[0] || null}
                options={PICCs}
                isOptionEqualToValue={(option, value) => option.jctmstid === value}
                getOptionLabel={(opts) => {
                  const picc = PICCs.find((p) => p.jctmstid === opts);
                  if (picc) {
                    return `${picc.PICC} - ${picc.Description}`;
                  }
                  if (opts.jctmstid) {
                    return `${opts.PICC} - ${opts.Description}`;
                  }
                  return '';
                }}
                disabled={checkLockout}
                onChange={(event, value) => {
                  form.setFieldValue('data.PICC', [value.jctmstid]);
                  form.setFieldValue(
                    'data.PICCDisplay',
                    `${value.PICC} - ${value.Description}`
                  );
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              hidden={
                (form.values?.data.PICCDisplay || '')
                  ?.toLowerCase()
                  ?.indexOf('force account work'.toLowerCase()) === -1
              }
            >
              <TextField
                label='FA/FO #'
                value={form.values?.data?.ForceAccount}
                fullWidth
              />
            </Grid>
          </>
        )}
    </Grid>
  );
}
