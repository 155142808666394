import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { faTrashAlt, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {
  deleteDoc,
  doc,
  collection,
  query,
  where,
  onSnapshot,
  orderBy
} from 'firebase/firestore';
import getWBSDescription, { getManHours } from 'utils/getWBSDescription';
import { UserContext } from '../../providers/UserProvider';
import { firestore } from '../../firebase';
import {
  _CurrentJobNumber,
  _CurrentDate,
  _CurrentForeman
} from '../../_Recoil/atoms';
import { icons, basicStyles } from '../../theme';
import StartDayCopyForm from './StartDayCopyForm';
import GenerateOTDTasksModal from './GenerateOTDTasksModal';
import existsWithLength from '../../utils/existsWithLength';

const NewJobList = (props) => {
  const { history } = props;
  const [newJobs, setNewJobs] = useState([]);
  const [displayBoxOpen, setDisplayBoxOpen] = useState(false);
  const [otdBoxOpen, setOtdBoxOpen] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useContext(UserContext);

  const displayChangeBox = (bool) => {
    setDisplayBoxOpen(bool);
  };
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const CurrentDate = useRecoilValue(_CurrentDate);

  const handleSelect = (idx) => {
    history.push(`/pod/${idx}`);
  };

  const [deleteState, setDeleteState] = useState({
    show: false,
    id: -99
  });

  const addNew = () => {
    history.push(`/pod/new`);
  };

  const PreviousTask = () => {
    if (
      CurrentJobNumber.value.length !== 0 &&
      CurrentForeman.value.length !== 0
    ) {
      displayChangeBox(true);
    } else {
      toast.error(
        'You must have a Job and Foreman Selected to copy P.O.D.s...'
      );
    }
  };

  const GenerateOTDTasks = () => {
    setOtdBoxOpen(true);
  };

  const customClasses = makeStyles(
    () => ({
      button: {
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          justifyContent: 'center'
        },
        marginLeft: '8px',
        marginBottom: '16px'
      },
      line: {
        padding: '8px'
      }
    }),
    { index: 1 }
  )();
  const classes = basicStyles();

  useEffect(() => {
    let mounted = true;
    if (CurrentDate.value === '') {
      toast.error('Date is Required');
      setNewJobs([]);
      return;
    }
    if (
      CurrentForeman.value.length === 0 &&
      CurrentJobNumber.value.length === 0
    ) {
      toast.error('Foreman or Job Number is Required');
      setNewJobs([]);
      return;
    }
    const colRef = (() => {
      let col = collection(firestore, 'Tasks');
      if (CurrentForeman.value.length > 0)
        col = query(col, where('data.Foreman', '==', CurrentForeman.value[0]));
      if (CurrentDate.value !== '')
        col = query(col, where('data.Date', '==', CurrentDate.value));
      if (CurrentJobNumber.value.length > 0)
        col = query(
          col,
          where('data.JobNumber', '==', CurrentJobNumber.value[0])
        );
      col = query(col, orderBy('CreatedDate'));
      return col;
    })();
    const unsub = onSnapshot(colRef, (res) => {
      const allNewJobs = [];
      res.forEach((d) => allNewJobs.push(d.data()));
      if (mounted) setNewJobs(allNewJobs);
    });
    // eslint-disable-next-line consistent-return
    return () => {
      mounted = false;
      unsub();
    };
  }, [CurrentForeman, CurrentDate, CurrentJobNumber]);

  const removeRecord = async () => {
    if (deleteState.id !== -99) {
      deleteDoc(doc(firestore, 'Tasks', deleteState.id));
      toast.success('P.O.D. Removed.');
      const analytics = getAnalytics();
      logEvent(analytics, 'pod_deleted', { user: user.email, recordID: deleteState.id });
    }
    setDeleteState({ show: false, id: -99 });
  };
  const handleDelete = (idx) => {
    setDeleteState({ show: true, id: idx });
  };

  const iconClasses = icons();
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: matches ? '8px' : '0px',
          marginTop: '8px'
        }}
      >
        <Button
          className={customClasses.button}
          variant='contained'
          color='primary'
          onClick={PreviousTask}
        >
          Copy P.O.D.s
        </Button>
        {(user.prefab || user.SuperAdmin) &&
          <Button
            className={customClasses.button}
            variant='contained'
            color='primary'
            onClick={GenerateOTDTasks}
          >
            Create OTD Tasks
          </Button>}
        {matches ? (
          <div
            className={`${iconClasses.container} ${iconClasses.addContainer}`}
            style={{ marginRight: '0' }}
          >
            <Button
              onClick={addNew}
              disableRipple
              className={iconClasses.buttonWrapper}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className={iconClasses.icon}
                title='Add P.O.D.'
              />
            </Button>
          </div>
        ) : null}
      </div>
      <div style={{ padding: '5px' }} />
      <Modal open={deleteState.show} sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Paper sx={{ width: '50%' }}>
          <Card>
            <CardHeader title="Delete P.O.D." />
            <CardContent>
              Would you like you to delete this record?
            </CardContent>
            <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" color="error" onClick={removeRecord}>Delete</Button>
              <Button variant="contained" onClick={() => { setDeleteState({ show: false, id: -99 }); }}>Close</Button>
            </CardActions>
          </Card>
        </Paper>
      </Modal>
      <StartDayCopyForm
        displayBoxOpen={displayBoxOpen}
        closePopup={() => displayChangeBox(false)}
      />
      <GenerateOTDTasksModal open={otdBoxOpen} closePopup={() => { setOtdBoxOpen(false); }} job={CurrentJobNumber} foreman={CurrentForeman} user={user} date={CurrentDate.value} />
      <Grid container>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: 'white' }}>
                <TableCell className={classes.tableHeader}>Date</TableCell>
                <TableCell className={classes.tableHeader}>Foreman</TableCell>
                {CurrentJobNumber.value.length === 0 ? (
                  <TableCell className={classes.tableHeader}>
                    Job Number
                  </TableCell>
                ) : null}

                <TableCell className={classes.tableHeader} colSpan={2}>
                  P.O.D./WBS
                </TableCell>
                <TableCell
                  className={`${classes.tableHeader} ${classes.tableCell}`}
                >
                  Planned vs Actual
                </TableCell>
                <TableCell className={classes.tableHeader} align='right'>
                  <div
                    className={`${iconClasses.container} ${iconClasses.addContainer}`}
                    style={{ margin: '8px 0px' }}
                  >
                    <Button
                      onClick={addNew}
                      disableRipple
                      className={iconClasses.buttonWrapper}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        className={iconClasses.icon}
                        title='Add P.O.D.'
                      />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newJobs.map((row) => (
                <TableRow key={row.id} className={classes.row1}>
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => handleSelect(row.id)}
                  >
                    {row.data.Date}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => handleSelect(row.id)}
                  >
                    {row.data.ForemanDisplay}
                  </TableCell>
                  {CurrentJobNumber.value.length === 0 ? (
                    <TableCell
                      className={classes.tableCell}
                      onClick={() => handleSelect(row.id)}
                    >
                      {existsWithLength(row.data.GLCode) ? row.data.GLCode : row.data.JobNumberDisplay}
                    </TableCell>
                  ) : null}
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => handleSelect(row.id)}
                    colSpan={2}
                  >
                    {getWBSDescription(row)}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => handleSelect(row.id)}
                  >
                    Planned: {row.data.StartDay.ExpectedProductionQuantity}{' '}
                    {row.data.StartDay.UoM} <br />
                    {existsWithLength(
                      row.data.EndDay.CompletedProductionQuantity
                    )
                      ? `Actual: ${row.data.EndDay.CompletedProductionQuantity} ${row.data.StartDay.UoM}`
                      : ''} <br />
                    Man Hours: {getManHours(row)}
                  </TableCell>
                  <TableCell className={classes.tableCell} align='right'>
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(row.id);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default NewJobList;

NewJobList.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};
NewJobList.defaultProps = {};
