/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */

import React, { useContext } from "react";
// import {
//   Autocomplete,
//   Grid,
//   Radio,
//   Group,
//   Title,
//   Container,
// } from "@mantine/core";
import { Grid, Typography, Autocomplete, TextField, RadioGroup, Radio, FormControlLabel, FormControl, FormLabel, Paper } from "@mui/material";
import { DatePickerInput, DatesProvider } from "@mantine/dates";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers-pro";
import WBSTaskSection from "./TaskTypeSections/WBSTaskSection";
import UnplannedWorkSection from "./TaskTypeSections/UnplannedWorkSection";
import PrefabSection from "./TaskTypeSections/PrefabSection";
import { UserContext } from "../../providers/UserProvider";

export default function TaskWBSSection({ form, PICCs, ENTPICCs, ENTJobs, CurrentJobNumber, CurrentForeman, Employees, checkLockout, timeOfDay }) {
  const { user } = useContext(UserContext);

  const foremanDisplayString = (opts) => {
    let display = `${opts.LegalLastName} ${opts.Suffix || ''}, ${opts.PreferredFirstName
      } ${opts.MiddleName || ''} [${opts.Title} - ${opts.UniversalID
      }]`.replace(/ {2}/g, ' ');
    if (Object.keys(opts).length === 0) return '';
    if (opts.IsAldridgeEmployee === false) {
      display += ` - [${opts.CompanyDisplay}]`;
    }
    return display;
  };
  const onForemanChange = (event, obj) => {
    if (obj) {
      form.setFieldValue('data.Foreman', obj.prtmstid);
      form.setFieldValue('data.ForemanDisplay', foremanDisplayString(obj)?.split(' [')[0]);
    } else {
      form.setFieldValue('data.Foreman', '');
      form.setFieldValue('data.ForemanDisplay', '');
    }
  };
  const onJobChange = (event, obj) => {
    if (obj) {
      form.setFieldValue('data.JobNumber', obj.jctdscid);
      form.setFieldValue('data.JobNumberDisplay', `${obj.JobNumber}.${obj.SubJobNumber} - ${obj.JobDescription}`);
    } else {
      form.setFieldValue('data.JobNumber', '');
      form.setFieldValue('data.JobNumberDisplay', '');
    }
  };

  return (
    <Paper sx={{
      padding: 2,
      '& .MuiGrid-item': {
        padding: 1
      }
    }}>
      <Grid container style={{ margin: 0 }}>
        <Grid item xs={12}>
          <h2>Task/WBS</h2>
        </Grid>
        <Grid item xs={12} md={4}>
          <Autocomplete
            options={Employees}
            value={form?.values?.data?.Foreman || null}
            onChange={onForemanChange}
            isOptionEqualToValue={(opt, val) => opt.prtmstid === val}
            getOptionLabel={(opts) => {
              let Employee = Employees.find(e => e.prtmstid === opts);
              if (typeof opts === 'object') {
                Employee = opts;
              }
              if (Employee) {
                let display = `${Employee.LegalLastName} ${Employee.Suffix || ''}, ${Employee.PreferredFirstName
                  } ${Employee.MiddleName || ''} [${Employee.Title} - ${Employee.UniversalID
                  }]`.replace(/ {2}/g, ' ');
                if (Object.keys(Employee).length === 0) return '';
                if (Employee.IsAldridgeEmployee === false) {
                  display += ` - [${Employee.CompanyDisplay}]`;
                }
                return display;
              }
              return '';
            }}
            getOptionKey={(option) => option.prtmstid}
            renderInput={(params) => <TextField {...params} label="Foreman" />}
            disabled={CurrentForeman.value.length > 0 || checkLockout}
          />
        </Grid>
        {form?.values?.data?.IsGLTicket && form?.values?.data?.GLCode !== '' ? (
          <>
            <Grid item xs={12} md={4}>
              <TextField
                name='GLCode'
                label='GL Code'
                value={form?.values?.data?.GLCode || ''}
                disabled={checkLockout}
                fullWidth
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={4}>
              <Autocomplete
                renderInput={(params) => <TextField {...params} label='Job Number' />}
                value={form?.values?.data?.JobNumber || null}
                onChange={onJobChange}
                isOptionEqualToValue={(opt, val) => opt.jctdscid === val}
                getOptionKey={(option) => option.jctdscid}
                options={ENTJobs}
                getOptionLabel={(opts) => {
                  let Job = ENTJobs.find(e => e.jctdscid === opts);
                  if (typeof opts === 'object') {
                    Job = opts;
                  }
                  if (typeof Job === 'undefined') return '';
                  return `${Job.JobNumber}.${Job.SubJobNumber} - ${Job.JobDescription}`;
                }}
                disabled={
                  (CurrentJobNumber.value.length > 0 &&
                    form.values.data.TaskType !== 'PreFab') ||
                  checkLockout
                }
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={4}>
          <DatePicker
            label='P.O.D. Date'
            name='Date'
            value={dayjs(form?.values?.data?.Date)}
            disabled={
              timeOfDay === 'EndDay' ||
              (typeof form?.values?.data?.id !== 'undefined' &&
                form?.values?.data?.id !== '' &&
                checkLockout)
            }
            slotProps={{ textField: { fullWidth: true } }}
          />
        </Grid>

        <Grid item xs={12} sx={{ textAlign: 'left' }}>
          <FormControl>
            <FormLabel sx={{ fontWeight: 'bold' }}>Task Type</FormLabel>
            <RadioGroup
              name='TaskType'
              value={form?.values?.data?.TaskType}
              disabled={checkLockout}
              onChange={(e) => form.setFieldValue('data.TaskType', e.target.value)}
            >
              <FormControlLabel value='WBS' control={<Radio />} label='WBS' />
              <FormControlLabel value='Task' control={<Radio />} label='Unplanned Work' />
              {user.admin || user.prefab ? <FormControlLabel value='PreFab' control={<Radio />} label='PreFab' /> : null}
            </RadioGroup>
          </FormControl>
        </Grid>

        {form?.values?.data?.TaskType === "WBS" && (
          <WBSTaskSection form={form} PICCs={PICCs} Employees={Employees} checkLockout={checkLockout} timeOfDay={timeOfDay} />
        )}
        {form?.values?.data?.TaskType === "Task" && (
          <UnplannedWorkSection form={form} PICCs={ENTPICCs} Employees={Employees} checkLockout={checkLockout} timeOfDay={timeOfDay} />
        )}
        {form?.values?.data?.TaskType === "PreFab" && (
          <PrefabSection form={form} PICCs={ENTPICCs} Employees={Employees} CurrentJobNumber={CurrentJobNumber} checkLockout={checkLockout} timeOfDay={timeOfDay} />
        )}

      </Grid>
    </Paper>

  );
}
