import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, useTheme, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { faTrash, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import {
  deleteDoc,
  query,
  doc,
  collection,
  where,
  getDocs
} from 'firebase/firestore';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { _CurrentJobNumber } from '../../_Recoil/atoms';
import { firestore } from '../../firebase';

const gridStyles = makeStyles(
  () => ({
    root: {
      '& .MuiDataGrid-footerContainer': {
        justifyContent: 'flex-start',
        marginLeft: '-12px'
      },
      '& .MuiDataGrid-columnsContainer': {
        backgroundColor: '#fff'
      },
      '& .MuiDataGrid-row:nth-child(odd)': {
        backgroundColor: '#efefef'
      },
      '& .MuiDataGrid-row:nth-child(even)': {
        backgroundColor: '#fff'
      }
    }
  }),
  { index: 1 }
);

const SubcontractorList = (props) => {
  const { history } = props;
  const [subcontractor, setSubcontractor] = useState([]);
  const [rows, setRows] = useState(subcontractor);
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const GridApiRef = useGridApiRef();
  const gridClasses = gridStyles();

  useEffect(() => {
    let mounted = true;
    if (CurrentJobNumber.value.length === 0) {
      setSubcontractor([]);
      return;
    }
    const q = query(
      collection(firestore, 'Subcontractor'),
      where('data.JobNumber', '==', CurrentJobNumber.value[0])
    );
    getDocs(q).then((res) => {
      const allSubcontractors = [];
      res.forEach((d) => allSubcontractors.push(d.data()));
      if (mounted) setSubcontractor(allSubcontractors);
    });
    // eslint-disable-next-line consistent-return
    return () => (mounted = false);
  }, [CurrentJobNumber]);

  useEffect(() => {
    let mounted = true;
    if (mounted) setRows(subcontractor);
    return () => (mounted = false);
  }, [subcontractor]);

  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const handleSelect = (idx) => {
    history.push(`/Subcontractor/${idx}`);
  };
  const addNew = () => {
    if (CurrentJobNumber.value.length > 0) history.push(`/Subcontractor/new`);
    else toast.error('You must select a Job before adding a Subcontractor...');
  };
  const deleteRecords = () => {
    const selectedRows = Array.from(GridApiRef.current.getSelectedRows()?.entries());
    selectedRows.forEach((s) => {
      const [id] = s;
      deleteDoc(doc(firestore, 'Subcontractor', id));
    });
  };
  const columns = [
    {
      field: 'EquipmentNumber',
      headerName: 'Subcontractor',
      valueGetter: (params) => params.row.data.Name,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'Make',
      headerName: 'Contract Number',
      valueGetter: (params) => params.row.data.ContractNumber,
      flex: getFlex(15),
      width: getWidth(300)
    }
  ];
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              padding: matches ? '20px 0' : '10px 0',
              justifyContent: 'flex-end',
              flexDirection: 'row'
            }}
          >
            <ToolTipIconButton icon={faTrash} color="error" tooltipText='Delete Selected Records' onClick={deleteRecords} />
            <ToolTipIconButton icon={faPlus} tooltipText='Add New Record' onClick={addNew} />
          </div>
          <AldgDataGrid
            autoHeight
            checkboxSelection
            onRowClick={(row) => handleSelect(row.id)}
            className={gridClasses.root}
            rows={rows}
            columns={columns}
            apiRef={GridApiRef}
          />
        </Grid>
      </Grid>
    </div>
  );
};

SubcontractorList.propTypes = {
  history: PropTypes.objectOf(PropTypes.any)
};

SubcontractorList.defaultProps = {
  history: {
    push: () => { }
  }
};

export default SubcontractorList;
