/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import PODForm2 from 'components/pod2/PODForm';
import PODForm3 from 'components/pod3/PODForm';
import DailyToolbar from '../components/dailyUsage/DailyToolbar';
import NewJobList from '../components/pod/NewDayJobList';
import PODForm from '../components/pod/PODForm';
import { basicStyles } from '../theme';
import ErrorBoundary from '../utils/ErrorBoundary';

import { UserContext } from '../providers/UserProvider';

const NewDay = (props) => {
  const classes = basicStyles();
  const { user } = useContext(UserContext);
  const { match, history, pod, setPOD } = props;
  return (
    <ErrorBoundary componentName='NewDay' user={user}>
      <Grid className={classes.gridItem}>
        {typeof match.params.id === 'undefined' ? (
          <>
            <Grid container>
              <DailyToolbar />
            </Grid>
            <NewJobList history={history} match={match} />
          </>
        ) : (<><PODForm3 timeOfDay='StartDay' user={user} currentRow={pod} history={history} /><Grid container spacing={0} className={classes.gridItem}>

          {/* <PODForm
            history={history}
            match={match}
            pod={pod}
            setPOD={setPOD}
            timeOfDay='newday' /> */}
        </Grid></>
        )}
      </Grid>
    </ErrorBoundary>
  );
};

NewDay.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
  pod: PropTypes.objectOf(PropTypes.any),
  setPOD: PropTypes.func
};
NewDay.defaultProps = {
  history: {
    push: () => { }
  },
  match: {},
  pod: {},
  setPOD: () => { }
};
export default NewDay;
