import React from 'react';
import PropTypes from 'prop-types';
import { InputAutocomplete } from '@aldridge/aldg-data-components';
import { existsWithLength } from '@aldridge/aldg-helpers';
import { firebaseConfig } from '../../firebase';

const AllWBSSearchBar = (props) => {
  const { JobNumber, value, onChange, completeStatus } = props;

  const whereClause = () => {
    const clause = [['data.JobNumber', '==', JobNumber]];
    if (completeStatus !== 'Both') {
      clause.push(['data.Complete', '==', completeStatus]);
    }
    return clause;
  };

  if (!existsWithLength(JobNumber)) return null;
  return (
    <InputAutocomplete
      firebaseConfig={firebaseConfig}
      firestoreOptions={{
        method: 'get',
        collection: 'PICC',
        valueKey: 'id',
        where: whereClause(),
        orderBy: [
          'data.Level0',
          'data.Level1',
          'data.Level2',
          'data.Level3',
          'data.Level4'
        ]
      }}
      getOptionKey={(opts) => opts.id}
      onChange={onChange}
      value={value || []}
      name='AllWBS'
      label='All WBS'
      optionDisplay={(opts) => {
        if (Object.keys(opts).length === 0) return '';
        return ` ${opts.data?.Level1 || ''} / ${opts.data?.Level2 || ''} / ${opts.data?.Level3 || ''
          } / ${opts.data?.Level4 || ''}  ${typeof opts.data?.TotalHours !== 'undefined'
            ? `- Hours: ${opts.data?.TotalHours}`
            : ''
          } [Task Complete: ${opts.data.Complete}]`;
      }}
      getOptionDisabled={(option) => option.data.Complete === 'Yes'}
    />
  );
};

AllWBSSearchBar.propTypes = {
  JobNumber: PropTypes.string.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  completeStatus: PropTypes.oneOf(['No', 'Yes', 'Both'])
};
AllWBSSearchBar.defaultProps = {
  value: [],
  completeStatus: 'No'
};

export default AllWBSSearchBar;
