/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import {
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { _CurrentJobNumber, _CurrentForeman } from '../_Recoil/atoms';
import { firestore } from '../firebase';

const DataContext = createContext();

export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};

export function DataProvider({ children }) {
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const CurrentForeman = useRecoilValue(_CurrentForeman);

  const [entPiccs, setEntPiccs] = useState([]);
  const [piccs, setPiccs] = useState([]);
  const [crew, setCrew] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch ENT PICCs
  useEffect(() => {
    const fetchEntPiccs = async () => {
      try {
        if (!CurrentJobNumber.value[0]) return;

        const q = query(
          collection(firestore, 'ENT-Piccs'),
          where('Status', '!=', 'Invalid'),
          where('jctdscid', '==', CurrentJobNumber.value[0])
        );

        const querySnapshot = await getDocs(q);
        const results = [];
        querySnapshot.forEach((doc) => {
          results.push({
            ...doc.data(),
            value: `${doc.data().PICC} - ${doc.data().Description}`
          });
        });
        setEntPiccs(results);
      } catch (err) {
        setError(err.message);
      }
    };

    // fetchEntPiccs();
  }, [CurrentJobNumber.value]);

  // Fetch PICCs
  useEffect(() => {
    const fetchPiccs = async () => {
      try {
        if (!CurrentJobNumber.value[0]) return;

        const q = query(
          collection(firestore, 'PICC'),
          where('data.JobNumber', '==', CurrentJobNumber.value[0])
        );

        const querySnapshot = await getDocs(q);
        const results = [];
        querySnapshot.forEach((doc) => {
          results.push(doc.data());
        });
        setPiccs(results);
      } catch (err) {
        setError(err.message);
      }
    };

    // fetchPiccs();
  }, [CurrentJobNumber.value]);

  // Fetch Crew
  useEffect(() => {
    const fetchCrew = async () => {
      try {
        if (!CurrentJobNumber.value[0] && !CurrentForeman.value[0]) return;

        let q = collection(firestore, 'Crew');

        if (CurrentForeman.value[0]) {
          q = query(q, where('data.Foreman', '==', CurrentForeman.value[0]));
        }

        if (CurrentJobNumber.value[0]) {
          q = query(q, where('data.JobNumber', '==', CurrentJobNumber.value[0]));
        }

        const querySnapshot = await getDocs(q);
        const results = [];
        querySnapshot.forEach((doc) => {
          results.push({
            ...doc.data(),
            value: `${doc.data().data.EmployeeDisplay} ${doc.data().data.UnionDisplay}`
          });
        });
        setCrew(results);
      } catch (err) {
        setError(err.message);
      }
    };

    // fetchCrew();
  }, [CurrentJobNumber.value, CurrentForeman.value]);

  // Update loading state
  useEffect(() => {
    // setLoading(false);
  }, [entPiccs, piccs, crew]);

  const value = {
    entPiccs,
    piccs,
    crew,
    loading,
    error
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}

DataProvider.propTypes = {
  children: PropTypes.node.isRequired
}; 